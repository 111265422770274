import React from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import Section from '../Section'
import { useTranslate } from '../../helpers/useTranslate'
import * as feedbackElements from './helper'

export interface Props {
    className?: string
}

const Feedback: React.FC<Props> = (props) => {
    const { className } = props
    const { translate } = useTranslate()
    const sectionBody = feedbackElements.getSectionBody()

    return (
        <feedbackElements.Wrapper className={className} >
            <Section
                title={translate('HOMEPAGE_FEEDBACK_TITLE')}
                body={sectionBody}
            />
        </feedbackElements.Wrapper>
    )
}

const StyledFeedback = styled(Feedback)((props) => {
    return {
        ['& .sectionBody']: {
            display: 'flex',
            alignItems: 'baseline',
            ['& svg']: {
                marginRight: theme.space.medium,
                alignSelf: 'center'
            }
        },
        // @todo: Consider moving all this to a Link component
        ['& a']: {
            color: 'black',
            ['&:focus, &:hover']: {
                outline: 'none',
                ['> *']: {
                    color: theme.color.accentDark
                }
            }
        }
    }
})

export default StyledFeedback
