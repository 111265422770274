import imgLoader from 'blueimp-load-image'
import throttle from 'lodash.throttle'

export function productLabelsToObject(
    product: LegacyProduct
): { string?: any } {
    return product.product_labels.reduce((acc, label) => {
        acc[label.key] = label.value
        return acc
    }, {})
}

export function createImage(url: string, setCors: boolean = true) {
    return new Promise((resolve: (value: HTMLImageElement) => void, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.addEventListener('abort', (error) => reject(error))
        if (setCors) {
            // needed to avoid cross-origin issues on CodeSandbox
            image.setAttribute('crossOrigin', 'anonymous')
        }
        image.src = url
    })
}

export function getCroppedImg(imageURL: string, pixelCrop: any) {
    return createImage(imageURL).then((image) => {
        const canvas = document.createElement('canvas')
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            return
        }
        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        )

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(resolve, 'image/jpeg')
        })
    })
}

export function getUprightImageUrl(
    file: File,
    orientation: number,
    callback: (url: string) => void
) {
    if (orientation < 2) {
        const url = window.URL.createObjectURL(file)
        return callback(url)
    }

    imgLoader(
        file,
        (cbImg: HTMLImageElement | Event | HTMLCanvasElement) => {
            callback((cbImg as HTMLCanvasElement).toDataURL('image/jpeg'))
        },
        { orientation: true, canvas: true }
    )
}

export function getOrientation(
    file: File,
    callback: (orientation: number) => void
) {
    const reader = new FileReader()

    reader.onload = (e: any) => {
        if (!e.target) {
            return
        }
        const view = new DataView(e.target.result)
        if (view.getUint16(0, false) !== 0xffd8) {
            return callback(-2) // not JPEG
        }
        const length = view.byteLength
        let offset = 2
        while (offset < length) {
            if (view.getUint16(offset + 2, false) <= 8) {
                return callback(-1)
            }
            const marker = view.getUint16(offset, false)
            offset += 2
            if (marker === 0xffe1) {
                if (view.getUint32((offset += 2), false) !== 0x45786966) {
                    return callback(-1)
                }

                const little = view.getUint16((offset += 6), false) === 0x4949
                offset += view.getUint32(offset + 4, little)
                const tags = view.getUint16(offset, little)
                offset += 2
                for (let i = 0; i < tags; i++) {
                    if (view.getUint16(offset + i * 12, little) === 0x0112) {
                        return callback(
                            view.getUint16(offset + i * 12 + 8, little)
                        )
                    }
                }
            } else if (marker < 0xff00) {
                // above condition was originally (marker & 0xff00) !== 0xff00)
                break
            } else {
                offset += view.getUint16(offset, false)
            }
        }
        return callback(-1) // not defined
    }
    reader.readAsArrayBuffer(file)
}

export function validateFileSize(
    file: File,
    ctx: AppContextInterface
): boolean {
    const LIMIT = 9 * 1024 * 1024 // 9 MB
    if (file.size > LIMIT) {
        ctx.setToastMessage('Please select an Image less than 9MB')
        return false
    }

    return true
}

export function resizeImage(
    img: any,
    containerHeight: any,
    containerWidth: any
) {
    const ratio = parseFloat(img.naturalWidth) / parseFloat(img.naturalHeight)
    let newImgWidth
    let newImgHeight
    let widthExcess
    let heightExcess
    widthExcess = parseFloat(img.naturalWidth) / containerWidth - 1.0
    heightExcess = parseFloat(img.naturalHeight) / containerHeight - 1.0
    if (widthExcess > 0 || heightExcess > 0) {
        if (widthExcess > heightExcess) {
            newImgWidth = containerWidth
            newImgHeight = Math.ceil(containerWidth / ratio)
        } else {
            newImgHeight = containerHeight
            newImgWidth = Math.floor(containerHeight * ratio)
        }
        img.height = newImgHeight
        img.width = newImgWidth
    }
}

export function getScaledDimensions(
    imgHeight: number,
    imgWidth: number,
    containerHeight: number,
    containerWidth: number
) {
    let isFullHeight = false
    let isFullWidth = false
    const ratio = imgWidth / imgHeight
    if (!containerHeight) {
        return {
            height: containerWidth / ratio,
            width: containerWidth,
            isFullHeight,
            isFullWidth: true
        }
    }
    if (!containerWidth) {
        return {
            height: containerHeight,
            width: containerHeight * ratio,
            isFullHeight: true,
            isFullWidth
        }
    }
    let width = imgWidth
    let height = imgHeight
    let widthExcess
    let heightExcess
    widthExcess = imgWidth / containerWidth - 1.0
    heightExcess = imgHeight / containerHeight - 1.0
    if (widthExcess > heightExcess) {
        width = containerWidth
        height = Math.ceil(containerWidth / ratio)
        isFullWidth = true
    } else if (widthExcess < heightExcess) {
        height = containerHeight
        width = Math.floor(containerHeight * ratio)
        isFullHeight = true
    } else {
        height = containerHeight
        width = containerWidth
        isFullHeight = true
        isFullWidth = true
    }
    return { height, width, isFullHeight, isFullWidth }
}

export function ago(
    time: number | string,
    translate: (key: string, options: any) => string
) {
    let units: number
    const timestamp = new Date(time).getTime()
    const delta = Math.floor((new Date().getTime() - timestamp) / 1000)
    const minute = 60
    const hour = minute * 60
    const day = hour * 24
    const week = day * 7
    const month = week * 4
    const year = month * 52

    if (delta < minute) {
        units = delta
        return translate('GENERIC_SECOND_AGO', { count: units, units })
    }

    if (delta < hour) {
        units = Math.ceil(delta / minute)
        return translate('GENERIC_MINUTE_AGO', { count: units, units })
    }

    if (delta < day) {
        units = Math.ceil(delta / hour)
        return translate('GENERIC_HOUR_AGO', { count: units, units })
    }
    if (delta < week) {
        units = Math.ceil(delta / day)
        return translate('GENERIC_DAY_AGO', { count: units, units })
    }
    if (delta < month) {
        units = Math.ceil(delta / week)
        return translate('GENERIC_WEEK_AGO', { count: units, units })
    }
    if (delta < year) {
        units = Math.ceil(delta / month)
        return translate('GENERIC_MONTH_AGO', { count: units, units })
    }
    if (delta > year) {
        units = Math.ceil(delta / year)
        return translate('GENERIC_YEAR_AGO', { count: units, units })
    }
    // @todo .. determine what happens if execution gets here. It shouldn't!
    return ''
}

export function toClipboard(text: string) {
    return new Promise<void>((resolve, reject) => {
        const fallbackCopyTextToClipboard = () => {
            const textarea = document.createElement('textarea')
            textarea.setAttribute('readonly', 'true')
            textarea.setAttribute('contenteditable', 'true')
            textarea.style.position = 'fixed'
            textarea.style.opacity = '0'
            textarea.value = text

            document.body.appendChild(textarea)

            textarea.focus()
            textarea.select()

            const range = document.createRange()
            range.selectNodeContents(textarea)

            const selection = window.getSelection()
            if (!selection) {
                return reject(new Error('unable to manually use clipboard'))
            }
            selection.removeAllRanges()
            selection.addRange(range)
            textarea.setSelectionRange(0, textarea.value.length)

            try {
                document.execCommand('copy')
                resolve()
            } catch (err) {
                reject(err)
            } finally {
                document.body.removeChild(textarea)
            }
        }

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard()
            return
        }
        navigator.clipboard
            .writeText(text)
            .then(resolve)
            .catch(reject)
    })
}

export function deepEqual<T>(x: T, y: T): boolean {
    // note that this function is naive in the sense that it expects that no key-pair value
    // should have an 'object' value
    const sameKeys = Object.keys(x).length === Object.keys(y).length
    return Object.keys(x).every((key) => x[key] === y[key]) && sameKeys
}

export function getRandomSample<T>(array: T[], count: number): T[] {
    if (count > array.length) {
        // if sample size is greater than array just
        // return a copy of the array
        return [...array]
    }

    const indices: number[] = []
    const result = new Array(count)
    for (let i = 0; i < count; i++) {
        const j = Math.floor(Math.random() * (array.length - i) + i)
        result[i] = array[indices[j] === undefined ? j : indices[j]]
        indices[j] = indices[i] === undefined ? i : indices[i]
    }
    return result
}

export function removeProductFromList(product: Product, list: Product[]) {
    const index = list.findIndex((p) => p.id === product.id)
    if (index >= 0) {
        // delete previously existing product
        list.splice(index, 1)
    }
    return list
}

const start = (track: any, onScrollStart: () => void, newY: any): void => {
    document.addEventListener('scroll', track)
    onScrollStart()
    window.scroll({ left: 0, top: newY, behavior: 'smooth' })
}

export function scrollTo(
    ref: any,
    offset: number = 0,
    onScrollStart: () => void,
    onScrollEnd: () => void
): void {
    const currentY = window.scrollY
    const newY = ref.offsetTop - offset

    const track = throttle(() => {
        if (
            (newY <= currentY && window.scrollY <= newY) ||
            (newY >= currentY && window.scrollY >= newY) ||
            window.innerHeight + window.pageYOffset >=
                document.body.offsetHeight
        ) {
            stop()
        }
    }, 100)

    const stop = () => {
        document.removeEventListener('scroll', track)
        onScrollEnd()
    }

    start(track, onScrollStart, newY)
}

export function isSaveButtonUsed() {
    return !!localStorage.getItem('used-save-button')
}

export function markSaveButtonasUsed() {
    localStorage.setItem('used-save-button', 'true')
}
