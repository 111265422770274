import React, { useContext } from 'react'
import { Wavy } from '../../screens/Business/Sections'
import Button from '../Button'
import TextLine from '../TextLine'
import VWBlock from '../VWBlock'
import * as pageElements from './helper'
import UserAccountTrigger from '../UserAccountTrigger'
import AppContext from '../../helpers/AppContext'

const SignUpProposal: React.FC = () => {
    const ctx = useContext(AppContext)

    if (ctx.currentUser) {
        return null
    }

    return (
        <VWBlock>
            <pageElements.Wrapper>
                <pageElements.InnerWrapper>
                    <Wavy color="black">
                        <TextLine
                            text="Sign Up at US$3.99/month"
                            type="subtitle"
                        />
                    </Wavy>
                    <pageElements.BodyWrapper>
                        <TextLine
                            text={pageElements.bodyCopy}
                            type="heading3"
                        />
                    </pageElements.BodyWrapper>
                    <pageElements.ButtonWrapper>
                    <UserAccountTrigger source="home page proposal">
                        <Button
                            text={"Sign Up Now"}
                            color={'grayDark'}
                            textType={'buttontextsmall'}
                        />
                    </UserAccountTrigger>
                    </pageElements.ButtonWrapper>
                </pageElements.InnerWrapper>
            </pageElements.Wrapper>
        </VWBlock>
    )
}

export default SignUpProposal
