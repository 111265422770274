import React, { useState, useRef, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, withRouter, useHistory  } from 'react-router-dom'
import theme from '../../theming'
import Button from '../Button'
import ImageSearcher from '../ImageSearcher'
import AppContext from '../../helpers/AppContext'
import TextLine from '../TextLine'

export interface Props extends RouteComponentProps {
    className?: string
    primary?: boolean
    leftAligned?: boolean
}

const TopSearchBox: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const [textSearch, setTextSearch] = useState(false)
    const inputElement = useRef<HTMLInputElement>(null)
    const history = useHistory()
    useEffect(() => {
        if (textSearch && inputElement.current) {
            inputElement.current.focus()
        }
    }, [textSearch])

    const imagePickerButton = (
        <Button 
            className="buttontext"
            cancelHover={true}
            textType="buttontext"
            icon="Camera"
            text="Search with image"
            full={true}
            color="grayLight"
            transparent={true}
            noBorder={true}
            size="20px"
            iconColor="black"
        />
    )
   
    const textSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (inputElement.current && inputElement.current.value) {
            history.push(`/text-search/?query=${inputElement.current.value}`)
        }
    }
   
    let content 
    if (textSearch) {
        content = (
            <div className="text-input">
                <form className="textForm" onSubmit={textSubmit}>
                    <input
                        ref={inputElement}
                        type="text"
                        placeholder="Search products, brands and categories"
                        onBlur={() => setTextSearch(false)}
                    />
                    <Button
                        iconOnly={true}
                        icon="Search"
                        color="black"
                        inline={true}
                        transparent={true}
                        padding={`${ctx.isDesktop ? '10px' : '5px'}`}
                        noBorder={true}
                        size="20px"
                        onClick={textSubmit}
                    />
                </form>
            </div>
        )
    } else {
        content = (
            <div className="top-dual-search-box">
                <div className="top-search-image-box">
                    <ImageSearcher>{imagePickerButton}</ImageSearcher>
                </ div>
                <div className="top-or-text">
                    <TextLine text="|" type="small" color={'grayLight'} />
                </div>
                <div className="top-search-text-box">
                    <Button
                        className="buttontext"
                        cancelHover={true}
                        transparent={true}
                        textType="buttontext"
                        icon="Search"
                        text="Search with text"
                        background="white"
                        color="grayLight"
                        noBorder={true}
                        full={true}
                        size="20px"
                        iconColor="black"
                        onClick={() => setTextSearch(true)}
                    />
                </div>
            </div>
        )
    }
    
    return (
        <div className={className}>
            {content}
        </div>
    )
}

const StyledSearchBox = styled(TopSearchBox)((props) => {
    return {
        ['.top-search-box-with-text']: {
            maxWidth: 500,
            margin: `${theme.space.larger} auto`
        },
        ['.top-dual-search-box']: {
            textAlign: 'center',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        ['.top-search-image-box']: {
            maxWidth: 240,
            display: 'inline-flex',
            justifyContent: 'space-between',
            background: 'transparent',
            margin: `${theme.space.smallest} ${props.leftAligned ? 0 : 'auto'}`,
            ['& .paste']: {
                marginLeft: theme.space.medium
            }
        },
        ['.top-search-text-box']: {
            maxWidth: 240,
            display: 'inline-flex',
            position: 'relative',
            justifyContent: 'space-between',
            margin: `${theme.space.smallest} ${props.leftAligned ? 0 : 'auto'}`,
            ['& .paste']: {
                marginLeft: theme.space.medium
            }
        }, 
        ['.buttontext']: {
            fontSize: 16,
        },

        ['.search-input']: {
            width: '130px',
        },
        ['.text-input']: {
            display: 'flex',
            border: `1px solid rgba(0, 0, 0, 0.5)`,
            width: '479px',
            marginTop: '5px',
            borderRadius: '8px',
            backgroundColor: 'white',
            paddingTop: '2px',
            justifyContent: 'space-evenly',
            boxShadow: theme.boxShadow,
            height: '35px'
        },
        ['.textForm']: {
            display: 'flex',
        },
        ['input']: {
            verticalAlign: 'super',
            padding: theme.space.smaller,
            fontSize: theme.font.body.fontSize,
            width: '399px',
            border: 'none',
        },
        ['input[type=text]:focus']: {
            outline: 'none'
        }
    }
})

export default withRouter(StyledSearchBox)
