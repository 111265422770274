import React, { useContext } from 'react'
import styled from 'styled-components'
import { DEMO_IMAGES } from '../../constants'
import { getRandomSample } from '../../helpers/utils'
import Section from '../Section'
import PictureCard from '../PictureCard'
import ReactGA from 'react-ga'
import AppContext from '../../helpers/AppContext'
import { useTranslate } from '../../helpers/useTranslate'

export interface Props {
    className?: string
}

const Explore: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const { translate } = useTranslate()
    const cards = getRandomSample(DEMO_IMAGES, 6).map((imageUrl, index) => (
        <ExploreCard
            key={`${index}--${imageUrl}`}
            imageSrc={imageUrl}
            index={index}
        />
    ))
    return (
        <div className={className}>
            <Section
                title={translate('HOMEPAGE_EXPLORE_TITLE')}
                subtitle={translate('HOMEPAGE_EXPLORE_SUBTITLE')}
                body={<div className="cardsContainer">{cards}</div>}
            />
        </div>
    )
}

const ExploreCard: React.FC<{ imageSrc: string; index: number }> = (props) => {
    const { imageSrc, index } = props
    const doGaTracking = () => {
        ReactGA.event({
            category: 'User',
            action: 'Searched from explore'
        })
    }
    return (
        <PictureCard
            onClick={doGaTracking}
            imageSrc={imageSrc}
            linkTo={`/search-result?image_url=${encodeURIComponent(imageSrc)}`}
            alt={`Sample image to search with - number ${index}`}
        />
    )
}

const StyledExplore = styled(Explore)((props) => {
    return {
        ['.cardsContainer']: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        }
    }
})
export default StyledExplore
