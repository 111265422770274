import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme, { Layer } from '../../theming'
import TextLine from '../TextLine'
import Button from '../Button'
import Icon from '../Icon'
import { useTranslate } from '../../helpers/useTranslate'

interface Props {
    layer?: Layer
    className?: string
}

const CookieBanner: React.FC<Props> = (props) => {
    const { className } = props
    const { translate: t } = useTranslate()
    const [showBanner, setShowBanner] = useState(false)

    useEffect(() => {
        const alreadySeen = window.localStorage.getItem('seen-cookie-banner')
        setShowBanner(!alreadySeen)
    }, [])

    const close = () => {
        setShowBanner(false)
        window.localStorage.setItem('seen-cookie-banner', 'true')
    }

    return (
        <div
            className={className}
            style={{ display: showBanner ? 'flex' : 'none' }}
        >
            <div className="cookieTextWrapper">
                <TextLine
                    type="heading5"
                    color="black"
                    text={t("COOKIE_BANNER_TITLE")}
                />

                <span className="cookieText">
                    {t('COOKIE_TEXT.0')} 
                    <a target={'_blank'} href="" color="blue">
                        {t('COOKIE_TEXT.1')}
                    </a>
                     {t('COOKIE_TEXT.2')} 
                    <a target={'_blank'} href="" color="blue">
                         {t('COOKIE_TEXT.3')}
                    </a>
                    . {t('COOKIE_TEXT.4')}
                </span>
            </div>
            <span className="buttonsWrapper">
                <Button
                    background="black"
                    inline={true}
                    color="white"
                    textType="buttontextsmall"
                    onClick={close}
                    text={t('COOKIE_BUTTON_ONE')}
                />
                <Button
                    inline={true}
                    transparent={true}
                    textType="buttontextsmall"
                    color="black"
                    newTab={true}
                    asLinkTo="/policies"
                    text={t('COOKIE_BUTTON_TWO')}
                    borderColor="grayLighter"
                />
            </span>
            <span className="close" onClick={close}>
                <Icon name="Close" className="icon" />
            </span>
        </div>
    )
}

const StyledCookieBanner = styled(CookieBanner)((props) => {
    return {
        backgroundColor: '#EAF2FD',
        color: theme.color.black,
        width: '100vw',
        justifyContent: 'flex-start',
        fontSize: '16px',
        padding: '10px 20px',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        zIndex: props.layer ? theme.layer[props.layer] : 'inherit',
        ['.cookieTextWrapper']: {
            width: '70vw',
            paddingBottom: '20px'
        },
        ['.cookieText']: {
            width: '70%',
            fontSize: '16px'
        },
        ['.close']: {
            cursor: 'pointer'
        },
        ['.buttonsWrapper']: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '20vw'
        },
        [`${theme.media.smallScreenBreakpoint}`]: {
            flexWrap: 'wrap',

            ['.cookieTextWrapper']: {
                width: '85vw',
                paddingBottom: '35px'
            },
            ['.cookieText']: {
                width: '80%',
                fontSize: '16px'
            },
            ['.close']: {
                cursor: 'pointer',
                alignSelf: 'flex-start',
                order: 1
            },
            ['.buttonsWrapper']: {
                order: 3,
                width: '70%'
            }
        }
    }
})

export default StyledCookieBanner
