import React, { useState } from 'react'
import styled from 'styled-components'
import TextLine from '../TextLine'
import Button from '../Button'
import theme from '../../theming'

interface Props {
    className?: string
    text: string
}

const Accordion: React.FC<Props> = (props) => {
    const inActive = {
        display: 'hide',
        icon: 'ChevronDown'
    }
    const active = {
        display: 'show',
        icon: 'ChevronUp'
    }
    const [visible, setVisible] = useState(inActive)
    const { children, text } = props

    const toggleVisibility = () => {
        if (visible.display === 'hide') {
            setVisible(active)
        } else {
            setVisible(inActive)
        }
    }

    return (
        <div className={props.className}>
            <div onClick={toggleVisibility} className="heading">
                <TextLine text={text} type="body" inline={true} />
                <div>
                    <Button
                        transparent={true}
                        color="primary"
                        round={true}
                        icon={visible.icon}
                        noBorder={true}
                        padding="0"
                        iconOnly={true}
                    />
                </div>
            </div>

            <div className={visible.display}>{children}</div>
        </div>
    )
}

const StyledAccordion = styled(Accordion)((props) => {
    return {
        cursor: 'pointer',
        ['.heading']: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: `${theme.space.medium} 0`
        },
        ['.show']: {
            overflow: 'scroll',
            maxHeight: '400px',
            transition: 'max-height 0.3s ease-in'
        },
        ['.hide']: {
            overflow: 'hidden',
            maxHeight: '0',
            transition: 'max-height 0.3s ease-out'
        }
    }
})

export default StyledAccordion
