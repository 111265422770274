import React from 'react'
import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'
import { CONTACT_LINK } from '../../constants'
import TextLine from '../TextLine'
import theme from '../../theming'
import { useLocation } from 'react-router-dom'
import logo from '../../../public/logo.svg'
import Image from '../Image'

// b2c: about us | blog | lykdat for business | contact ----- privacy policy | terms of service | lykdat 2021
// b2b: about us | try it out | lykdat app | contact ----- privacy policy | terms of service | lykdat 2021
interface FooterLink {
    text: string
    url: string
}

const GroupTwoLinks: FooterLink[] = [
    { text: 'Privacy policy', url: '/privacy#privacy-top' },
    { text: 'Terms of service', url: '/terms#terms-top' }
]

const B2BGroupOneLinks: FooterLink[] = [
    { text: 'About us', url: '/about#about-top' },
    { text: 'Try it out', url: '#try-it-out' },
    { text: 'Contact', url: '#contact' },
    { text: 'Docs', url: 'https://apidocs.lykdat.com/' },
]

const B2CGroupOneLinks: FooterLink[] = [
    { text: 'About us', url: '/about#about-top' },
    { text: 'Blog', url: 'https://blog.lykdat.com' },
    { text: 'Lykdat for business', url: 'https://solutions.lykdat.com?source=footer' },
    { text: 'Contact', url: CONTACT_LINK }
]

export interface Props {
    className?: string
}

const Wrapper = styled.div<{ padded?: boolean }>`
    background: ${theme.color.primaryLighter};
    padding: ${theme.space.smaller} ${theme.space.small}
    ${({ padded }) => padded && '8px'};
    flex: 1;
    margin: 0px -12px -${theme.space.small} ;
    ${theme.media.below.md`
        margin: 0 0 4rem 0;
        padding: ${theme.space.smaller} ${theme.space.smallest};
    `}
`
const InnerWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    ${theme.media.above.md`
        display: grid;
        grid-template-columns: auto 1fr;   
        
    `}
`
const LinkGroup = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
    flex-direction: column;
    ${theme.media.above.md`
        flex-direction: row;
        width: auto
    `}
`
const FooterItemWrapper = styled.div`
    margin: ${theme.space.smallest} ${theme.space.medium}
        ${theme.space.smallest} 0;
`
const Logo = styled(Image)`
    margin-right: ${theme.space.large};
    margin-bottom: ${theme.space.small};
    ${theme.media.above.md`
        margin-bottom: 0;
    `}
`
const LinksWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    ${theme.media.above.md`
        width: auto
    `}
`
const Footer: React.FC<Props> = (props) => {
    const { className } = props
    const appRoute = useLocation()
    const getLinkLeafs = (fLink: FooterLink, indx: number) => {
        const linkLeafTextElement = (
            <TextLine color="black" text={fLink.text} type="body" />
        )
        if (fLink.url.startsWith('http') || fLink.url.startsWith('mailto')) {
            return (
                <a
                    key={`${indx}--${fLink.text}`}
                    href={fLink.url}
                    target="_blank"
                >
                    {linkLeafTextElement}
                </a>
            )
        }

        return (
            <Link key={`${indx}--${fLink.text}`} to={fLink.url} smooth={true}>
                {linkLeafTextElement}
            </Link>
        )
    }
    // 169 is the code for &copy;
    const copyRight = `${String.fromCharCode(
        169
    )} ${new Date().getFullYear()} lykdat.com`
    const groupTwoItems = [
        ...GroupTwoLinks.map((item, index) => (
            <FooterItemWrapper key={index}>
                {getLinkLeafs(item, index)}
            </FooterItemWrapper>
        )),
        <FooterItemWrapper key="copyright">
            <TextLine text={copyRight} color="gray" />
        </FooterItemWrapper>
    ]
    const linksToRender =
        appRoute.pathname === '/business' ? B2BGroupOneLinks : B2CGroupOneLinks
    const groupOneItems = linksToRender.map((item, index) => (
        <FooterItemWrapper key={index}>
            {getLinkLeafs(item, index)}
        </FooterItemWrapper>
    ))

    return (
        <Wrapper padded={appRoute.pathname !== '/business'}>
            <InnerWrapper>
                <Logo src={logo} />
                <LinksWrapper>
                    <LinkGroup>{groupOneItems}</LinkGroup>
                    <LinkGroup>{groupTwoItems}</LinkGroup>
                </LinksWrapper>
            </InnerWrapper>
        </Wrapper>
    )
}

export default Footer