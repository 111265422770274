import React from 'react'
import theme from '../../theming'

const Terms: React.FC = () => {
    return (
        <div style={{...theme.font.body, lineHeight: '35px'}}>
            <h2 id="h.mrhf5v31q7pt">
                <span>Who we are and how to contact us</span>
            </h2>
            <p>
                <span>lykdat.com is a site operated by Lykdat Tech (&quot;Lykdat&quot;).
                    Lykdat is registered in The Netherlands under Kvk number 80277160.
                </span>
            </p>
            <p>
                <span>
                    To contact us, please email &nbsp;
                    <a href="mailto:contact@lykdat.com">contact@lykdat.com</a>.
                </span>
            </p>
            <p>
                <span>
                    For any enquiries regarding our privacy and cookie policy, please email &nbsp;
                    <a href="mailto:contact@lykdat.com">contact@lykdat.com</a>.
                </span>
            </p>
            <h2 id="h.xfh4qixhvsvk">
            <span>By using our site you accept to these terms</span>
            </h2>
            <p>
            <span>By creating a lykdat.com account, you are agreeing to be bound by these
                terms and conditions between you and Lykdat. If you do not agree to
                these terms, you must not use our site. We recommend that you print a
                copy of these terms for future reference</span
            >
            </p>
            <h2 id="h.gr30dt53lvpz">
                <span>Making changes to these terms</span>
            </h2>
            <p>
            <span>Lykdat reserves the right to update and change the Terms of Service. We
                will notify you about such changes via email. Continued use of the
                service after any such change shall constitute your consent.</span
            >
            </p>
            <h2 id="h.n1uv8jwqkbf7">
                <span>Making changes to site</span>
            </h2>
            <p>
                <span>Lykdat may update and change its site from time to time to reflect
                    changes to its products, its users&#39; needs and its business
                    priorities. We will try to give you reasonable notice of any major
                    changes.
                </span>
            </p>
            <p>
                <span>Lykdat shall not be liable to you or to any third party for any
                    modification, price change, suspension or discontinuance of its
                    Service.</span>
            </p>
            <h2 id="h.tro09tg20qjj"><span>Liability</span></h2>
            <p>
            <span>You expressly understand and agree that Lykdat shall not be liable for
                any direct, indirect, incidental, special, consequential or exemplary
                damages, including but not limited to, damages for loss of profits,
                goodwill, use, data or other intangible losses (even if Lykdat has been
                advised of the possibility of such damages), resulting from your use of
                this service.</span
            >
            </p>
            <h2 id="h.bqwh6zn0ysh2"><span>Account Terms</span></h2>
            <ol className="c10 lst-kix_d6ide4ky3qof-0 start" start={1}>
            <li>
                <span>You must be 13 years or older to use this Service.</span>
            </li>
            <li>
                <span>You must be a human. Accounts registered by &quot;bots&quot; or other
                automated methods are not permitted.</span>
            </li>
            <li>
                <span>You are responsible for maintaining the security of your account and
                password.</span>
            </li>
            <li>
                <span>You are responsible for all content posted and activity that occurs
                under your account, this includes all uploads made by users of your
                service that you are integrating Lykdat into.</span>
            </li>
            <li>
                <span>Lykdat may contact you about your account or updates to our service
                at any point.</span>
            </li>
            <li>
                <span>We will never sell your contact information.</span>
            </li>
            <li>
                <span>You grant Lykdat the right to use your company name and logo in
                promotional material. If you need an exception to this, please send us
                an email at &nbsp;
                </span>
                <span><a href="mailto:contact@lykdat.com">contact@lykdat.com</a></span>
                <span>&nbsp;before you sign up.</span>
            </li>
            </ol>
            <h2 id="h.wy5f1ny9e6xu">
                <span>Payment &amp; Pricing</span>
            </h2>
            <ol start={1}>
            <li>
                <span>E</span><span>ach month, on completion of a 30-day cycle after your previously due
                invoice, you will be charged according to your excess service usage
                for the last 30 days, and according to the fixed monthly plan.</span>
            </li>
            <li>
                <span>Invoices are due immediately.</span>
            </li>
            <li>
                <span>You may cancel your account at any time. Cancelling implies you will
                only be charged for your excess service usage in your current 30-day
                cycle.</span>
            </li>
            <li>
                <span>If we cannot charge your credit card, and you do not provide a
                working replacement within 30 days, we will cancel your account. We
                may cancel your account earlier than that if your account shows
                excessive usage patterns and you are not responsive via email.</span>
            </li>
            <li>
                <span>We may make changes to our pricing in the future. Continued usage of
                our service will require accepting the new pricing..</span>
            </li>
            <li>
                <span>We reserve the right to retract discounts that you earned on signup
                or by any other means at our own discretion.</span>
            </li>
            </ol>
            <h2 id="h.ld97uxqpwuof">
                <span>Refund Policy</span>
            </h2>
            <ol className="c10 lst-kix_ai1j1nhc1vik-0 start" start={1}>
            <li>
                <span>Generally, there are no refunds.</span>
            </li>
            <li>
                <span>If you would still like a refund, please explain your problems to us
                in detail, we will make exceptions on a case by case basis.</span>
            </li>
            </ol>
            <h2><span>Ownership of Extended Solutions and PoCs</span></h2>
            <p>
                <span>
                    Any solutions, products, or Intellectual Property developed by Lykdat for your
                    specific use case or requirement, including but not limited to extended solutions
                    and proof-of-concept (PoC) products, shall remain the sole property of Lykdat, and
                    we reserve the right to redistribute and reuse such solutions or products as we see fit.
                    By engaging us to provide solutions or products for a specific use case or requirement,
                    you agree to the terms of this clause and acknowledge that you have no claim or
                    ownership over any solutions, products, or intellectual property developed by Lykdat in the
                    course of delivering the requested solution or product.
                </span>
            </p>
            <h2><span>Global Image Search API</span></h2>
            <p>
                <span>
                    The recommendations generated through the use of Lykdat's Global Image Search API may include media content, such as product images,
                    that are owned by third-party sellers or other entities. Lykdat does not own or claim any rights to this media content.
                    We advise all users of our API to ensure that any media content obtained through the use of our service is properly attributed
                    to its original source, including by providing a link or other reference to the entity that owns the content.
                </span>
            </p>
            <h2 id="h.wgxn60y8ksj2"><span>Severability Clause</span></h2>
            <p>
                <span>
                    If a provision of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect:
                </span>
            </p>
            <ol start={1}>
            <li>
                <span>The validity or enforceability in that jurisdiction of any other
                provision of this Agreement; or</span>
            </li>
            <li>
                <span>The validity or enforceability in other jurisdictions of that or any
                other provision of this Agreement.</span>
            </li>
            <li>
                <span>Invalid provisions shall be replaced by valid ones, that are as close
                as possible to the originals.</span>
            </li>
            </ol>
            <h2 id="h.8ior71uk7pe7">
                <span>Legal Jurisdiction</span>
            </h2>
            <p>
            <span>The legal jurisdiction for any disputes under this agreement is
                Amsterdam, The Netherlands.</span>
            </p>
            <p>
            <span>This document was last updated on: </span><span>April 07, 2023</span>
            </p>
        </div>
    )
}

export default Terms
