import React, { SyntheticEvent, useContext } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import Image from '../Image'
import Button, { PrimaryCta } from '../Button'
import Cropper from '../Cropper'
import AppContext from '../../helpers/AppContext'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { search } from '../../helpers/apiClient'
import axios from 'axios'
import ReactGA from 'react-ga'
import { useTranslate } from '../../helpers/useTranslate'

export interface SRControl {
    icon?: string
    text: string
    action?: (e: SyntheticEvent) => any
    asLink?: string
}

export interface Props extends RouteComponentProps {
    className?: string
    imageSrc: string
    control?: SRControl
}

const SearchResultsDashboard: React.FC<Props> = (props) => {
    const {
        className,
        imageSrc,
        control,
    } = props

    const ctx = useContext(AppContext)
    const { translate } = useTranslate()

    const onRefineClick = () => {
        const cropper = (
            <div>
                <Cropper
                    image={imageSrc}
                    finisher={cropFinisher}
                    onCropComplete={onCropComplete}
                />
            </div>
        )
        ctx.openDrawer(cropper)
    }

    const onImageClick = () => {
        const imageViewer = (
            <div style={{ height: '60vh' }}>
                <Image
                    className="expandedQueryImage"
                    height="100%"
                    width="100%"
                    src={imageSrc}
                    alt="query image"
                    fit="contain"
                    setCors={false}
                />
            </div>
        )
        ctx.setDrawerCta('Done')
        ctx.openDrawer(imageViewer)
    }

    // @todo: undupe duplicated code
    const onCropComplete = (cropped: CropperOutput) => {
        ctx.openDrawer(null)

        const onSearched = (resultURL: string) => {
            // @todo: search-result value should be imported from Nav/data
            if (props.location.pathname === '/search-result/') {
                props.history.push('/empty')
                props.history.replace(resultURL)
            } else {
                props.history.push(resultURL)
            }
        }
        const onSearchError = (err: Error) => console.log(err)
        if (cropped.imageUrl.startsWith('http')) {
            const options = {
                imageURL: cropped.imageUrl,
                boundingBox: cropped.boundingBox
            }
            search(options, ctx)
                .then(onSearched)
                .catch(onSearchError)
        } else {
            axios({
                method: 'get',
                url: cropped.imageUrl,
                responseType: 'blob'
            }).then((response) => {
                const options = {
                    image: response.data,
                    boundingBox: cropped.boundingBox
                }
                search(options, ctx)
                    .then(onSearched)
                    .catch(onSearchError)
            })
        }
    }

    const doGaTracking = () => {
        ReactGA.event({
            category: 'User',
            action: 'Finish crop | Search by upload'
        })
    }
    const cropFinisher = (
        <PrimaryCta
            onClick={doGaTracking}
            text={translate('SEARCH_RESULTS_SEARCH_AGAIN')}
            full={true}
        />
    )
    
    const getControl = (control: SRControl) => (
        <Button
            text={control.text}
            textType="bodysmallest"
            icon={control.icon}
            color="primary"
            transparent={true}
            noBorder={true}
            inline={true}
            sizeByScale="small"
            onClick={control.action}
            asLinkTo={control.asLink}
            className="shareButton"
        />
    ) 
    
    return (
        <div className={className}>
            <div className="imageBlock" >
                <div className="queryImage" onClick={onImageClick}>
                    <Image
                        className="queryImage__img"
                        height="100%"
                        width="100%"
                        src={imageSrc}
                        alt="query image"
                        fit="cover"
                        setCors={false}
                    />
                </div>
                <div className="queryText">
                    <div className="queryImageOverlay1">
                        <Button
                            text="Edit Image"
                            textType="caption"
                            background="black"
                            noBorder={true}
                            inline={true}
                            sizeByScale="small"
                            onClick={onRefineClick}
                            className="editButton"
                        />
                    </div>
                    <div className="queryImageOverlay3">
                        {control && getControl(control)}
                    </div> 
            </div>                      
            </div>
        </div>
    )
}

const StyledSearchResultsDashboard = styled(SearchResultsDashboard)((props) => {
    return {
        display: 'flex',
        maxHeight: 156,
        position: 'relative',
        borderRadius: theme.size.smaller,
        margin: `${theme.space.medium} 0`,
        [`${theme.media.largeScreenBreakpoint}`]: {
            maxHeight: 220,
            margin: `0 0 130px`,
        },
        ['.imageBlock']: {
            width: '250px',
            position: 'relative',
            borderRadius: theme.size.smaller,
            cursor: 'pointer',
            overflow: 'hidden',
            height: '326px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                width: '150px',
                height: '174px',
            }
        },
        ['.queryImage, .queryImage__img']: {
            height: '100%',
            borderRadius: theme.size.smaller
        },
        ['.queryText']: {
            display: 'flex'
        },
        // @todo: extract this into a component
        // or the entire image thing with text overlay at the bottom
        ['.queryImageOverlay1']: {
            display: 'flex',
            position: 'absolute',
            bottom: theme.size.medium,
            left: '20px',
            justifyContent: 'center',
            alignContent: 'center',
            fontWeight: 600,
            [`${theme.media.smallScreenBreakpoint}`]: {
                left: '27px',
                top: '94px',
                bottom: '48px',
            }
        },
        ['.queryImageOverlay3']: {
            display: 'flex',
            position: 'absolute',
            bottom: theme.size.medium,
            left: '128px',
            justifyContent: 'center',
            backgroundColor: theme.color.white,
            alignContent: 'center',
            border: `1px solid #EBEBEB`,
            borderRadius: theme.size.smallest,
            fontWeight: 600,
            [`${theme.media.smallScreenBreakpoint}`]: {
                left: '27px',
                bottom: '10px'
            }
        },
        ['.shareButton']: {
            padding: `7px`,
            minWidth: '95px',
            ['.icon']: {
                marginLeft: '15px',
                marginRight: '-55px'
            }
        },
        ['.editButton']: {
            padding: `7px`,
            minWidth: '95px'
        }
    }
})
export default withRouter(StyledSearchResultsDashboard)
