import styled from 'styled-components'
import TextLine from '../TextLine'
import React from 'react'
import theme from '../../theming'

export const FeedbackHeading = styled.p`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
`

export const MainWarpper = styled.main`
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    color: #666;
    position: relative;
    z-index: 0;
    ${theme.media.largeScreenBreakpoint} {
        padding: 0 60px;
    }

    .video-react .video-react-big-play-button {
        font-size: 3em;
        line-height: 1.5em;
        height: 1.5em;
        width: 1.5rem;
        display: block;
        position: absolute;
        top: calc(50% - 1.5rem);
        left: calc(50% - 1.5rem);
        padding: 22px;
        cursor: pointer;
        opacity: 1;
        border: 0.08666em solid #000;
        background-color: #2b333f;
        background-color: rgb(209 209 209 / 70%);
        border-radius: 75.3em;
        transition: all 0.4s;
    }
    .fill {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .my-0 {
        margin: 0 auto;
    }
    .m-0 {
        margin: 0;
    }
    .py-2 {
        padding: 2rem 0;
    }
    .pt-2 {
        padding-top: 2rem;
    }
    .p-2 {
        padding: 0;
        ${theme.media.largeScreenBreakpoint} {
            padding: 2rem;
        }
    }
    .flex {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
    }
    .flex-1 {
        flex: 1 1 100%;
        ${theme.media.largeScreenBreakpoint} {
            flex: 1 1 33%;
        }
    }
    .center {
        text-align: center;
    }
    .w-auto {
        width: auto;
    }
    .v-center {
        flex-direction: column;
        justify-content: center;
    }
    .h-center {
        justify-content: center;
    }
`

export const Text = styled(TextLine)`
    margin: 10px 0;
    text-justify: newspaper;
    text-align: justify;
    letter-spacing: -0.02em;
    line-height: 1.6;
`

export const Section = styled.section`
    padding: 60px 0;
    &.text-center {
        text-align: center;
    }
`
export const CalloutSection = styled.section`
    background: #080808;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 24px 16px;
    ${theme.media.largeScreenBreakpoint} {
        padding: 60px;
    }
    .do-more-pictures {
        display: none;
        ${theme.media.largeScreenBreakpoint} {
            display: grid;
            flex: 1 1 33%;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: 1fr 1fr;
            justify-content: center;
            gap: 10px;
        }
    }
    .do-more-pictures-left-1 {
        grid-area: 1/1/2/2;
    }
    .do-more-pictures-left-2 {
        grid-area: 2/1/2/2;
    }
    .do-more-pictures-right {
        grid-area: 1/2/3/3;
    }
    .do-more-details {
        display: flex;
        flex: 1 1 33%;
        flex-direction: column;
        justify-content: center;
    }
    .do-more-text {
        padding: 16px 0 24px;
        ${theme.media.largeScreenBreakpoint} {
            padding: 16px 0 40px;
        }
    }
`

export const MarkedText = styled.span`
    display: inline-block;
    position: relative;
    margin: 0 0.2em;

    & span {
        position: relative;
        z-index: 1;
    }

    &::after {
        display: inline-block;
        width: 100%;
        content: '';
        position: absolute;
        z-index: 0;
        background: linear-gradient(
            180deg,
            rgba(242, 153, 74, 0.56) 0%,
            rgba(242, 153, 74, 0) 100%
        );
        left: 0;
        right: 0;
        bottom: 0;
        top: 60%;
    }
`
export const SectionTitle = styled.h3`
    font-weight: 700;
    width: 100%;
    color: #080808;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
`
export const SectionHeading: React.FC<{ title: string }> = ({ title }) => {
    return (
        <SectionTitle>
            <MarkedText>
                <span>{title}</span>
            </MarkedText>
        </SectionTitle>
    )
}

export const HeroText = styled.h2`
    font-weight: 700;
    color: #080808;
    font-size: 32px;
    letter-spacing: -0.01em;
    margin: 16px 0;
    ${theme.media.largeScreenBreakpoint} {
        font-size: 40px;
    }
`

export const HeroImage = styled.img`
    width: 100%;
    ${theme.media.largeScreenBreakpoint} {
        width: 600px;
    }
`
export const HeroWrapper = styled.div`
    padding: 40px 20px 0;
`
