import React, { SyntheticEvent } from 'react'
import { transparentize, darken } from 'polished'
import Icon from '../Icon'
import TextLine from '../TextLine'
import theme, { ColorType, Scale, TextType } from '../../theming'
import { NavLink } from 'react-router-dom'
import { Interface } from 'readline'

export type ButtonType = 'button' | 'reset' | 'submit' | undefined

export interface Props {
    className?: string
    transparent?: boolean
    text?: string
    color?: ColorType
    icon?: string
    full?: boolean
    iconOnly?: boolean
    onClick?: (e: SyntheticEvent) => any
    cancelHover?: boolean
    noBorder?: boolean
    padding?: string
    sizeByScale?: Scale
    size?: string
    inline?: boolean
    round?: boolean
    background?: ColorType
    bgOpacity?: number
    textType?: TextType
    asLinkTo?: string
    newTab?: boolean
    buttonType?: ButtonType
    disabled?: boolean
    borderColor?: ColorType
    textClassName?: string
    iconColor?: ColorType
}

export const getTextContent = (textProps: Props): JSX.Element | null => {
    const colorKey = (textProps.transparent || textProps.background) && textProps.color
    if (textProps.text) {
        return (
            <TextLine
                text={textProps.text}
                color={colorKey || 'white'}
                type={textProps.textType || 'buttontext'}
                className={textProps.textClassName}
            />
        )
    }
    return null
}

export const getIconContent = (iconProps: Props): JSX.Element | null => {
    const colorKey = (iconProps.transparent || iconProps.background) && iconProps.color
    if (iconProps.icon) {
        return (
            <Icon
                sizebyScale={iconProps.sizeByScale}
                name={iconProps.icon}
                color={iconProps.iconColor || colorKey || 'white'}
                className="icon"
                size={iconProps.size}
            />
        )
    }
    return null
}

export const getButtonOrLink = (pageProps: Props, pgContent?: JSX.Element): JSX.Element => {
    let pageResult = (
        <button
            className={pageProps.className}
            onClick={pageProps.onClick}
            type={pageProps.buttonType || 'button'}
            disabled={pageProps.disabled} 
        >
            {pgContent}
        </button>
    )
    if (pageProps.asLinkTo) {
        pageResult = (
            <NavLink
                target={pageProps.newTab ? '_blank' : ''}
                className={pageProps.className}
                to={pageProps.asLinkTo}
            >
                {pgContent}
            </NavLink>
        )
    }
    return pageResult
}

export const getButtonBorder = (
    borderColor?: ColorType, 
    noBorder?: boolean, 
    transparent?: boolean, 
    color?: ColorType): string => {
    const borderColorKey = borderColor || (!noBorder && transparent && color)

    if (borderColorKey) {
        return `1px solid ${theme.color[borderColorKey]}`
    }
    return 'none'
}

export const getButtonPadding = (padding?: string, iconOnly?: boolean): string => {
    if (padding) {
        if (iconOnly) {
            return padding
        } else {
            return`${parseInt(padding) / 2}px ${padding}`
        }
    } else if (iconOnly) {
        return theme.space.medium
    } else {
        return `${theme.space.small} ${theme.space.large}`
    }
}

export const getButtonHeight = (
    textType?: TextType, 
    padding?: string, 
    iconOnly?: boolean): number => {
    const paddingValue = getButtonPadding(padding, iconOnly)
    const doublePadding = 2 * parseInt(`${paddingValue}`.split(' ')[0])
    return parseInt(theme.font[textType || 'buttontext'].lineHeight) + doublePadding
}

export const getBgColor = (
    transparent?: boolean, 
    background?: ColorType, 
    color?: ColorType, 
    bgOpacity?: number): string => {
    if (transparent) {
        return 'transparent'
    }
    return transparentize(
        bgOpacity || 0,
        theme.color[background || color || 'primary']
    )
}

export const getHoverResult = (
    cancelHover?: boolean, 
    transparent?: boolean, 
    background?: ColorType, 
    color?: ColorType, 
    bgOpacity?: number): string => {
    const bgColorValue = getBgColor(transparent, background, color, bgOpacity)
    if (cancelHover) {
        return 'initial'
    } else if (transparent) {
        return theme.color.accentLightest
    }
    return darken(0.1, bgColorValue)
}

export const getIconClassMarginRight = (
    iconOnly?: boolean, 
    noBorder?: boolean, sizeByScale?: Scale): string | number => {
    if (iconOnly) {
        return 0
    } else if (noBorder) {
        if (sizeByScale === 'small') {
            return theme.space.small
        } else {
            return theme.space.medium
        }
    }
    return theme.space.large
}

export const getButtonWidth = (full?: boolean): string => full ? '100%' : 'auto'

export const getCursor = (disabled?: boolean): string => disabled ? 'not-allowed' : 'pointer'

export const getDisplay = (inline?: boolean): string => inline ? 'inline-flex' : 'flex'

export const getBorderRadius = (
    round?: boolean, 
    iconOnly?: boolean, 
    textType?: TextType, 
    padding?: string): string => {
    if (round) {
        const buttonHeightVal = getButtonHeight(textType, padding, iconOnly)
        if (iconOnly) {
            return '50%'
        } else {
            return `${buttonHeightVal / 2}px`
        }
    }
    return '4px'
}

export const getButtonThemeColor = (transparent?: boolean, color?: ColorType) => {
    if (transparent && color) {
        return color
    }
    return 'white'
}