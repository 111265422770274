import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import TextLine from '../TextLine'
import Button from '../Button'
import axios from 'axios'
import AppContext from '../../helpers/AppContext'
import { saveSearchEsclation } from '../../helpers/storage'
export interface Props {
    queryImage: string
    searchMetaData: SearchMetaData[]
    className?: string
}

enum Status {
    initial = 'initial',
    submitting = 'submitting',
    submitted = 'submitted',
    failed = 'failed'
}

const SearchEscalator: React.FC<Props> = (props) => {
    const { className, queryImage, searchMetaData } = props
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState(Status.initial)
    const ctx = useContext(AppContext)
    const userEmail = ctx.currentUser ? ctx.currentUser.email : ''

    const handleSubmission = (e: any) => {
        e.preventDefault()
        if (email.trim() === '' && !userEmail) {
            return
        }
        setStatus(Status.submitting)
        const url = 'https://amoeba-search-escalation-vs6o6hcumq-uc.a.run.app/'
        const searchUrl = `https://lykdat.com/search-result/?image_url=${encodeURIComponent(
            queryImage
        )}`
        const data = {
            email: userEmail || email,
            queryImage,
            searchUrl,
            scores: searchMetaData
        }
        const config = {
            headers: {
                'content-type': 'application/json',
                ['Accept']: 'application/json'
            }
        }

        axios
            .post(url, data, config)
            .then((_res) => {
                saveSearchEsclation(queryImage)
                ctx.setToastMessage(`We'll be in touch!`)
                setStatus(Status.submitted)
            })
            .catch((_err) => {
                setStatus(Status.failed)
            })
    }

    // @todo: proper typing later
    const handleInputChange = (e: any) => {
        setEmail(e.target.value)
    }
    if (status === Status.submitted) {
        return null
    }
    const formClass = `escalationForm ${status === Status.submitting &&
        'submitting'}`
    const message = (
        <>
            <span>Cant find what you're looking for? </span>
            <br />
            <span>
                Drop us your email address and we'll reach out to you once we
                find it.
            </span>
        </>
    )
    const altmessage = (
        <>
            <span>Cant find what you're looking for? </span>
            <br />
            <span>
                Please let us know if we should notify you when we find
                something more similar.
            </span>
        </>
    )

    if (userEmail) {
        return (
            <div className={className}>
                <TextLine text={altmessage} />
                <form className={formClass} onSubmit={handleSubmission}>
                    <Button
                        text="Notify me"
                        buttonType="submit"
                        inline={true}
                        className="escalateSubmit"
                    />
                </form>
            </div>
        )
    } else {
        return (
            <div className={className}>
                <TextLine text={message} />
                <form className={formClass} onSubmit={handleSubmission}>
                    <input
                        type="email"
                        className="emailInput"
                        placeholder="email@example.com"
                        onChange={handleInputChange}
                    />
                    <Button
                        text="Notify me"
                        buttonType="submit"
                        inline={true}
                        className="escalateSubmit"
                    />
                </form>
            </div>
        )
    }
}

const StyledSearchEscalator = styled(SearchEscalator)((props) => {
    return {
        background: theme.color.white,
        display: 'block',
        borderRadius: theme.space.small,
        boxShadow: theme.boxShadow,
        height: '100%',
        width: '100%',
        padding: theme.space.medium,
        boxSizing: 'border-box',
        marginBottom: theme.space.larger,
        ['.emailInput']: {
            border: `1px solid ${theme.color.grayDark}`,
            borderRadius: theme.space.smaller,
            flex: 1,
            flexBasis: '100%',
            marginTop: theme.space.medium,
            padding: theme.space.small,
            ...theme.font.body
        },
        ['.escalationForm']: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'stretch',
            ['&.submitting']: {
                opacity: 0.3
            }
        },
        ['.escalateSubmit']: {
            flexBasis: '100%',
            marginTop: theme.space.medium,
            borderRadius: theme.space.smaller
        },
        [`${theme.media.largeScreenBreakpoint}`]: {
            ['.emailInput']: {
                maxWidth: 500,
                marginRight: theme.space.medium,
                marginBottom: 0
            },
            ['.escalateSubmit']: {
                flexBasis: 'auto',
                maxWidth: 500
            }
        }
    }
})
export default StyledSearchEscalator
