export const navItems: NavItem[] = [
    {
        key: 'index',
        icon: 'Home',
        routes: ['/'],
        copy: 'HOME_NAV',
        size: '18px'
    },
    {
        key: 'explore',
        icon: 'Loader',
        routes: ['/explore/'],
        copy: 'EXPLORE_NAV',
        size: '20px'
    },
    {
        key: 'search',
        icon: 'Camera',
        routes: ['/search/'],
        copy: 'SEARCH_NAV',
        size: '20px'
    },
    {
        key: 'user',
        icon: 'User',
        routes: ['/user/'],
        copy: 'USER_NAV',
        size: '20px'
    }
]
