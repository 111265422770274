import React, { useContext, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import SearchResultsDashboard from '../../components/SearchResultsDashboard'
import ProductList from '../../components/ProductList'
import Feedback from '../../components/Feedback'
import ProductListWrapper from '../../components/ProductListWrapper'
import SearchEscalator from '../../components/SearchEscalator'
import Section, { SectionControl } from '../../components/Section/Section'
import ToggleBar from '../../components/ToggleBar'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import SortAndFilter from '../../components/SortAndFilter'
import TextLine from '../../components/TextLine'
import AppContext from '../../helpers/AppContext'
import { toClipboard } from '../../helpers/utils'
import { search } from '../../helpers/apiClient'
import useSortAndFilterState from '../../helpers/SortAndFilterState'
import FloatingToolbar from '../../components/FloatingToolbar'
import { getSearchEsclations } from '../../helpers/storage'
import YouMayAlsoLike from '../../components/YouMayAlsoLike'
import Divider from '../../components/Divider'
import { useTranslate } from '../../helpers/useTranslate'
import theme from '../../theming'
import Icon from '../../components/Icon'
import { SortAndFilterButton } from '../../components/Button'

export interface Props extends RouteComponentProps<{ image_url: string }> {
    className?: string
    primary?: boolean
    icon?: string
}

const toProducts = (storageData: { [key in string]: any }): Product[] => {
    const flatProducts: Product[] = []
    if (storageData && storageData.results) {
        storageData.results.forEach((result: any, index: number) => {
            result.similar_products.forEach((product: Product) => {
                flatProducts.push({ ...product, tabId: index })
            })
        })
    }

    return flatProducts
}

const getSearchResult = (
    imgURL: string,
    ctx: AppContextInterface
): SearchResultData | null => {
    const result = ctx.lastSearchResult
    if (result && result.public_url === imgURL) {
        return result
    }

    return null
}

const getTabFromHash = (rawHash: string) => {
    return rawHash ? parseInt(rawHash.slice(1)) : 0
}
const SearchResult: React.FC<Props> = (props) => {
    const ctx = useContext(AppContext)
    const [ready, setReady] = useState(false)
    const [queryImage, setQueryImage] = useState('')
    const [sort, setSort, filter, setFilter] = useSortAndFilterState('similarity')
    const [currentTab, setCurrentTab] = useState(0)
    const [products, setProducts] = useState<Product[]>(
        toProducts(ctx.imageSearchResult || {})
    )
    const [isLowQualityResults, setIsLowQualityResults] = useState(false)
    const [sortedProducts, setSortedProducts] = useState<Product[]>(
        toProducts(ctx.imageSearchResult || {})
    )
    const [localizedObjects, setLocalizedObjects] = useState<LocalizedObject[]>(
        []
    )
    const [searchMetaData, setSearchMetaData] = useState<SearchMetaData[]>([])
    const [vendor, setVendor] = useState('')
    const { translate } = useTranslate()

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        setVendor(params.get('vendor') || '')
        const imgURL = params.get('image_url') || ''
        const srData = getSearchResult(imgURL, ctx)
        const targetTab = getTabFromHash(location.hash)
        if (srData) {
            setProducts(toProducts(srData))
            setCurrentTab(targetTab)
            setSortedProducts(toProducts(srData))
            setQueryImage(srData.public_url)
            let maxScore = 0
            const _localizedObjs: LocalizedObject[] = []
            const _searchMeta: SearchMetaData[] = []
            srData.results.forEach((r) => {
                maxScore = Math.max(r.max_score, maxScore)
                if (r.bounding_box) {
                    const boundingBox = r.bounding_box
                    _localizedObjs.push({ name: r.item_name, boundingBox })
                    _searchMeta.push({ boundingBox, maxScore: r.max_score })
                }
            })
            setLocalizedObjects(_localizedObjs)
            setSearchMetaData(_searchMeta)
            setIsLowQualityResults(
                maxScore < (srData.max_score_threshold || 0.5)
            )
            setReady(true)
            ctx.setLoader({ show: false, text: '' })
        } else if (imgURL) {
            search({ imageURL: imgURL }, ctx)
                .then((resultURL) => {
                    props.history.push('/empty')
                    props.history.replace(`${resultURL}#${targetTab}`)
                })
                .catch((err) => {
                    // todo report
                    console.log(err)
                })
        } else {
            props.history.push('/')
        }
    }, [
        setProducts,
        setQueryImage,
        setReady,
        setLocalizedObjects,
        setIsLowQualityResults
    ])

    const handleSortOutput = (
        processedList: Product[],
        output: SortAndFilterStateData
    ) => {
        setSortedProducts(processedList)
        setSort(output.sortedBy)
    }

    const handleFilterOutput = (
        processedList: Product[],
        output: SortAndFilterStateData
    ) => {
        setSortedProducts(processedList)
        setFilter(output.allFilteredBy)
    }

    const handleFilterAndSortOutput = (
        processedList: Product[],
        output: SortAndFilterStateData
    ) => {
        setSortedProducts(processedList)
        setSort(output.sortedBy)
        setFilter(output.allFilteredBy)
    }

    const hasEscalated = () => {
        return getSearchEsclations().indexOf(queryImage) >= 0
    }

    const resultInsert =
        isLowQualityResults && !hasEscalated()
            ? {
                  position: ctx.isDesktop ? 4 : 2,
                  component: (
                      <SearchEscalator
                          key="search-esaclator"
                          queryImage={queryImage}
                          searchMetaData={searchMetaData}
                      />
                  )
              }
            : undefined
    const sectionBody = (
        <ProductList
            products={sortedProducts}
            currentTabIndex={currentTab}
            useTabs={true}
            insert={resultInsert}
        />
    )
    const sortAndFilterProps = {
        products,
        sortedBy: sort,
        filteredBy: filter
    }

    const filters = (
        <>
            <TextLine type="heading5" text="Filters" />
            <SortAndFilter
                {...sortAndFilterProps}
                renderFilterOnly={true}
                onUpdate={handleFilterOutput}
            />
        </>
    )

    if (!ready && !sortedProducts) {
        return null
    }
    const toggleBar = localizedObjects.length > 0 && (
        <ToggleBar
            current={currentTab}
            items={localizedObjects}
            queryImage={queryImage}
            switchTabs={setCurrentTab}
        />
    )

    const copyToClipboard = () => {
        const url = `https://lykdat.com/search-result/?image_url=${encodeURIComponent(
            queryImage
        )}#${currentTab}`
        toClipboard(url).then(() =>
            ctx.setToastMessage('Link has been copied to clipboard')
        )
    }

    const shareControl = {
        text: 'Share',
        icon: 'Share',
        action: copyToClipboard
    }

    const toolbarItems = [
        {
            icon: 'Filter',
            onClick: () => {
                ctx.setDrawerCta('Done')
                ctx.openDrawer(filters)
            }
        }
    ]

    const srDashboardWrapper = (
        <div className="boardWrapper">
            <SearchResultsDashboard imageSrc={queryImage} control={shareControl} />
            {toggleBar}
        </div>
    )
    const lowScoreInfo = (
        <div className="lowScoreInfo">
            <Icon name={'BiError'} className="errorIcon" />
            <TextLine
                text={translate('SEARCH_RESULTS_LOW_SCORE_WARNING')}
                type="caption"
            />
        </div>
    )

    const sectionControls: SectionControl[] = []
    if (!ctx.isDesktop) {
        const drawerContent = (
            <SortAndFilter
                {...sortAndFilterProps}
                onUpdate={handleFilterAndSortOutput}
            />
        )
        sectionControls.push({
            content: <SortAndFilterButton />,
            onClick: () => {
                ctx.setDrawerCta('Done')
                ctx.openDrawer(drawerContent)
            }
        })
    } else {
        sectionControls.push({
            content: (<SortAndFilter
                {...sortAndFilterProps}
                renderSortOnly={true}
                onUpdate={handleSortOutput}
            />),
            onClick: () => {}
        })
    }

    const textSuffix = vendor ? ` (${vendor})` : ''
    const main = (
        <div>
            {ctx.isDesktop ? null : srDashboardWrapper}
            <div className="mainText">
                <TextLine
                    type="heading4"
                    bold={true}
                    text={translate('SIMILAR_PRODUCTS_HEADER') + textSuffix}
                />
                {isLowQualityResults && (
                    <div className="lowScore">{lowScoreInfo}</div>
                )}
            </div>
            <Section body={sectionBody} controls={sectionControls} />
        </div>
    )
    const aside = (
        <div className="sr-aside">
            {srDashboardWrapper}
            <div>
                <Divider color="grayDarker" />
            </div>
            <SortAndFilter
                {...sortAndFilterProps}
                renderFilterOnly={true}
                onUpdate={handleFilterOutput}
            />
        </div>
    )
    return (
        <>
            <Helmet>
                <title>
                    Search Results &bull; LykDat &bull; Fashion Image Search
                </title>
            </Helmet>
            <div className={props.className}>
                <ProductListWrapper main={main} aside={aside} />
                <FloatingToolbar
                    tools={ctx.isDesktop ? undefined : toolbarItems}
                />
            </div>
            <YouMayAlsoLike />
            <Feedback />
        </>
    )
}

const StyledSearchResult = styled(SearchResult)((props) => {
    return {
        '.sr-aside': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        '.boardWrapper': {
            [`${theme.media.smallScreenBreakpoint}`]: {
                display: 'flex'
            }
        },
        '.mainText': {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        '.lowScoreInfo': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: `8px 16px`,
            background: `rgba(255, 52, 52, 0.2)`,
            hight: `${theme.size.large}`,
            width: '397px',
            marginRight: '264px',
            marginTop: `${theme.space.medium}`,
            marginBottom: `${theme.space.medium}`,
            position: 'sticky',
            fontSize: `${theme.size.small}`,
            lineHeight: '28px',
            opacity: 0.7,
            color: 'black',
            [`${theme.media.smallScreenBreakpoint}`]: {
                width: '340px',
                margin: `${theme.space.smaller}  0`,
                padding: `${theme.size.smaller} ${theme.size.smallest}`
            }
        },
        '.errorIcon': {
            margin: `2px ${theme.space.smaller}`
        }
    }
})
export default withRouter(StyledSearchResult)
