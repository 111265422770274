import React from 'react'
import styled from 'styled-components'

import RichSection from '../../components/RichSection'
import Button from '../../components/Button'
import TextLine from '../../components/TextLine'
import Image from '../../components/Image'
import theme from '../../theming'
import { B2B_ASSET_PATH } from '../../constants'

const ButtonWrapper = styled.div`
    display: flex;
    margin-top: ${theme.space.large};
    ${theme.media.from.md`
        margin-top: ${theme.space.larger};`};
    gap: 16px;
`

export const Wavy = styled.div<{ color?: string }>`
    display: inline;
    color: ${({ color }) => color && `${color}`};
`

const TryItOutButton = styled(Button)<any>`
    border-width: 2px;
`

const DisplayHeader = styled.div`
    margin-bottom: ${theme.space.large};
`
interface SectionProps {
    onGetStarted?: () => void
    onTryItOut?: () => void
    isDesktop?: boolean
}

export const Hero = ({ onGetStarted, onTryItOut, isDesktop }: SectionProps) => (
    <RichSection
        right={<Image src={B2B_ASSET_PATH + 'hero-image.png'} lazy={false} />}
        left={
            <div>
                <DisplayHeader>
                    <TextLine
                        text={
                            <>
                                Power your <Wavy color="#F2994A">fashion business</Wavy>{' '}
                                with{' '}
                                <Wavy color="#2F80ED">AI visual discovery</Wavy>
                            </>
                        }
                        type={isDesktop ? 'heading1' : 'heading3'}
                    />
                </DisplayHeader>
                <TextLine
                    text={`
                    Our cutting edge visual AI makes it easy for customers 
                    to find the right products even when they don’t have the right words.`}
                    type={isDesktop ? 'body' : 'small'}
                />
                <ButtonWrapper>
                    <Button
                        textType="buttontext"
                        text="Contact Us"
                        onClick={onGetStarted}
                    />
                    <TryItOutButton
                        textType="buttontext"
                        text="Try it out"
                        transparent={true}
                        color="black"
                        onClick={onTryItOut}
                    />
                </ButtonWrapper>
            </div>
        }
    />
)

const ImageSearchRichSection = styled(RichSection)`
    margin-top: 56px;
`

export const ImageSearch = ({ onGetStarted, isDesktop }: SectionProps) => (
    <ImageSearchRichSection
        left={
            <div>
                <TextLine
                    text={<Wavy color="#2F80ED">Image search</Wavy>}
                    type={isDesktop ? 'subtitle' : 'buttontextsmall'}
                />
                <TextLine
                    text={`Fashion is visual, search is not.
                        Drive sales and engagement with with an intuitive way to search`}
                    type={isDesktop ? 'heading3' : 'heading4'}
                />
                <ButtonWrapper>
                    <Button text="Contact Us" onClick={onGetStarted} />
                </ButtonWrapper>
            </div>
        }
        right={<Image src={B2B_ASSET_PATH + 'image-search.png'} lazy={false} />}
    />
)

export const Recommendations = ({ onGetStarted, isDesktop }: SectionProps) => (
    <RichSection
        left={
            <div>
                <TextLine
                    text={<Wavy color="#F2994A">Smart recommendations</Wavy>}
                    type={isDesktop ? 'subtitle' : 'buttontextsmall'}
                />
                <TextLine
                    text={`
                        Maintain customer engagement in situations where an item is out of stock.
                        Suggest similar products to reduce bounce rates,
                         increase basket size and drive sales.
                    `}
                    type={isDesktop ? 'heading3' : 'heading4'}
                />
                <ButtonWrapper>
                    <Button text="Contact Us" onClick={onGetStarted} />
                </ButtonWrapper>
            </div>
        }
        right={<Image src={B2B_ASSET_PATH + 'out-of-stock.png'} lazy={false} />}
    />
)

const StyledShopTheLookSection = styled(RichSection)`
    ${theme.media.smallScreenBreakpoint} {
        flex-direction: column-reverse;
    }
`

export const ShopTheLook = ({ onGetStarted, isDesktop }: SectionProps) => (
    <StyledShopTheLookSection
        right={
            <div>
                <TextLine
                    text={<Wavy color="#9B51E0">Shop the look</Wavy>} 
                    type={isDesktop ? 'subtitle' : 'buttontextsmall'}
                />
                <TextLine
                    text={`Inspire customers to complete the look to each 
                        product taking into account style, 
                        and stock to increase the shopping basket and drive sales.`}
                    type={isDesktop ? 'heading3' : 'heading4'}
                />
                <ButtonWrapper>
                    <Button text="Contact Us" onClick={onGetStarted} />
                </ButtonWrapper>
            </div>
        }
        left={<Image src={B2B_ASSET_PATH + 'shop-the-look.png'} lazy={false} />}
    />
)
