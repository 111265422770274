import React, { Suspense } from 'react'
import { hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { useSSR } from 'react-i18next'

import App from './App'
import MasApp from './MasApp'

import './i18n'

declare global {
    interface Window {
        env: any,
        initialI18nStore: any,
        initialLanguage: string,
    }
}

const BaseApp = () => {    
    useSSR(window.initialI18nStore, window.initialLanguage)

    return (
        <Suspense fallback={<div>Still loading i18n....</div>}>
            <BrowserRouter><App lang={window.env.LANG} textSearchResult={window.env.TEXT_SR} /></BrowserRouter>
        </Suspense>
    )
}

hydrate(
    <BaseApp />,
    document.getElementById('root')
)

if (module.hot) {
    module.hot.accept()
}
