import React, { ReactElement } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import TextLine from '../TextLine'

export interface SectionControl {
    content: ReactElement
    onClick: () => any
}

export interface Props {
    className?: string
    title?: string
    subtitle?: string | ReactElement
    body?: ReactElement
    controls?: SectionControl[]
    onControlClick?: () => any
    noBg?: boolean
    bottomCTA?: SectionControl
}

const Section: React.FC<Props> = (props) => {
    const { className, title, subtitle, body, bottomCTA } = props
    if (!title && !subtitle && !body) {
        return null
    }
    const sT =
        (subtitle &&
            (typeof subtitle === 'string' && (
                <TextLine className="subtitle" text={subtitle} type="body" />
            ))) ||
        subtitle

    const controls = (props.controls || []).map((control, i) => (
        <div onClick={control.onClick} key={'control-' + i}>
            {control.content}
        </div>
    ))

    let titleBlock

    const bottomCTAEl = bottomCTA ? (
        <div className="bottomCTA" onClick={bottomCTA.onClick}>
            {bottomCTA.content} &gt;
        </div>
    ) : null

    titleBlock = (
        <div className="sectiontitleBlock">
            {<TextLine text={title || ''} inline={true} type="heading4" />}
            <div className="controls">{controls}</div>
        </div>
    )

    return (
        <div className={className}>
            {titleBlock}
            {sT}
            {body && <div className="main">{body}</div>}
            {bottomCTAEl}
        </div>
    )
}

const StyledSection = styled(Section)((props) => {
    return {
        marginBottom: theme.space.large,
        ['& .sectiontitleBlock']: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            ['& .controls']: {
                display: 'flex',

                justifyContent: 'space-between',
                ['&>div']: { marginLeft: theme.space.small }
            }
        },
        ['& .subtitle']: {
            margin: `${theme.space.smaller} 0`
        },
        ['& .main']: {
            margin: `${theme.space.smaller} 0`
        },
        ['.bottomCTA']: {
            textAlign: 'center',
            textTransform: 'capitalize'
        }
    }
})
export default StyledSection
