import * as apiClient from './apiClient'
import { getRandomSample } from './utils'

/**
 * wraps extra functionality around the apiClient function to
 * get Explore Items. It handles all the logic needed before
 * the apiClient call can be made.
 */
const getExploreItems = async (
    ctx: AppContextInterface,
    allowGeneric: boolean = false,
    user?: firebase.default.User | null,
): Promise<ExploreItemData[]> => {
    user = user || ctx.currentUser

    if (user) {
        const token = await user.getIdToken()
        return apiClient.getExploreItems(token, ctx.userCountry, allowGeneric)
    }

    const recentProducts = await ctx.storage.getRecentProducts(ctx)
    if (!recentProducts.length) {
        return apiClient.getExploreItems(null, ctx.userCountry, allowGeneric)
    }

    const sample = getRandomSample(recentProducts, 4)
    const imgUrls = sample.map(
        (prod) => prod.matching_image || prod.images[0] || prod.vendor_images[0]
    )

    return apiClient.getExploreItems(null, ctx.userCountry, allowGeneric, imgUrls)
}

export default getExploreItems