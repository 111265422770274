import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import AppContext from '../../helpers/AppContext'
import ReactGA from 'react-ga'
import theme from '../../theming'
import TextLine from '../../components/TextLine'
import ExploreItem from '../../components/ExploreItem'
import FloatingToolbar from '../../components/FloatingToolbar'
import Feedback from '../../components/Feedback'
import SearchBox from '../../components/SearchBox'
import qs from 'qs'
import Button from '../../components/Button'
import { Element, scroller, animateScroll as scroll } from 'react-scroll'
import getExploreItems from '../../helpers/exploreItems'
import axios from 'axios'

export interface Props {
    className?: string
    primary?: boolean
}

const Explore: React.FC<Props> = (props) => {
    const { className } = props
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [hasErrored, setHasErrored] = useState(false)
    const [items, setItems] = useState<ExploreItemData[]>([])
    const [inspoImages, setInspoImages] = useState<
        { id: string; image: string }[]
    >([])
    const [shouldScrollTo, setShouldScrollTo] = useState('')
    const [hasReachedEnd, setHasReachedEnd] = useState(false)
    const ctx = useContext(AppContext)

    useEffect(() => {
        const stringifiedQuery = qs.stringify(
            {
                where: {
                    visible: {
                        equals: true
                    }
                },
                limit: 90 // ensure that `qs` adds the `where` property, too!
            },
            { addQueryPrefix: true }
        )
        const url = `https://cms-beta-app-001.lykdat.com/api/inspo-contest-entries${stringifiedQuery}`
        axios.get(url).then((res) => {
            const entriesData = res.data
            const entries = entriesData.docs
            setInspoImages(
                entries.map((e: object & Record<string, string>) => ({
                    image: e.image,
                    id: e.id
                }))
            )
        })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (shouldScrollTo) {
            scroller.scrollTo(shouldScrollTo, { offset: -100 })
        }
    }, [shouldScrollTo])
    useEffect(() => {
        // already prefetched
        if (ctx.exploreItems.length) {
            setItems(ctx.exploreItems)
            setIsLoading(false)
            return
        }

        getExploreItems(ctx, true)
            .then((itms) => {
                setItems(itms)
                ctx.setExploreItems(itms)
            })
            .catch((error) => {
                setHasErrored(true)
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    const getComponentId = (idx: number) => `explore-item-${idx}`

    const loadMoreItems = () => {
        ReactGA.event({
            category: 'User',
            action: 'Loaded more explore items'
        })
        if (hasReachedEnd) {
            scroll.scrollToTop()
            return
        }
        setIsLoadingMore(true)
        getExploreItems(ctx, true)
            .then((newItems) => {
                if (!newItems.length) {
                    setHasReachedEnd(true)
                    return
                }

                const itemsCombined = [...items, ...newItems]
                setItems(itemsCombined)
                ctx.setExploreItems(itemsCombined)
                setShouldScrollTo(getComponentId(items.length))
            })
            .catch((e) => {
                ctx.setToastMessage(
                    'Something went wrong :(, Please try again.'
                )
            })
            .finally(() => {
                setIsLoadingMore(false)
            })
    }
    const getContent = () => {
        if (isLoading) {
            return <TextLine type="body" text="loading..." />
        }
        if (hasErrored) {
            return (
                <TextLine
                    type="body"
                    text="error occured, please refresh page :("
                />
            )
        }
        const header = (
            <div className="exploreHeader">
                <TextLine type="heading4" bold={true} text={'Explore'} />
                <TextLine
                    type="body"
                    text="Shop for items inspired by these pictures from around the world"
                />
            </div>
        )
        const hasDuplicateProduct = (item: ExploreItemData) => {
            const uniqueCode = (prod: Product) =>
                `${prod.vendor}|${prod.price}|${prod.name}|${prod.score}`
            const p = item.products[0]
            for (let i = 1; i < item.products.length; i++) {
                if (uniqueCode(p) === uniqueCode(item.products[i])) {
                    return true
                }
            }
            return false
        }
        const list = items.map((item, index) => {
            const itemElement = hasDuplicateProduct(item) ? null : (
                <ExploreItem {...item} />
            )
            return (
                <Element
                    name={getComponentId(index)}
                    key={getComponentId(index)}
                >
                    {itemElement}
                </Element>
            )
        })

        const buttonLabel = hasReachedEnd
            ? 'Back to top'
            : isLoadingMore
            ? 'Loading...'
            : 'More'

        const searchBox = (
            <div className="searchBoxAside">
                <div style={{ position: 'fixed' }} className="searchBoxInner">
                    <SearchBox />
                </div>
            </div>
        )
        return (
            <div className={className}>
                {header}
                <div className="exploreList">
                    <div className="exploreListMainGrid">
                        <div>{list}</div>
                    </div>

                    <div className="moreButton">
                        <Button
                            text={buttonLabel}
                            onClick={loadMoreItems}
                            color={isLoadingMore ? 'gray' : 'grayDark'}
                            background="white"
                            padding={theme.space.smaller}
                            full={true}
                            borderColor="grayLighter"
                        />
                    </div>
                </div>
                {searchBox}
                <FloatingToolbar />
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Explore &bull; LykDat &bull; Fashion Image Search</title>
            </Helmet>
            <div>{getContent()}</div>
            <Feedback />
        </>
    )
}

const StyledExplore = styled(Explore)((props) => {
    return {
        margin: '0',
        ['.searchBoxAside']: {
            margin: theme.space.large,
            marginLeft: theme.space.larger,
            minWidth: 330,
            position: 'relative',
            flexShrink: 0
        },
        ['.searchBoxInner']: {
            border: `1px solid ${theme.color.grayLighter}`,
            padding: theme.space.large,
            height: 'fit-content'
        },
        ['.exploreList']: {
            paddingTop: theme.space.large
        },
        ['.exploreListMainGrid']: { display: 'flex' },
        ['.moreButton']: {
            width: 200,
            margin: `-${theme.space.large} auto ${theme.space.larger}`
        }
    }
})
export default StyledExplore
