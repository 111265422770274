import React from 'react'
import * as Icons from 'react-feather'
import { MdFavoriteBorder, MdFavorite } from 'react-icons/md'
import { FiTag, FiChevronDown, FiChevronUp  } from 'react-icons/fi'
import { ReactComponent as CustomHome } from './homeicon.svg'
import styled from 'styled-components'
import theme, { ColorType, Scale } from '../../theming'
import { ReactComponent as Logout } from './logout-exit.svg'
import { ReactComponent as Close } from './closeicon.svg'
import { BiError } from 'react-icons/bi'

export interface Props {
    className?: string
    color?: ColorType
    name: string
    sizebyScale?: Scale
    size?: string
}

const Icon: React.FC<Props> = (props) => {
    const rIcons = { Home: CustomHome, MdFavorite, MdFavoriteBorder, FiTag, Logout, Close,
            BiError, FiChevronDown, FiChevronUp  }
    const { className = '', color = 'grayDark', name, sizebyScale = 'medium', size } = props
    const I = name in rIcons ? rIcons[name] : Icons[name]
    return (
        <I
            className={className}
            size={size || theme.size[sizebyScale]}
            color={theme.color[color]}
        />
    )
}

const StyledIcon = styled(Icon)((props) => {
    return {
        fontWeight: 'bold',
        minWidth: theme.size[props.size || 'medium'],
    }
})

export default StyledIcon
