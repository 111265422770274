import React, { useContext } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import theme from '../../theming'
import Button, { PrimaryCta } from '../Button'
import ImageSearcher from '../ImageSearcher'
import AppContext from '../../helpers/AppContext'
import TextLine from '../TextLine'

export interface Props extends RouteComponentProps {
    className?: string
    primary?: boolean
    leftAligned?: boolean
}

const SearchBox: React.FC<Props> = (props) => {
    const { className } = props

    const content = (
        <div className="dual-search-box">
            <div className="search-image-box">
                <ImageSearcher
                    text="Search with image"
                    full={true}
                    noBorder={true}
                    className="SearchImageStyle"
                />
            </div>
        </div>
    )

    return (
        <div className={className}>
            {content}
        </div>
    )
}

const StyledSearchBox = styled(SearchBox)((props) => {
    return {
        ['.search-box-with-text']: {
            maxWidth: 500,
            margin: `${theme.space.larger} auto`
        },
        ['.dual-search-box']: {
            textAlign: 'center',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center'

            }
        },
        ['.search-image-box']: {
            borderStyle: 'solid',
            borderColor: '#000000',
            borderWidth: 1,
            background: '#000000',
            borderRadius: '6px',
        },
        ['.search-text-box']: {
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            borderWidth: '1px',
            borderRadius: '6px',

        },
        ['.SearchTextStyle']: {
            width: '244px',
            height: '44px',
            padding: '0px'

        },

        ['.SearchImageStyle']: {
            width: '244px',
            height: '44px',
            padding: '0px'
        },
    }
})

export default withRouter(StyledSearchBox)
