import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme, { Layer } from '../../theming'
import TextLine from '../TextLine'
import Icon from '../Icon'
import { useTranslate } from '../../helpers/useTranslate'

interface Props {
    layer?: Layer
    className?: string
}

const STORAGE_KEY = 'seen-disclosure-banner'
// const STORAGE_KEY = 'seen-ph-banner'

const DisclosureBanner: React.FC<Props> = (props) => {
    const { className } = props
    const { translate } = useTranslate()
    const [showBanner, setShowBanner] = useState(false)

    useEffect(() => {
        const alreadySeen = window.localStorage.getItem(STORAGE_KEY)
        setShowBanner(!alreadySeen)
    }, [])

    const close = () => {
        setShowBanner(false)
        window.localStorage.setItem(STORAGE_KEY, 'true')
    } 

    return (
        <div
            className={className}
            style={{ display: showBanner ? 'flex' : 'none' }}
        >
            <TextLine
                text={translate('DISCLOSURE_BANNER')}
                type="body"
                className="bannerText"
            />

            <span className="close" onClick={close}>
                <Icon name="Close" className="icon" />
            </span>
        </div>
    )
}

const StyledDisclosureBanner = styled(DisclosureBanner)((props) => {
    return {
        backgroundColor: theme.color.black,
        color: theme.color.white,
        width: '100vw',
        justifyContent: 'space-around',
        padding: '10px 5px',
        position: 'sticky',
        top: 0,
        zIndex: props.layer ? theme.layer[props.layer] : 'inherit',
        ['.close']: {
            cursor: 'pointer'
        },
        ['.bannerText']: {
            fontSize: '14px'
        },
        [`${theme.media.smallScreenBreakpoint}`]: {
            ['.bannerText']: {
                width: '350px',
                fontSize: '10px'
            }
        }
    }
})

export default StyledDisclosureBanner
