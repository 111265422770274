import React, { useContext } from 'react'
import { Wavy } from '../../screens/Business/Sections'
import Button from '../Button'
import TextLine from '../TextLine'
import VWBlock from '../VWBlock'
import * as pageElements from './helper'
import AppContext from '../../helpers/AppContext'
import { TextType } from '../../theming'

const BusinessEntryPoint: React.FC = () => {
    return (
        <VWBlock>
            <pageElements.Wrapper>
                <pageElements.InnerWrapper>
                    <Wavy color="black">
                        <TextLine
                            text="Lykdat for Businesses"
                            type="subtitle"
                        />
                    </Wavy>
                    <pageElements.BodyWrapper>
                        <TextLine
                            text={pageElements.bodyCopy}
                            type="heading3"
                        />
                    </pageElements.BodyWrapper>
                    <pageElements.ButtonWrapper>
                        <Button
                            newTab={true}
                            asLinkTo="/business?source=home-entry-point"
                            text="Learn more"
                        />
                    </pageElements.ButtonWrapper>
                </pageElements.InnerWrapper>
            </pageElements.Wrapper>
        </VWBlock>
    )
}

export const DesktopHomeBusinessEntryPoint: React.FC = () => {
    const { isDesktop } = useContext(AppContext)
    return (
        <VWBlock>
            <pageElements.Wrapper>
                <pageElements.InnerWrapper>
                    <pageElements.BodyWrapper>
                        <TextLine
                            text={pageElements.bodyCopy}
                            type={
                                `${
                                    isDesktop ? 'heading1' : 'heading3'
                                }` as TextType
                            }
                        />
                    </pageElements.BodyWrapper>
                    <pageElements.ButtonWrapper>
                        <Button
                            newTab={true}
                            asLinkTo="/business?source=home-entry-point"
                            text="Learn more"
                        />
                    </pageElements.ButtonWrapper>
                </pageElements.InnerWrapper>
            </pageElements.Wrapper>
        </VWBlock>
    )
}

export default BusinessEntryPoint
