import React from 'react'
import TextLine from '../TextLine'

export const getTextList = (loader: LoaderProps): string[] => {
    let textList: string[] = ['']
    if (loader.textList && loader.textList.length) {
        textList = loader.textList
    } else if (loader.text) {
        textList = [loader.text]
    }
    return textList
}

export const getText = (textList: string[], current: number) => {
    return textList.length ? (
        <TextLine text={textList[current]} color="grayDark" type="body" />
    ) : null
}

export const changeTextIdAtIntervals = (
    setCurrent: React.Dispatch<React.SetStateAction<number>>, 
    textList: string[], current: number
) => {
    setTimeout(() => {
        setCurrent(Math.min(textList.length - 1, current + 1))
    }, 2300)
}