import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import closeIcon from './helper'
import {
    useDrawerCloser,
    transitionValue,
    doneButton
} from '../../helpers/drawerControl'

export interface Props {
    className?: string
}

const SideDrawer: React.FC<Props> = (props) => {
    const { className } = props
    const drawerRef = useRef<HTMLDivElement>(null)
    const [left, setLeft] = useState('100vw')
    const [closed, closing, drawer, drawerCta, close, open] = useDrawerCloser()

    const closeDrawer = () => close(() => setLeft('100vw'))

    useEffect(() => {
        if (closing) {
            return
        }

        if (drawer) {
            open(() => setLeft(`calc(100vw - 400px)`))
        } else if (!closed) {
            closeDrawer()
        }
    }, [closing, drawer])

    const onOverlayClick = (e: SyntheticEvent) => {
        closeDrawer()
        e.stopPropagation()
    }

    return (
        <div style={{ left: closed ? '100vw' : 0 }} className={className}>
            {drawer && <div className="overlay" onClick={onOverlayClick} />}
            <div className="drawer" style={{ left }} ref={drawerRef}>
                <div className="inner">
                    <div className="body">
                        {drawer}
                        {closeIcon(closeDrawer)}
                    </div>
                </div>
                {doneButton(drawerCta, closeDrawer)}
            </div>
        </div>
    )
}

const StyledSideDrawer = styled(SideDrawer)((props) => {
    return {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: theme.size.larger,
        width: '100vw',
        height: '100vh',
        zIndex: theme.layer.appShell + 1,
        ['.overlay']: {
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            backgroundColor: theme.color.black,
            opacity: 0.3
        },
        ['.drawer']: {
            position: 'absolute',
            width: '400px',
            height: '100vh',
            backgroundColor: theme.color.white,
            transition: `left ease-out ${transitionValue}s`
        },
        ['.close']: {
            cursor: 'pointer',
            position: 'absolute',
            right: '10px',
            top: '10px'
        },
        ['.inner']: {
            padding: theme.space.large,
            paddingTop: theme.space.largest
        },
        ['.inner > .body']: {
            paddingTop: '10px',
            display: 'flex',
            justifyContent: 'center',
            maxHeight: '100vh',
            overflowY: 'auto'
        },
        ['.doneButtonContainer']: { padding: theme.space.large }
    }
})

export default StyledSideDrawer
