import React, { useContext } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import axios from 'axios'
import theme from '../../theming'
import { PrimaryCta } from '../Button'
import ImagePicker from '../ImagePicker'
import Cropper from '../Cropper'
import AppContext from '../../helpers/AppContext'
import { search } from '../../helpers/apiClient'
import ReactGA from 'react-ga'
import { Props as ButtonProps } from '../Button/Button'

export interface Props extends RouteComponentProps, ButtonProps  {
    className?: string
    children?: React.ReactElement
}

const ImageSearcher: React.FC<Props> = (props) => {
    const ctx = useContext(AppContext)
    const cropFinisher = <PrimaryCta icon="Check" full={true} />
    const onImageAdded = (src: string) => {
        const cropper = (
            <div>
                <Cropper
                    image={src}
                    finisher={cropFinisher}
                    onCropComplete={onCropComplete}
                />
            </div>
        )
        ctx.openDrawer(cropper)
    }

    const onCropComplete = (cropped: CropperOutput) => {
        ctx.openDrawer(null)

        const onSearched = (resultURL: string) => {
            // @todo: search-result value should be imported from Nav/data
            if (props.location.pathname === '/search-result/') {
                props.history.push('/empty')
                props.history.replace(resultURL)
            } else {
                props.history.push(resultURL)
            }
        }

        const onSearchError = (err: Error) => console.log(err)

        if (cropped.imageUrl.startsWith('http')) {
            const options = {
                imageURL: cropped.imageUrl,
                boundingBox: cropped.boundingBox
            }
            search(options, ctx)
                .then(onSearched)
                .catch(onSearchError)

            ReactGA.event({
                category: 'User',
                action: 'Search by image URL'
            })
        } else {
            axios({
                method: 'get',
                url: cropped.imageUrl,
                responseType: 'blob'
            }).then((response) => {
                const options = {
                    image: response.data,
                    boundingBox: cropped.boundingBox
                }
                search(options, ctx)
                    .then(onSearched)
                    .catch(onSearchError)
            })

            ReactGA.event({
                category: 'User',
                action: 'Search by upload'
            })
        }
    }

    return (
        <ImagePicker
            onImageAdded={onImageAdded}
            text={props.text}
            full={props.full}
            noBorder={props.noBorder}
            className={props.className}
            color={props.color}
        >
            {props.children}
        </ImagePicker>
    )
}

const StyledImageSearcher = styled(ImageSearcher)((props) => {
    return {
        ['.search-box-with-text']: {
            maxWidth: 500,
            margin: `${theme.space.larger} auto`
        },
        ['.dual-search-box']: {
            textAlign: 'center',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center'

            }
        },

        ['.search-image-box']: {
            borderStyle: 'solid',
            borderColor: '#000000',
            borderWidth: 1,
            background: '#000000',
            borderRadius: '6px',
        },
        ['.search-text-box']: {
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            borderWidth: '1px',
            borderRadius: '6px',

        },
        ['.SearchTextStyle']: {
            width: '244px',
            height: '44px',
            padding: '0px'

        },

        ['.SearchImageStyle']: {
            width: '244px',
            height: '44px',
            padding: '0px'
        },
    }
})

export default withRouter(ImageSearcher)
