import React, { useContext } from 'react'
import styled from 'styled-components'
import theme, { Scale } from '../../theming'
import AppContext from '../../helpers/AppContext'
import TextLine from '../TextLine'

export interface Props {
    className?: string
}

const Toast: React.FC<Props> = (props) => {
    const { className } = props
    const { toastMessage, setToastMessage } = useContext(AppContext)
    let content: React.ReactElement | null = null
    if (toastMessage) {
        let timeout = 2300
        let text = ''
        const style = { backgroundColor: theme.color.grayDark }
        if (typeof toastMessage === 'string') {
            text = toastMessage
        } else {
            text = toastMessage.text
            timeout = toastMessage.timeout || timeout
            if (toastMessage.type === 'error') {
                style.backgroundColor = '#c22' // red
            }
        }

        content = (
            <div className={className} style={style}>
                <TextLine color="white" text={text} type="body" center={true} />
            </div>
        )
        setTimeout(() => setToastMessage(''), timeout)
    }
    return content
}

const StyledToast = styled(Toast)((props) => {
    return {
        position: 'fixed',
        bottom: '100px',
        left: '50%',
        width: '80%',
        maxWidth: '250px',
        padding: theme.space.small,
        borderRadius: theme.space.smaller,
        transform: 'translate(-50%, -50%)',
    }
})
export default StyledToast
