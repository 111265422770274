import React from 'react'
import styled from 'styled-components'
import theme, { ColorType, TextType } from '../../theming'

export interface Props {
    type?: TextType
    className?: string
    text: string | React.ReactNode
    inline?: boolean
    color?: ColorType | string
    center?: boolean
    twoLiner?: boolean
    oneLiner?: boolean
    bold?: boolean
    strike?: boolean
    underline?: boolean
}

const TextLine: React.FC<Props> = (props) => {
    const {
        className,
        text,
        type = 'body',
        inline = false,
        strike = false
    } = props
    const tagMap: { [key in TextType]: any } = {
        body: inline ? 'span' : 'div',
        caption: inline ? 'span' : 'div',
        heading1: 'h1',
        heading2: 'h2',
        heading3: 'h3',
        heading4: 'h4',
        heading5: 'h5',
        heading6: 'h6',
        small: inline ? 'span' : 'div',
        buttontext: inline ? 'span' : 'div',
        buttontextsmall: inline ? 'span' : 'div',
        bodysmallbold: inline ? 'span' : 'div',
        bodysmallest: inline ? 'span' : 'div',
        subtitle: 'h5'
    }
    const Tag = tagMap[type]
    const cName = `${className} ${type} ${props.twoLiner ? 'twoLiner' : ''} ${
        props.oneLiner ? 'oneLiner' : ''
    }`

    return <Tag className={cName}>{text}</Tag>
}

const StyledTextLine = styled(TextLine)((props) => {
    const fontStyle = theme.font[props.type || 'body']
    const color = theme.color[props.color!] 
        ? theme.color[props.color || 'black'] 
        : props.color

    let textDecorVal = 'initial'
    if (props.strike) {
        textDecorVal = 'line-through'
    } else if (props.underline) {
        textDecorVal = 'underline'
    }

    return {
        // use theme here e.g theme.color.primary
        ...fontStyle,
        fontWeight: props.bold ? 'bold' : fontStyle.fontWeight,
        WebkitFontSmoothing: 'antialiased',
        color,
        display: props.inline ? 'inline-block' : 'block',
        textDecoration: textDecorVal,
        textUnderlinePosition: 'under',
        cursor: props.underline ? 'pointer' : 'inherit',
        ['h1&, h2&, h3&, h4&, h5&, h6&']: {
            margin: `${theme.space.medium} 0`
        },
        textAlign: props.center ? 'center' : 'inherit',
        ['&.twoLiner']: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            maxHeight: `calc(${theme.font.body.lineHeight} * 2)`
        },
        ['&.oneLiner']: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            maxHeight: `theme.font.body.lineHeight`
        },

        //  @todo: express these as multiples of what's defined in Base.ts
        ['@media (max-width: 350px)']: {
            ['&.display']: {
                fontSize: '26px',
                lineHeight: '34px'
            },
            ['&.subdisplay']: { fontSize: '14px', lineHeight: '20px' },
            ['&.title']: { fontSize: '18px', lineHeight: '26px' }
        },
        [`${theme.media.largeScreenBreakpoint}`]: {
            ['&.display']: {
                fontSize: '60px',
                lineHeight: '96px'
            },
            ['&.subdisplay']: { fontSize: '28px', lineHeight: '32px' },
            ['&.title']: { fontSize: '22px', lineHeight: '30px' },
            ['&.body']: { fontSize: '16px', lineHeight: '22px' },
            ['&.small']: { fontSize: '14px', lineHeight: '20px' },
            ['&.caption']: { fontSize: '12px', lineHeight: '18px' }
        }
    }
})
export default StyledTextLine
