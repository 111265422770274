import ReactGA from 'react-ga'
import * as apiClient from './apiClient'

export function saveRecentSearch(
    searchResp: SearchResultData,
    ctx: AppContextInterface
) {
    return new Promise<void>((resolve, reject) => {
        if (!ctx.currentUser) {
            return reject(new Error('user is not logged in'))
        }

        ctx.currentUser.getIdToken().then((token) => {
            apiClient
                .saveRecentSearch(searchResp.public_url, token)
                .then(() => resolve())
                .catch(reject)
        })
    })
}

export function getRecentSearches(ctx: AppContextInterface) {
    return new Promise<RecentSearch[]>((resolve, reject) => {
        if (!ctx.currentUser) {
            return reject(new Error('user is not logged in'))
        }

        ctx.currentUser
            .getIdToken()
            .then((token) => {
                getRecentItemsThrottled(token).then((data) => {
                    resolve(data.recent_searches)
                })
            })
            .catch(reject)
    })
}

export function saveLikedProduct(product: Product, ctx: AppContextInterface) {
    return new Promise<void>((resolve, reject) => {
        if (!ctx.currentUser) {
            return reject(new Error('user is not logged in'))
        }

        ctx.currentUser.getIdToken().then((token) => {
            apiClient
                .saveLikedProduct(product, token)
                .then(() => resolve())
                .catch(reject)
        })

        ReactGA.event({
            category: 'User',
            action: 'Saved product'
        })
    })
}

export function unsaveLikedProduct(product: Product, ctx: AppContextInterface) {
    return new Promise<void>((resolve, reject) => {
        if (!ctx.currentUser) {
            return reject(new Error('user is not logged in'))
        }

        ctx.currentUser.getIdToken().then((token) => {
            apiClient
                .unsaveLikedProduct(product.id, token)
                .then(() => resolve())
                .catch(reject)
        })

        ReactGA.event({
            category: 'User',
            action: 'Unsaved product'
        })
    })
}

export function getLikedProducts(ctx: AppContextInterface) {
    return new Promise<Product[]>((resolve, reject) => {
        if (!ctx.currentUser) {
            return reject(new Error('user is not logged in'))
        }

        ctx.currentUser.getIdToken().then((token) => {
            getLikedProductsThrottled(token)
                .then((data) => {
                    resolve(data.saved_products)
                })
                .catch(reject)
        })
    })
}

export function saveRecentProduct(product: Product, ctx: AppContextInterface) {
    return new Promise<void>((resolve, reject) => {
        if (!ctx.currentUser) {
            return reject(new Error('user is not logged in'))
        }

        ctx.currentUser.getIdToken().then((token) => {
            apiClient
                .saveRecentProduct(product, token)
                .then(() => resolve())
                .catch(reject)
        })
    })
}

export function getRecentProducts(ctx: AppContextInterface) {
    return new Promise<Product[]>((resolve, reject) => {
        if (!ctx.currentUser) {
            return reject(new Error('user is not logged in'))
        }

        ctx.currentUser
            .getIdToken()
            .then((token) => {
                getRecentItemsThrottled(token).then((data) => {
                    const prods = data.recent_products.map((i) => i.product)
                    resolve(prods)
                })
            })
            .catch(reject)
    })
}

// there are cases where getRecentItems could be called multiple times simultaneously
// so to avoid the unneccessary parallel call, we'll re-use the same call
let recentItemsCall: Promise<apiClient.RecentItemsResponse> | null = null
function getRecentItemsThrottled(token: string) {
    if (!recentItemsCall) {
        recentItemsCall = apiClient.getRecentItems(token)
    }
    recentItemsCall.finally(() => {
        recentItemsCall = null
    })

    return recentItemsCall
}

// there are cases where getLikedProducts could be called multiple times simultaneously
// so to avoid the unneccessary parallel call, we'll re-use the same call
let likedProductsCall: Promise<apiClient.LikedProductsResponse> | null = null
function getLikedProductsThrottled(token: string) {
    if (!likedProductsCall) {
        likedProductsCall = apiClient.getLikedProducts(token)
    }
    likedProductsCall.finally(() => {
        likedProductsCall = null
    })

    return likedProductsCall
}
