import React from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import CutOut from './CutOut'
import HorizontalScroll from '../HorizontalScroll'
import TextLine from '../TextLine'

export interface Props {
    className?: string
    items: LocalizedObject[]
    current: number
    queryImage: string
    switchTabs: (tabIndex: number) => any
}

const ToggleBar: React.FC<Props> = (props) => {
    const { className } = props

    const content = props.items.map((item, index) => (
        <div
            className="locItem"
            key={index}
            onClick={() => props.switchTabs(index)}
        >
            <CutOut
                imageSrc={props.queryImage}
                item={item}
                highlight={index === props.current}
            />
            <TextLine
                className="bubbleCaption"
                text={item.name || 'Item'}
                type="caption"
                color="black"
            />
        </div>
    ))
    const heading =
        content.length > 1 ? 'Multiple items detected' : 'Single item detected'
    return (
        <div className={className}>
            <HorizontalScroll className="heading" heading={heading} items={[...content]} />
        </div>
    )
}

const StyledToggleBar = styled(ToggleBar)((props) => {
    return {
        ['.locItem']: {
            cursor: 'pointer',
            borderRadius: '6px',
            [':first-of-type']: {
                paddingLeft: 0
            },
            width: '76px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                width: '52px',
            },
        },
        ['.heading']: {
            display: 'inline-grid',
            [`${theme.media.smallScreenBreakpoint}`]: {
                display: 'block',
                height: '180px', 
                margin: '15px 0 10px 10px',
            },
        },
        '.bubbleCaption': {
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'left',
            margin: '4px 5px 0 5px',
            width: '76px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                width: '52px',
            },
        },
    }
})
export default StyledToggleBar
