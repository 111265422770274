import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import TextLine from '../../components/TextLine'
import Image from '../../components/Image'
import theme from '../../theming'
import { DEMO_IMAGES } from '../../constants'
import { apiURL, getHeaders } from '../../helpers/apiClient'
import AppContext from '../../helpers/AppContext'
import ReactGA from 'react-ga'
import axios from 'axios'

interface Props {
    className?: string
}

const HorizontalScroll = styled.div<any>`
    overflow-x: scroll;
    height: auto;
    ${theme.media.from.lg`
        overflow-x: hidden;
    `}
`

const QueryImageScroll = styled(HorizontalScroll)`
    padding: 3px;
    ${theme.media.from.md`
        padding: 6px;
    `}
`

const QueryImageGroup = styled.div`
    width: 100%;
    min-width: 600px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: ${theme.space.small};
    padding: ${theme.space.medium} 0 ${theme.space.larger};
    ${theme.media.from.md`
        column-gap: ${theme.space.medium};
        padding: ${theme.space.medium} 0 ${theme.space.largest};
        margin-top: ${theme.space.larger};
        padding-top: ${theme.space.large};
    `}
`

const HighlightRing = styled.div`
    border-radius: 9px;
    border: 2px solid black;
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    ${theme.media.from.md`
        border-radius: 14px;
        top: -6px;
        bottom: -6px;
        left: -6px;
        right: -6px;
    `}
`

const QueryImageWrapper = styled.div`
    box-sizing: border-box;
    ${theme.media.from.md`
        height: 150px;
    `}
    height: 70px;
    position: relative;
    cursor: pointer;
`

const ProductGroup = styled.div`
    display: grid;
    width: 100%;
    min-width: 600px;
    column-gap: ${theme.space.medium};
    ${theme.media.from.md`
        margin: ${theme.space.small} auto;
        min-height: 430px;
        column-gap: ${theme.space.larger};
    `};
    grid-template-columns: repeat(4, minmax(0, 1fr));
    overflow: auto;
    min-height: 300px;
`

const RoundedImage = styled(Image)`
    border-radius: 6px;
    ${theme.media.from.md`
    border-radius: 8px;`}
`

const ProdDetailsContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(2, auto);
    row-gap: ${theme.space.small};
    padding-top: ${theme.space.medium};
`

const HeaderText = styled(TextLine)`
    ${theme.media.from.md`
    text-align: center;`}
`

const Wrapper = styled.div`
    margin: ${theme.space.medium} 0;
    padding: ${theme.space.medium} 0;
    ${theme.media.from.md`
        margin: ${theme.space.largest} 0;
        padding: ${theme.space.largest} 0;
    `}
`

const TryItOut: React.FC<Props> = (props) => {
    const ctx = useContext(AppContext)
    const [currentQueryIndex, setCurrentQueryIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const queryImages = DEMO_IMAGES.slice(0, 7)
    const [results, setResults] = useState<Product[][]>(
        Array(queryImages.length).fill([])
    )
    const selectNewQuery = (indx: number) => {
        setCurrentQueryIndex(indx)
    }
    useEffect(() => {
        if (results[currentQueryIndex].length) {
            setIsLoading(false)
            return
        }

        const fd: FormData = new FormData()
        fd.append(
            'image_url',
            encodeURIComponent(queryImages[currentQueryIndex])
        )
        axios
            .post(
                apiURL('/search'),
                fd,
                getHeaders({
                    contentType: 'multipart/form-data',
                    countryCode: ctx.userCountry
                })
            )
            .then((response) => {
                setResults((old) => {
                    const temp = [...results]
                    temp[
                        currentQueryIndex
                    ] = response.data.results[0].similar_products.slice(0, 4)
                    return temp
                })
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [currentQueryIndex])
    const queryImagesBlock = (
        <QueryImageGroup>
            {queryImages.map((imgSrc, index) => {
                const highlightRing =
                    index === currentQueryIndex ? <HighlightRing /> : null
                const handleClick = () => {
                    ReactGA.event({
                        category: 'User',
                        action: 'Clicked on try it out image on business page'
                    })
                    setIsLoading(true)
                    selectNewQuery(index)
                }
                return (
                    <QueryImageWrapper key={index} onClick={handleClick}>
                        {highlightRing}
                        <RoundedImage
                            src={imgSrc}
                            height="100%"
                            width="100%"
                            fit="cover"
                        />
                    </QueryImageWrapper>
                )
            })}
        </QueryImageGroup>
    )

    const productsBlock = isLoading ? (
        <TextLine text="loading..." type="caption" />
    ) : (
        results[currentQueryIndex].map((prod, index) => {
            return (
                <div key={index}>
                    <RoundedImage
                        src={prod.images[0]}
                        height={ctx.isDesktop ? '355' : '210'}
                        width="100%"
                        fit="cover"
                    />
                    <ProdDetailsContainer>
                        <TextLine
                            text={prod.name}
                            type="body"
                            oneLiner={true}
                        />
                        <TextLine text={prod.price} type="body" bold={true} />
                    </ProdDetailsContainer>
                </div>
            )
        })
    )

    return (
        <Wrapper id="try-it-out">
            <HeaderText
                type={ctx.isDesktop ? 'heading3' : 'heading4'}
                text="Try it yourself"
            />
            <QueryImageScroll>{queryImagesBlock}</QueryImageScroll>

            <TextLine text="Results" type="heading5" />
            <HorizontalScroll>
                <ProductGroup>{productsBlock}</ProductGroup>
            </HorizontalScroll>
        </Wrapper>
    )
}

export default TryItOut
