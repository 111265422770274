import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import ProductCard from '../ProductCard'

export interface Props {
    className?: string
    products: Product[]
    insert?: { position: number; component: React.ReactElement }
    currentTabIndex?: number
    noSimilarityBadge?: boolean
    exploreTracker?: () => void
    useTabs?: boolean
    maxLength?: number
    onProductClick?: () => void
    disableTracking?: boolean
}

const matchingTab = (tab: number, productTab: number, useTabs: boolean) => {
    return Boolean(!useTabs || tab === productTab)
}

const ListWrapper = styled.div`
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr));
    ${theme.media.from.md`
        grid-template-columns: repeat(4, minmax(0, 1fr));
    `}
    column-gap: ${theme.space.medium};
    width: 100%;
    .product: {
        display: none
    }
    .visibleProduct: {
        display: block
    }
`

const ProductList: React.FC<Props> = (props) => {
    const {
        currentTabIndex = 0,
        useTabs = false,
        products,
        insert,
        noSimilarityBadge = false,
        exploreTracker,
        maxLength,
        onProductClick,
        disableTracking
    } = props
    if (!products.length) {
        return null
    }
    const [tab, setTab] = useState(0)
    useEffect(() => {
        setTab(currentTabIndex)
    }, [currentTabIndex])
    const uniqueProducts = {}
    const getUniqueId = (p: Product): string => {
        // console.log(`${p.vendor}|${p.price}|${p.name}|${p.score}`)
        return `${p.vendor}|${p.price}|${p.name}|${p.score}`
    }
    let lastBadgeCount = 0
    const cards = products.map((product, index) => {
        product.score = product.score || 0
        const productTab = product.tabId || 0
        const uniqueId = getUniqueId(product)
        if (!matchingTab(tab, productTab, useTabs)) {
            return null
        }
        if (uniqueProducts[uniqueId]) {
            return null
        }
        uniqueProducts[uniqueId] = true

        // @todo: revisit peformant tab implementation
        // that doesnt reload images everytime
        const productCssClasses = `${
            matchingTab(tab, productTab, useTabs) ? 'visibleProduct' : 'product'
        }`
        let similarityBadgeText = ''
        if (product.score > 0.9) {
            similarityBadgeText = 'Exact match!'
            lastBadgeCount++
        } else if (lastBadgeCount === 0 && product.score > 0.59) {
            similarityBadgeText = 'Most similar'
            lastBadgeCount++
        } else if (lastBadgeCount > 0 && product.score > 0.59) {
            similarityBadgeText = 'Very similar'
            lastBadgeCount++
        }
        similarityBadgeText = noSimilarityBadge ? '' : similarityBadgeText
        return (
            <ProductCard
                key={`${product.id}--${index}`}
                product={product}
                className={productCssClasses}
                similarityBadgeText={similarityBadgeText}
                exploreTracker={exploreTracker}
                onClick={onProductClick}
                disableTracking={disableTracking}
            />
        )
    })
    const uncappedValidCards = cards.filter((c) => c !== null)
    const validCards = maxLength
        ? uncappedValidCards.slice(0, maxLength)
        : uncappedValidCards
    let validCardsGroup2 = null
    const content = [<ListWrapper key="group1">{validCards}</ListWrapper>]
    if (insert && insert.position) {
        validCardsGroup2 = validCards.splice(insert.position)
        content.push(
            ...[
                insert.component,
                <ListWrapper key="group2">{validCardsGroup2}</ListWrapper>
            ]
        )
    }
    return <div>{content}</div>
}

export default ProductList
