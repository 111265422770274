import { ColorType, Layer, Scale, TextType } from './Base'
import theme from './Default'

export type TextType = TextType

export type ColorType = ColorType

export type Scale = Scale

export type Layer = Layer

export default theme
