import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import TextLine from '../../components/TextLine'
import theme from '../../theming'
import Icon from '../../components/Icon'
import AppContext from '../../helpers/AppContext'
import Button from '../../components/Button'

interface Props {
    className?: string
}

const ButtonWrapper = styled.div`
    display: flex;
    margin-top: ${theme.space.large};
    ${theme.media.from.md`
        margin-top: ${theme.space.larger};`};
    gap: 16px;
`

const PricingGroup = styled.div`
    display: grid;
    width: 100%;
    column-gap: ${theme.space.medium};
    ${theme.media.from.md`
        margin: ${theme.space.small} auto;
        min-height: 430px;
        column-gap: 150px;
        grid-template-columns: 1fr 1fr;
        min-width: 600px;
    `};
    grid-template-columns: 300px;
    overflow: auto;
    min-height: 300px;
`

const PricingDetailsContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(2, auto);
    row-gap: ${theme.space.small};
    padding: ${theme.space.large} ${theme.space.large};
    ${theme.media.from.md`
        padding-left: ${theme.space.largest};
        border-left: 1px solid #dfdfdf;
    `};
`

const HeaderText = styled(TextLine)`
    ${theme.media.from.md`
    text-align: center;`}
`

const Wrapper = styled.div`
    margin: ${theme.space.medium} 0;
    padding: ${theme.space.medium} 0;
    ${theme.media.from.md`
        margin: ${theme.space.largest} 0;
        padding: ${theme.space.largest} 0;
    `}
`

const Pricing: React.FC<Props> = (props) => {
    const ctx = useContext(AppContext)
    const pricingBlock = (
        <>
            <div>
                <PricingDetailsContainer>
                    <TextLine text="Free" type="heading4" color="#2F80ED" />
                    <TextLine text="$0/mo" type="heading4" bold={true} />
                    <TextLine
                        text={<><Icon name="Check" sizebyScale="small" /> Import 10,000 products</>}
                        type="small"
                        oneLiner={true}
                    />
                    <TextLine text="Upgrade to import more products" type="caption" />
                    <br/>
                    <TextLine
                        text={<><Icon name="Check" sizebyScale="small" /> Create 3 product catalogs</>}
                        type="small"
                        oneLiner={true}
                    />
                    <br/>
                    <TextLine
                        text={<><Icon name="Check" sizebyScale="small" /> Execute first 500 image searches for free</>}
                        type="small"
                        oneLiner={true}
                    />
                    <TextLine text="Upgrade to execute more image searches" type="caption" />
                    <br/>
                    <TextLine
                        text={
                            <>
                                <Icon name="Check" sizebyScale="small" />
                                &nbsp;Limited API access (500 image searches) to our global product catalog
                            </>
                        }
                        type="small"
                    />
                    <TextLine text="Upgrade to execute more global image searches" type="caption" />
                    <ButtonWrapper>
                        <a href="#contact">
                            <Button text="Contact Us" />
                        </a>
                    </ButtonWrapper>
                </PricingDetailsContainer>
            </div>
            <div>
                <PricingDetailsContainer>
                    <TextLine text="Standard" type="heading4" color="#9B51E0" />
                    <TextLine text="$99.99/mo" type="heading4" bold={true} />
                    <TextLine
                        text={<><Icon name="Check" sizebyScale="small" /> Import 50,000 products</>}
                        type="small"
                        oneLiner={true}
                    />
                    <TextLine text="Import extra products at $2.00/1000 products" type="caption" />
                    <br/>
                    <TextLine
                        text={<><Icon name="Check" sizebyScale="small" /> Create up to 10 product catalogs</>}
                        type="small"
                        oneLiner={true}
                    />
                    <br/>
                    <TextLine
                        text={<><Icon name="Check" sizebyScale="small" /> Execute 2,000 image searches monthly</>}
                        type="small"
                        oneLiner={true}
                    />
                    <TextLine text="Execute extra image searches at $15.00/1000 searches" type="caption" />
                    <br/>
                    <TextLine
                        text={
                            <>
                                <Icon name="Check" sizebyScale="small" />
                                &nbsp;Execute 1,000 image searches through our global product catalog
                            </>
                        }
                        type="small"
                    />
                    <TextLine text="Execute global image searches at $20.00/1000 searches" type="caption" />
                    <ButtonWrapper>
                        <a href="#contact">
                            <Button text="Contact Us" />
                        </a>
                    </ButtonWrapper>
                </PricingDetailsContainer>
            </div>
        </>
    )

    return (
        <Wrapper id="pricing">
            <HeaderText
                type={ctx.isDesktop ? 'heading3' : 'heading4'}
                text="Pricing"
            />
            <br />
            <br />
            <PricingGroup>{pricingBlock}</PricingGroup>
        </Wrapper>
    )
}

export default Pricing
