import React, { useContext, useState, ReactElement } from 'react'

export interface Props {
    children: ReactElement
    onSwipeDown: () => any
}

const Swipable: React.FC<Props> = (props) => {
    const [startX, setStartX] = useState<number | null>(null)
    const [startY, setStartY] = useState<number | null>(null)

    const onTouchStart = (e: React.TouchEvent) => {
        setStartX(e.touches[0].clientX)
        setStartY(e.touches[0].clientY)
    }

    const onTouchEnd = (e: React.TouchEvent) => {
        setStartX(null)
        setStartY(null)
    }

    const onTouchMove = (e: React.TouchEvent) => {
        if (startY == null || startX == null) {
            return
        }

        const diffY = e.touches[0].clientY - startY
        const diffX = e.touches[0].clientX - startX
        // reset start values before proceeding so that this case is only handled once
        setStartX(null)
        setStartY(null)
        const isVerticalSwipe = Math.abs(diffY) > Math.abs(diffX)
        if (isVerticalSwipe && diffY > 0) {
            props.onSwipeDown()
        }
        // @todo handle other cases like swipeUp, swipeLeft when the need arises
    }

    return (
        <div
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
        >
            {props.children}
        </div>
    )
}

export default Swipable
