import React from 'react'
import styled from 'styled-components'
import theme from '../../theming'

export interface Props {
    className?: string
}

const ScreenContainer: React.FC<Props> = (props) => {
    return <div className={props.className}>{props.children}</div>
}

const StyledScreenContainer = styled(ScreenContainer)((props) => {
    return {
        minHeight: theme.minBodyHeight,
        maxWidth: theme.maxBodyWidth,
        margin: '0 auto'
    }
})
export default StyledScreenContainer
