import React, { useContext } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import TextLine from '../TextLine'
import Button from '../Button'
import AppContext from '../../helpers/AppContext'

export interface Props {
    className?: string
}

const CurrencySelector: React.FC<Props> = (props) => {
    const ctx = useContext(AppContext)

    const currencies: UserCurrency[] = [
        'EUR',
        'USD',
        'GBP',
        'AUD',
        'CAD',
        'INR',
        'CHF',
        'NGN',
    ]
    const currenciesButtons = currencies.map((currency, i) => {
        return (
            <Button
                key={i}
                icon={ctx.userCurrency === currency ? 'Check' : ''}
                textType="small"
                inline={true}
                text={currency}
                transparent={true}
                color="black"
                onClick={() => ctx.setUserCurrency(currency)}
                className="buttonCurrency"
            />
        )
    })

    return (
        <div className={props.className}>
            <TextLine
                text="Select a Currency"
                type="subtitle"
                className="currencyText"
                color="black"
            />
            <div className="currencyDivider" />
            <div className="currencies">{currenciesButtons}</div>
        </div>
    )
}

const StyledCurrencySelector = styled(CurrencySelector)((props) => {
    return {
        ['.currencies button']: {
            display: 'flex',
            padding: `0 ${theme.space.medium}`,
            margin: theme.space.smaller,
            border: 'none',
            opacity: 0.6,
            fontWeight: 'normal',
            ['.icon']: {
                marginLeft: '-25px',
                marginRight: '10px'
            },
            ['&:hover']: {
                backgroundColor: 'transparent'
            }
        },
        ['.currencyText']: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 'normal',
            display: 'flex',
            whiteSpace: 'nowrap'
        },
        ['.buttonCurrency']: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 'normal',
            padding: 0,
            display: 'grid'
        },
        ['.currencyDivider']: {
            borderTop: `1px solid ${theme.color.black}`,
            borderBottom: 'none',
            opacity: 50
        }
    }
})
export default StyledCurrencySelector
