import axios from 'axios'
import {
    EUROZONE,
    CC_TO_CURR
} from '../constants'

export const DEFAULT_CURRENCY = 'USD'
export const BASE_CURRENCY = 'EUR'

export function getUserCountry(): Promise<string | null> {
    return new Promise((resolve, reject) => {
        axios
            .get('/country')
            .then((res) => {
                resolve(res.data || null)
            })
            .catch((err) => {
                console.log(err)
                reject(err)
            })
    })
}

export function convertAndFormat(
    price: string,
    currency: UserCurrency,
    ctx: AppContextInterface
): string {
    const parsed = parsePrice(price)
    let formatted = `${parsed.currency} ${parsed.amount}`
    const currencyISO = currency
    if (currencyISO && ctx.currencyRates) {
        let rateForBaseCurrency = ctx.currencyRates.rates[currencyISO]
        if (currencyISO === ctx.currencyRates.base) {
            rateForBaseCurrency = 1
        }

        let factor = 1
        if (ctx.userCurrency !== ctx.currencyRates.base) {
            // @todo: investicate why this could possibly be undefined if i dont add '|| factor'
            factor = ctx.currencyRates.rates[ctx.userCurrency] || factor
        }

        const converted = (parsed.amount * factor) / rateForBaseCurrency
        formatted = `${isoToSymbol(ctx.userCurrency)} ${converted.toFixed(2)}`
    }

    return formatted
}

export function isoToSymbol(currency: UserCurrency) {
    return (0)
        .toLocaleString('en-GB', {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
        .replace(/\d/g, '')
        .trim()
}

export function symbolToISO(symbol: string): string {
    const toISO = {
        ['$']: 'USD',
        ['USD']: 'USD',
        ['£']: 'GBP',
        ['€']: 'EUR',
        ['Kr']: 'SEK'
    }

    return toISO[symbol]
}

export function countryToCurrency(countryCode: string): UserCurrency {
    if (EUROZONE.includes(countryCode)) {
        return 'EUR'
    }
    if (CC_TO_CURR[countryCode]) {
        return CC_TO_CURR[countryCode]
    }
    return 'USD'
}

export function priceToNumber(price: string): number {
    return parsePrice(price).amount
}

export function parsePrice(
    price: string
): { currency: string; amount: number } {
    // remove _, commas, and whitespace
    price = price.replace(/[\s|,|_]/g, '')
    const currencyMatch: RegExpMatchArray | null = price.match(/^([^\d]+)/)
    const providedCurrency = currencyMatch ? currencyMatch[0] : '$'
    const currency = providedCurrency.replace('US$', '$').replace('$£', '£')
    const amount = parseFloat(price.replace(providedCurrency, ''))
    return { currency, amount }
}
