import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import TextLine from '../../components/TextLine'
import YouMayAlsoLike from '../../components/YouMayAlsoLike'
import ProductListWithControls from '../../components/ProductListWithControls'
import Feedback from '../../components/Feedback'
import AppContext from '../../helpers/AppContext'
import * as textSearchHelper from './helpers'
import { useTranslate } from '../../helpers/useTranslate'
import { textSearch } from '../../helpers/apiClient'

const TextSearch = (props: RouteComponentProps) => {
    const ctx = useContext(AppContext)
    const { translate } = useTranslate()
    const [emptyMessage, setEmptyMessage] = useState(
        'Your search result will show up here'
    )
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState<Product[]>([])
    const [shouldPresetFilter, setShouldPresetFilter] = useState(true)
    const [loadFromCtx, setLoadFromCtx] = useState(true)

    const handleSearch = (searchQuery: string) => {
        setLoading(true)
        textSearch(searchQuery, ctx.userCountry)
            .then((data) => setSearchResult(data))
            .catch((err) => {
                ctx.setToastMessage({
                    text: 'Search for similar items failed :(',
                    type: 'error',
                    timeout: 7000
                })
            })

        ReactGA.event({
            category: 'User',
            action: `Attempted text search`
        })
    }

    const searchQuery = new URLSearchParams(props.location.search).get('query')
    useEffect(() => {
        if (searchQuery) {
            handleSearch(searchQuery)
        }
    }, [searchQuery])

    const setSearchResult = (res: TextSearchResponse) => {
        setLoading(false)
        if (textSearchHelper.hasGroupedResult(res) && res.grouped_results) {
            setProducts([
                ...res.grouped_results.female,
                ...res.grouped_results.male,
                ...res.grouped_results.unisex,
            ])
            setShouldPresetFilter(true)
        } else {
            setProducts(res.result)
        }
        setEmptyMessage(
            textSearchHelper.isEmpty(res) ? 'Search returned no result' : ''
        )
    }

    // we are loading results that may pre-exist in context
    // this is needed for Server Side Rendering which helps for SEO gains
    if (ctx.textSearchResult && loadFromCtx) {
        setSearchResult(ctx.textSearchResult)
        setLoadFromCtx(false)
    }

    const loader = loading ? <TextLine type="body" text="loading..." /> : null

    const body = products.length ? (
        <div style={{ opacity: loading ? 0.5 : 1 }}>
            <ProductListWithControls
                products={products}
                title={translate('TEXT_SEARCH_RESULT_HEADER')}
                emptyListMessage={emptyMessage}
                presetFilter={shouldPresetFilter ? {gender: new Set(['female'])} : undefined}
            />
        </div>
    ) : null
    
    return (
        <div>
            {loader}
            {body}
            <YouMayAlsoLike />
            <Feedback />
        </div>
    )
}

export default withRouter(TextSearch)
