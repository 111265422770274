import {
    productLabelsToObject
} from './utils'
import { parsePrice, symbolToISO } from './currency'
import { BUCKET_URL } from '../constants'

export function saveRecentSearch(searchResponse: SearchResultData) {
    const imgURL = searchResponse.public_url
    const ts = Date.now()
    const recentSearch: RecentSearch = {
        query_image_url: imgURL,
        timestamp: ts,
        data: searchResponse
    }
    localStorage.setItem('search-results', JSON.stringify([recentSearch]))
    return Promise.resolve()
}

export function getSearchResult(imgURL: string): SearchResultData | null {
    const dump = localStorage.getItem('search-results')
    if (dump) {
        const data: RecentSearch = JSON.parse(dump)[0]
        const recentSearch: RecentSearch = loadRecentSearchFromLegacy(data)[0]
        return recentSearch && recentSearch.data ? recentSearch.data : null
    }

    return null
}

export function saveSearchEsclation(id: string) {
    const dump = localStorage.getItem('search-escalations')
    const escalations: string[] = dump ? JSON.parse(dump) : []
    if (escalations.length >= 5) {
        escalations.shift()
    }
    escalations.push(id)
    localStorage.setItem('search-escalations', JSON.stringify(escalations))
}

export function getSearchEsclations(): string[] {
    const dump = localStorage.getItem('search-escalations')
    if (dump) {
        const data = JSON.parse(dump)
        return data
    }
    return []
}

export function getRecentSearches(): Promise<RecentSearch[]> {
    const dump = localStorage.getItem('search-results')
    if (dump) {
        const data: RecentSearch = JSON.parse(dump)[0]
        return Promise.resolve(loadRecentSearchFromLegacy(data))
    }

    return Promise.resolve([])
}

export async function saveLikedProduct(product: Product) {
    return Promise.resolve()
}

export function unsaveLikedProduct(product: Product) {
    return Promise.resolve()
}

export async function getLikedProducts(): Promise<Product[]> {
    return Promise.resolve([])
}

export function saveRecentProduct(product: Product) {
    const leanProduct: Product = {
        id: product.id,
        images: product.images,
        url: product.url,
        name: product.name,
        vendor: product.vendor,
        matching_image: product.matching_image,
        vendor_images: product.vendor_images,
        price: product.price,
        currency: product.currency,
        is_affiliate: product.is_affiliate
    }
    _saveProduct('recent-products', leanProduct)
    return Promise.resolve()
}

export function getRecentProducts() {
    return Promise.resolve(_getProducts('recent-products'))
}

function _getProducts(key: string): Product[] {
    const dump = localStorage.getItem(key)
    if (dump) {
        return loadProductsFromLegacies(JSON.parse(dump))
    }

    return []
}

function _saveProduct(key: string, product: Product) {
    const dump = localStorage.getItem(key)
    const products: Product[] = dump ? JSON.parse(dump) : []
    // because we want to save only 10 products
    // once the product is up to 10, we shift the array
    // and delete the first element
    if (products.length >= 10) {
        products.shift()
    }

    products.push(product)
    localStorage.setItem(key, JSON.stringify(products))
}

function loadRecentSearchFromLegacy(search: RecentSearch): RecentSearch[] {
    if (search.data) {
        search.data.results = search.data.results.map((result) => {
            result.similar_products = loadProductsFromLegacies(
                result.similar_products
            )
            return result
        })
    }

    return [search]
}

function loadProductFromLegacy(product: LegacyProduct | Product): Product {
    if (product['product_labels']) {
        const legacy = product as LegacyProduct
        const labels = productLabelsToObject(legacy)
        const vendorImages: string[] = []
        if (labels['source_image']) {
            const domain = labels['public_image_url']
                .replace(BUCKET_URL, '')
                .split('/')[0]
            vendorImages.push(`https://${domain}/${labels['source_image']}`)
        }

        const loadedProduct: Product = {
            name: legacy.product_display_name,
            id: legacy.product_id,
            tabId: legacy.tabId,
            score: legacy.score,
            image: legacy.image,
            vendor: labels['vendor'],
            price: labels['price'],
            reduced_price: labels['reduced_price'],
            brand_name: labels['brand_name'],
            images: [ labels['public_image_url'] ],
            vendor_images: vendorImages,
            matching_image: labels['public_image_url'],
            url: labels['product_url'],
            is_affiliate:
                (labels['product_url'] || '').indexOf('www.awin1.com') > -1,
            currency: symbolToISO(
                parsePrice(labels['price'] || '').currency
            ) as UserCurrency
        }

        return loadedProduct
    } else {
        return product as Product
    }
}

function loadProductsFromLegacies(data: object[]): Product[] {
    const maybeLegacies = data as Array<LegacyProduct | Product>
    return maybeLegacies.map(loadProductFromLegacy)
}
