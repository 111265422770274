import React, { useContext, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import theme, { Layer } from '../../theming'
import { LOADER_IMAGES } from '../../constants'
import AppContext from '../../helpers/AppContext'
import * as loadingHelper from './helper'

export interface Props {
    layer?: Layer
    className?: string
    // @todo: Get image urls from a prop
}

const Loading: React.FC<Props> = (props) => {
    const { className } = props
    const { loader } = useContext(AppContext)
    const [current, setCurrent] = useState(0)

    const textList = loadingHelper.getTextList(loader)

    useEffect(() => {
       loadingHelper. changeTextIdAtIntervals(setCurrent, textList, current)
    }, [current, textList, setCurrent])

    if (!loader.show) {
        return null
    }

    const text = loadingHelper.getText(textList, current)

    return (
        <div className={className}>
            {text}
            <div className="ring">
                <div className="backgroundImg" id="back1" />
                <div className="backgroundImg" id="back2" />
                <div className="backgroundImg" id="back3" />
                <div className="backgroundImg" id="back4" />
                <div className="backgroundImg" id="back5" />
            </div>
        </div>
    )
}

const StyledLoading = styled(Loading)`
    background-color: ${theme.color.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: ${(props) => theme.layer[props.layer || 'appShell']};

    .ring {
        border: ${theme.size.smallest} solid ${theme.color.black};
        border-radius: 50%;
        height: 80px;
        width: 80px;
        margin-top: ${theme.size.large};
    }

    #back1 {
        background: url('${LOADER_IMAGES[0]}')
            no-repeat center;
        background-position: center;
        background-size: cover;
        animation-delay: 8s;
    }

    #back2 {
        background: url('${LOADER_IMAGES[1]}')
            no-repeat center;
        background-position: center;
        background-size: cover;
        animation-delay: 6s;
    }

    #back3 {
        background: url('${LOADER_IMAGES[2]}')
            no-repeat center;
        background-position: center;
        background-size: cover;
        animation-delay: 4s;
    }

    #back4 {
        background: url('${LOADER_IMAGES[3]}')
            no-repeat center;
        background-position: center;
        background-size: cover;
        animation-delay: 2s;
    }

    #back5 {
        background: url('${LOADER_IMAGES[4]}')
            no-repeat center;
        background-position: center;
        background-size: cover;
        animation-delay: 0s;
    }

    @keyframes backgroundchangeFadeInOut {
        0% {
            opacity: 1;
        }
        17% {
            opacity: 1;
        }
        25% {
            opacity: 0;
        }
        92% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .backgroundImg {
        animation-name: backgroundchangeFadeInOut;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 8s;
        border-radius: 50%;
        height: 80px;
        margin: 0 auto;
        position: absolute;
        width: 80px;
    }
`
export default StyledLoading
