import React, { useEffect, useState, useContext, useCallback } from 'react'
import styled, { CSSObject } from 'styled-components'
import theme from '../../theming'
import Button from '../Button'
import * as helper from './sortFilterHelper'
import Accordion from '../Accordion'
import AppContext from '../../helpers/AppContext'
import useEffectPostRender from '../../helpers/useEffectPostRender'
import Dropdown from '../Dropdown'
import TextLine from '../TextLine'
import { Props as ButtonProps } from '../Button/Button'
import CheckboxSortAndFilter from './CheckboxSortAndFilter'

export interface Props {
    className?: string
    products: Product[]
    sortedBy: Sorter
    filteredBy: Filter
    onUpdate: (
        products: Product[],
        newSortAndFilter: SortAndFilterStateData
    ) => any

    // @Todo: good follow up will be to have SortAndFilter rendered asAccordion or asDropdown
    // with options to specifiy if sort only or filter only.
    // or consider splitting sort and filter into separate components
    renderSortOnly?: boolean
    renderFilterOnly?: boolean
}

type Sorters = {
    [s in sortType]: (p: Product[], ctx: AppContextInterface) => Product[]
}

const SortAndFilter: React.FC<Props> = (props) => {
    const [selectedSort, setSelectedSort] = useState<sortType | null>(
        props.sortedBy
    )
    const [selectedBrandNameFilters, setSelectedBrandNameFilters] = useState<
        Set<string>
    >(props.filteredBy.brandName)
    const [selectedVendorFilters, setSelectedVendorFilters] = useState<
        Set<string>
    >(props.filteredBy.vendor)
    const [selectedCountryFilters, setSelectedCountryFilters] = useState<
        Set<string>
    >(props.filteredBy.country)

    const [selectedGenderFilters, setSelectedGenderFilters] = useState<
        Set<Gender>
    >(props.filteredBy.gender)

    const [selectedColorFilters, setSelectedColorFilters] = useState<
        Set<string>
    >(props.filteredBy.color)

    const ctx = useContext(AppContext)

    const toggleFilteredGender = (gender: Gender) => {
        setSelectedGenderFilters((prev) => {
            const newVal = new Set(prev)
            prev.has(gender) ? newVal.delete(gender) : newVal.add(gender)
            return newVal
        })
    }

    const doSortAndFilter = () => {
        const sorters: Sorters = {
            price: helper.sortByPrice,
            priceReverse: helper.sortByPriceReverse,
            vendor: helper.sortByVendor,
            vendorReverse: helper.sortByVendorReverse,
            similarity: helper.sortBySimilarity
        }

        let filtered = helper.filterByProductAttribute(
            props.products,
            selectedVendorFilters,
            'vendor'
        )

        filtered = helper.filterByProductAttribute(
            filtered,
            selectedGenderFilters,
            'gender'
        )

        filtered = helper.filterByProductAttribute(
            filtered,
            selectedCountryFilters,
            'country'
        )

        filtered = helper.filterByProductAttribute(
            filtered,
            selectedBrandNameFilters,
            'brand_name'
        )

        filtered = helper.filterByProductAttribute(
            filtered,
            selectedColorFilters,
            'colors'
        )

        const sortedAndFiltered = selectedSort
            ? sorters[selectedSort](filtered, ctx)
            : filtered
        props.onUpdate(sortedAndFiltered, {
            sortedBy: selectedSort,
            allFilteredBy: {
                vendor: selectedVendorFilters,
                gender: selectedGenderFilters,
                country: selectedCountryFilters,
                color: selectedColorFilters,
                brandName: selectedBrandNameFilters
            }
        })
    }

    useEffect(doSortAndFilter, [
        selectedSort,
        selectedVendorFilters,
        selectedGenderFilters,
        selectedCountryFilters,
        selectedColorFilters,
        selectedBrandNameFilters
    ])

    useEffect(() => {
        setSelectedSort(props.sortedBy)
    }, [props.sortedBy])

    useEffectPostRender(() => {
        setSelectedSort(null)
        setSelectedVendorFilters(new Set())
        setSelectedGenderFilters(new Set())
        setSelectedCountryFilters(new Set())
        setSelectedColorFilters(new Set())
        setSelectedBrandNameFilters(new Set())
    }, [props.products])

    const sorts = helper.SORTS_META.map((sortMeta, i) => {
        let sortButtonProps: ButtonProps = { transparent: selectedSort !== sortMeta.func, inline: true }
        if (props.renderSortOnly) {
            sortButtonProps = {
                noBorder: true,
                transparent: true,
                className: `dropdown-selector ${
                    selectedSort === sortMeta.func ? '--selected' : ''
                }`,
                icon: 'Check'
            }
        }

        return (
            <Button
                textType="small"
                key={i}
                text={sortMeta.text}
                padding={theme.space.smaller}
                color="black"
                onClick={() => setSelectedSort(sortMeta.func)}
                {...sortButtonProps}
            />
        )
    })

    const vendorFilters = helper
        .generateVendorFilters(props.products)
        .map((filter, i) => {
            const onVendorClick = () =>
                setSelectedVendorFilters((prev) =>
                    helper.filterTags(prev, filter)
                )
            return (
                <Button
                    textType="small"
                    key={i}
                    inline={true}
                    text={filter}
                    padding={theme.space.smaller}
                    transparent={ctx.isDesktop || !selectedVendorFilters.has(filter)}
                    icon={ctx.isDesktop && selectedVendorFilters.has(filter) ? 'Check' : ''}
                    color={ctx.isDesktop ? 'gray' : 'black'}
                    onClick={onVendorClick}
                    className="filterButtons"
                />
            )
        })

    const brandNameFilters = helper
        .generateBrandNameFilters(props.products)
        .map((filter, i) => {
            const onBrandClick = () =>
                setSelectedBrandNameFilters((prev) =>
                    helper.filterTags(prev, filter)
                )
            return (
                <Button
                    textType="small"
                    key={i}
                    inline={true}
                    text={filter}
                    padding={theme.space.smaller}
                    transparent={ctx.isDesktop || !selectedBrandNameFilters.has(filter)}
                    icon={ctx.isDesktop && selectedBrandNameFilters.has(filter) ? 'Check' : ''}
                    color={ctx.isDesktop ? 'gray' : 'black'}
                    onClick={onBrandClick}
                    className="filterButtons"
                />
            )
        })

    const brandNameFiltersEI = brandNameFilters ? (
        <Accordion text="Filter by Brand name">
            <div className="sorts-filters">{brandNameFilters}</div>
        </Accordion>
    ) : null

    const genderFilters = ['male', 'female', 'unisex'].map((filter, i) => {
        const gender = filter as Gender
        return (
            <Button
                textType="small"
                key={i}
                inline={true}
                text={filter}
                padding={theme.space.smaller}
                transparent={ctx.isDesktop || !selectedGenderFilters.has(gender)}
                icon={ctx.isDesktop && selectedGenderFilters.has(gender) ? 'Check' : ''}
                color={ctx.isDesktop ? 'gray' : 'black'}
                onClick={() => toggleFilteredGender(gender)}
                className="filterButtons"
            />
        )
    })

    const countryFilters = helper
        .generateCountryFilters(props.products)
        .map((filter, i) => {
            const onCountryClick = () =>
                setSelectedCountryFilters((prev) =>
                    helper.filterTags(prev, filter)
                )
            return (
                <Button
                    textType="small"
                    key={i}
                    inline={true}
                    text={filter}
                    padding={theme.space.smaller}
                    transparent={ctx.isDesktop || !selectedCountryFilters.has(filter)}
                    icon={ctx.isDesktop && selectedCountryFilters.has(filter) ? 'Check' : ''}
                    color={ctx.isDesktop ? 'gray' : 'black'}
                    onClick={onCountryClick}
                    className="filterButtons"
                />
            )
        })

    const countryFiltersEl = countryFilters.length ? (
        <Accordion text="Filter by Seller location">
            <div className="sorts-filters">{countryFilters}</div>
        </Accordion>
    ) : null

    const colorFilters = helper
        .generateColorFilters(props.products)
        .map((filter, i) => {
            const onColorClick = () =>
                setSelectedColorFilters((prev) =>
                    helper.filterTags(prev, filter)
                )

            return (
                <Button
                    textType="small"
                    key={i}
                    inline={true}
                    text={filter}
                    padding={theme.space.smaller}
                    transparent={ctx.isDesktop || !selectedColorFilters.has(filter)}
                    icon={ctx.isDesktop && selectedColorFilters.has(filter) ? 'Check' : ''}
                    color={ctx.isDesktop ? 'gray' : 'black'}
                    onClick={onColorClick}
                    className="filterButtons"
                />
            )
        })

    const colorFiltersEl = colorFilters.length ? (
        <Accordion text="Filter by Color">
            <div className="sorts-filters">{colorFilters}</div>
        </Accordion>
    ) : null

    let content

    if (props.renderSortOnly) {
        const selectedSortMeta = helper.SORTS_META.find(
            (sm) => sm.func === selectedSort
        )
        content = (
            <>
                <TextLine text={'Sort:'} />
                <Dropdown
                    displayText={
                        selectedSortMeta ? selectedSortMeta.text : '---'
                    }
                    withButtonBorder={true}
                >
                    <div className="sort-dropdown-content">{sorts}</div>
                </Dropdown>
            </>
        )
    } else if (ctx.isDesktop) {
        content = (
            <CheckboxSortAndFilter
                genderFilters={genderFilters}
                countryFilters={countryFilters}
                colorFilters={colorFilters}
                vendorFilters={vendorFilters}
                brandNameFilters={brandNameFilters}
            />
        )
    } else {
        content = (
            <>
                {!props.renderFilterOnly && (
                    <Accordion text="Sort by">
                        <div className="sorts-filters">{sorts}</div>
                    </Accordion>
                )}
                <Accordion text="Filter by Gender">
                    <div className="sorts-filters">{genderFilters}</div>
                </Accordion>
                {countryFiltersEl}
                {colorFiltersEl}
                <Accordion text="Filter by Seller">
                    <div className="sorts-filters">{vendorFilters}</div>
                </Accordion>
                {brandNameFiltersEI}
            </>
        )
    }

    return <div className={props.className}>{content}</div>
}

const StyledSortAndFilter = styled(SortAndFilter)((props) => {
    const rootStyle = (): CSSObject => {
        if (props.renderSortOnly) {
            return {
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: 'auto auto',
                columnGap: theme.space.smaller
            }
        } else {
            return {
                height: '60vh',
                [`${theme.media.largeScreenBreakpoint}`]: {
                    height: 'unset',
                },
                overflowY: 'auto'
            }
        }
    }
    return {
        ...rootStyle(),
        ['.sorts-filters button']: {
            margin: `${theme.space.medium} ${theme.space.medium} ${theme.space.medium}  0`,
            [`${theme.media.largeScreenBreakpoint}`]: {
                margin: `${theme.space.medium} ${theme.space.medium} ${theme.space.medium}  -160px`,
                display: 'grid',
                border: 'none',
            }
        },
        ['.sort-dropdown-content']: {
            minWidth: '200px'
        },
        ['.dropdown-selector']: {
            width: '100%',
            justifyContent: 'flex-start',
            ['svg']: { visibility: 'hidden' }
        },
        ['.--selected']: {
            ['svg']: { visibility: 'visible' }
        }
    }
})
export default StyledSortAndFilter
