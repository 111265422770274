import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import AppContext from '../../helpers/AppContext'
import useSortAndFilterState from '../../helpers/SortAndFilterState'
import { SortAndFilterButton } from '../../components/Button'
import Frame from '../../components/Frame'
import ProductList from '../../components/ProductList'
import Section, { SectionControl } from '../../components/Section/Section'
import TextLine from '../../components/TextLine'
import EmptyListMessage from '../../components/EmptyListMessage'
import SearchList from '../../components/SearchList'
import FloatingToolbar from '../../components/FloatingToolbar'
import SortAndFilter from '../../components/SortAndFilter'
import Feedback from '../../components/Feedback'
import ProductListWrapper from '../../components/ProductListWrapper'
import theme from '../../theming'
import YouMayAlsoLike from '../../components/YouMayAlsoLike'
import { useTranslate } from '../../helpers/useTranslate'

export interface Props {
    className?: string
    primary?: boolean
    searches: RecentSearch[]
}

const Recent: React.FC<Props> = (props) => {
    const { className } = props
    const [isLoading, setIsLoading] = useState(true)
    const [products, setProducts] = useState<Product[]>([])
    const [sortedProducts, setSortedProducts] = useState<Product[]>([])
    const [sort, setSort, filter, setFilter] = useSortAndFilterState()
    const [searches, setSearches] = useState<RecentSearch[]>([])
    const ctx = useContext(AppContext)
    const { openDrawer, setDrawerCta, isDesktop } = ctx
    const { translate } = useTranslate()

    useEffect(() => {
        Promise.all([
            ctx.storage.getRecentProducts(ctx),
            ctx.storage.getRecentSearches(ctx)
        ])
            .then(([recentProducts, recentSearches]) => {
                const lastProdFirst = recentProducts.slice().reverse()
                const lastSearchFirst = recentSearches.slice().reverse()
                setSearches(lastSearchFirst)
                setProducts(lastProdFirst)
                setSortedProducts(lastProdFirst)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
            })
    }, [setSearches, setProducts, ctx.storage])

    const handleSortAndFilterOutput = (
        newList: Product[],
        output: SortAndFilterStateData
    ) => {
        setSortedProducts(newList)
        setSort(output.sortedBy)
        setFilter(output.allFilteredBy)
    }

    const sortAndFilter = (
        <SortAndFilter
            products={products}
            sortedBy={sort}
            filteredBy={filter}
            onUpdate={handleSortAndFilterOutput}
        />
    )

    const sectionControls: SectionControl[] = []

    if (!isDesktop) {
        sectionControls.push({
            content: <SortAndFilterButton />,
            onClick: () => {
                setDrawerCta('Done')
                openDrawer(sortAndFilter)
            }
        })
    } else {
        sectionControls.push({
            content: (<SortAndFilter
                products={products}
                sortedBy={sort}
                filteredBy={filter}
                renderSortOnly={true}
                onUpdate={handleSortAndFilterOutput}
            />),
            onClick: () => {}
        })
    }

    const getContent = () => {
        if (isLoading) {
            return (
                <Frame>
                    <TextLine type="body" text="loading..." />
                </Frame>
            )
        }

        if (!products.length && !searches.length) {
            return <EmptyListMessage message="You have no recent searches" />
        }

        const aside = (
            <div className="aside-content">
                <SearchList searches={searches} withBottomToggle={true} />
                {products.length ? sortAndFilter : null}
            </div>
        )

        const productList = (
            <ProductList products={sortedProducts} noSimilarityBadge={true} />
        )
        const main = (
            <div>
                <div style={{ paddingLeft: theme.space.medium }}>
                    <TextLine
                        text={'Recently viewed products'}
                        type="subtitle"
                        bold={true}
                    />
                </div>
                <Section
                    title={translate('GENERIC_PRODUCTS_TITLE')}
                    body={productList}
                    controls={sectionControls}
                />
            </div>
        )
        const productsSection = (
            <ProductListWrapper
                main={products.length ? main : <></>}
                aside={aside}
            />
        )
        const toolbarItems = [
            {
                icon: 'Sliders',
                onClick: () => {
                    setDrawerCta('Done')
                    openDrawer(sortAndFilter)
                }
            }
        ]
        const toolbar = (
            <FloatingToolbar tools={isDesktop ? undefined : toolbarItems} />
        )

        const mobileRecentSections = !isDesktop ? (
            <div style={{ paddingTop: theme.space.medium }}>
                <SearchList searches={searches} />
            </div>
        ) : null

        return (
            <>
                {mobileRecentSections}
                {productsSection}
                {products.length ? toolbar : null}
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>Recent &bull; LykDat &bull; Fashion Image Search</title>
            </Helmet>
            <div className={className}>{getContent()}</div>
            <YouMayAlsoLike />
            <Feedback />
        </>
    )
}

const StyledRecent = styled(Recent)((props) => {
    return {
        '.aside-content': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }
    }
})

export default StyledRecent
