import React, { useEffect, useMemo, useState } from 'react'
import { useTabGroupContext } from './TabGroup'

const randomString = () =>
    Math.random()
        .toString(36)
        .substring(2, 15)

const useTabContext = (arg: { title: string; data?: unknown }) => {
    const {
        addTab,
        removeTab,
        setActive,
        isActive,
        tabs
    } = useTabGroupContext()
    const [id] = useState(randomString())
    useEffect(() => {
        // Make the first tab active by default
        if (tabs.size === 0) {
            setActive(id)
        }
        addTab({ id, ...arg })
        return () => {
            removeTab(id)
        }
    }, [])
    return useMemo(() => ({ id, setActive, isActive }), [id, isActive])
}
const Tab = ({
    render,
    title,
    data
}: {
    render: (opts?: {id: string; activeTab: string}) => JSX.Element
    title: string
    data?: unknown
}) => {
    const { id, setActive, isActive } = useTabContext({ title, data })
    return id === isActive ? render({ id, activeTab: isActive }) : null
}

export default Tab
