import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import AppContext from '../../helpers/AppContext'
import Section from '../Section'
import ProductList from '../ProductList'
import ReactGA from 'react-ga'
import { useTranslate } from '../../helpers/useTranslate'
import { useLocation } from 'react-router-dom'
import { getTrendingProducts } from '../../helpers/apiClient'

export interface Props {
    className?: string
    shopByGender?: string
}

function randNumber(min: number = 1, max: number = 10) {
    return Math.floor(Math.random() * (max - min)) + min + 1
}

const TrendingBlock: React.FC<Props> = (props) => {
    const { className, shopByGender } = props
    const [products, setProducts] = useState<Product[]>([])
    const ctx = useContext(AppContext)
    const { translate } = useTranslate()

    useEffect(() => {
        if (ctx.trendingProducts.length) {
            setProducts(ctx.trendingProducts)
        }
        if (shopByGender) {
            const offset = randNumber(1, 500)
            getTrendingProducts(shopByGender, offset, 20).then(
                (products: Product[]) => {
                    setProducts(products)
                }
            )
        }
    }, [ctx, shopByGender])
    const trackTrendingClick = () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked trending product'
        })
    }
    const productsWithoutScore = products.map((p) => {
        return { ...p, score: 0 }
    })
    const productList = (
        <ProductList
            products={productsWithoutScore}
            maxLength={8}
            disableTracking={true}
            onProductClick={trackTrendingClick}
        />
    )
    const section = products.length ? (
        <Section title={translate('TRENDING_TITLE')} body={productList} />
    ) : null
    return <div className={className}>{section}</div>
}

const StyledTrendingBlock = styled(TrendingBlock)((props) => {
    return {
        minHeight: '350px'
    }
})
export default StyledTrendingBlock
