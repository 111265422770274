import { priceToNumber, convertAndFormat } from '../../helpers/currency'

export const SORTS_META: Array<{ func: sortType; text: string }> = [
    { func: 'price', text: 'lowest price' },
    { func: 'priceReverse', text: 'highest price' },
    { func: 'vendor', text: 'seller name (A - Z)' },
    { func: 'vendorReverse', text: 'seller name (Z - A)' },
    { func: 'similarity', text: 'similarity' }
]

export const sortByVendor = (products: Product[]): Product[] => {
    return products.slice().sort((a, b) => {
        return a.vendor.localeCompare(b.vendor)
    })
}

export const sortByVendorReverse = (products: Product[]): Product[] => {
    return sortByVendor(products).reverse()
}

const selectSortPrice = (
    price: number,
    reducedPrice: number | null
): number => {
    const finalPrice =
        reducedPrice && reducedPrice > 0.0 && reducedPrice < price
            ? reducedPrice
            : price
    return finalPrice
}

export const sortByPrice = (
    products: Product[],
    ctx: AppContextInterface
): Product[] => {
    return products.slice().sort((a, b) => {
        const priceA = priceToNumber(convertAndFormat(a.price, a.currency, ctx))
        const reducedPriceA = a.reduced_price
            ? priceToNumber(convertAndFormat(a.reduced_price, a.currency, ctx))
            : null
        const priceB = priceToNumber(convertAndFormat(b.price, b.currency, ctx))
        const reducedPriceB = b.reduced_price
            ? priceToNumber(convertAndFormat(b.reduced_price, b.currency, ctx))
            : null

        const finalPriceA = selectSortPrice(priceA, reducedPriceA)
        const finalPriceB = selectSortPrice(priceB, reducedPriceB)
        return finalPriceA - finalPriceB
    })
}

export const sortBySimilarity = (products: Product[]): Product[] => {
    // descending order of score

    return products.slice().sort((a, b) => a.score || 0 -  (b.score || 0))
}

export const sortByPriceReverse = (
    products: Product[],
    ctx: AppContextInterface
): Product[] => {
    return sortByPrice(products, ctx).reverse()
}

export const generateVendorFilters = (products: Product[]) => {
    const vendors = {}
    products.forEach((product) => {
        vendors[product.vendor] = true
    })

    return Object.keys(vendors).sort()
}

export const generateBrandNameFilters = (products: Product[]) => {
    const brandNames = {}
    products.forEach((product) => {
        if (product.brand_name) {
            brandNames[product.brand_name.toLowerCase()] = true
        }
    })

    return Object.keys(brandNames).sort()
}

export const generateCountryFilters = (products: Product[]) => {
    const countries = {}
    products.forEach((product) => {
        if (product.country) {
            countries[product.country] = true
        }
    })

    return Object.keys(countries).sort()
}

export const generateColorFilters = (products: Product[]) => {
    const colors = new Set<string>()
    products.forEach((product) => {
        if (
            product.colors &&
            product.colors.length &&
            product.language === 'en'
        ) {
            product.colors.forEach((color) => {
                if (color && color.trim().length > 1 && !color.match(/\d+/)) {
                    colors.add(color)
                }
            })
        }
    })

    return Array.from(colors).sort()
}

type ProductAttr = 'vendor' | 'brand_name' | 'colors' | 'country' | 'gender'

export const filterByProductAttribute = (
    products: Product[],
    attributes: Set<string>,
    attr: ProductAttr
) => {
    return products.filter((product) => {
        if (!attributes.size) {
            return true
        }
        const proAttr = product ? product[attr] : null
        if (proAttr && proAttr.length) {
            if (attr === 'colors') {
                // switch to set for O(1) lookup time
                const colorSet = new Set(product.colors)
                return Array.from(attributes).some((i) => colorSet.has(i))
            }
            if (attr === 'brand_name' && !Array.isArray(proAttr)) {
                return attributes.has(proAttr.toLowerCase())
            }

            if (!Array.isArray(proAttr)) {
                return attributes.has(proAttr)
            }
        }

        return false
    })
}

export const filterTags = (
    items: Set<string>,
    val: string | Gender
): Set<string> => {
    const newVal = new Set(items)
    items.has(val) ? newVal.delete(val) : newVal.add(val)
    return newVal
}
