import Image, { Props as ImageProps } from '../Image/Image'
import styled from 'styled-components'
import theme from '../../theming'
import TextLine from '../TextLine'
import { B2B_ASSET_PATH } from '../../constants'
import React from 'react'

interface Partner {
    name: string
    website?: string
    imageConfig: ImageProps
}

const clientList: Partner[] = [
    {
        name: 'MAS',
        imageConfig: {
            srcSet: '/client_logos/MAS.png 1x, /client_logos/MAS@2x.png 2x',
            src: '/client_logos/MAS.png',
            alt: '',
            sizes: '110px'
        }
    },
    {
        name: 'LUXE DRESSING',
        imageConfig: {
            srcSet: '/client_logos/luxe-dressing.png 1x, /client_logos/luxe-dressing@2x.png 2x',
            src: '/client_logos/luxe-dressing.png',
            alt: '',
            sizes: '155px, media(min-width: 1140px) 310px',
            className: 'luxe'
        }
    },
    {
        name: 'CAMPER',
        imageConfig: {
            srcSet: '/client_logos/camper.png 1x, /client_logos/camper@2x.png 2x',
            src: '/client_logos/camper.png',
            alt: '',
            sizes: '87px'
        }
    }
]

const ClientLogoImg = styled(Image)`
      ${theme.media.largeScreenBreakpoint} {
        max-width: 100px;
        &.luxe {
          max-width: 310px; 
        }
      }
`

const Partners: React.FC<{title: string}> = ({title}) => {
    const PartnersSection = styled.section`
      background: black;
      color: white;
      text-align: center;
      padding: 0;
      margin: 0 -12px;
`
    const ClientsWrapper = styled.div`
      width: 50%;
      margin: 10px auto;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-evenly;
      align-items: center;
`
    return <PartnersSection>
        <Wrapper>
            <TextLine text={title} type="heading4" bold={true} />
            <ClientsWrapper>
                {
                    clientList.map(({imageConfig: {src, srcSet, alt, sizes, className}, ...partner}) =>
                        <ClientLogoImg
                            src={B2B_ASSET_PATH + src}
                            className={className || ''}
                            title={partner.name}
                            alt={alt || partner.name}
                            srcSet={srcSet}
                            sizes={sizes}
                            key={partner.name}
                            lazy={false}
                        />
                    )
                }
            </ClientsWrapper>
        </Wrapper>
    </PartnersSection>
}

const Wrapper = styled.div`
    padding: 30px 0;
    // max-width: 1100px;
    margin: 0 auto;
    ${theme.media.only.md`
        padding: 60px 0;
    `}

    ${theme.media.from.lg`
        margin: ${theme.size.larger} auto;
    `}
`

export default Partners
