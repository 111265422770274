import React from 'react'
import styled from 'styled-components'
import theme, { ColorType, Scale } from '../../theming'
import Icon from '../Icon'
import TextLine from '../TextLine'

export interface Props {
    caption: string
    className?: string
    color?: ColorType
    icon: string
    sizeByScale?: Scale
    size?: string
    onClick?: () => any
}

const CaptionedIcon: React.FC<Props> = (props) => {
    const {
        caption,
        className = '',
        color,
        icon,
        sizeByScale = 'medium',
        size,
        onClick = () => {
            // do nothing
        }
    } = props

    return (
        <div className={className} onClick={onClick}>
            <Icon name={icon} size={size} sizebyScale={sizeByScale} color={color} className="icon" />
            <TextLine type="small" color={color} text={caption} />
        </div>
    )
}

const StyledCaptionedIcon = styled(CaptionedIcon)((props) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        maxHeight: '76px',
        maxWidth: '76px',
        ['& > *']: {
            margin: '0 auto'
        },
        ['& .icon']: {
            marginBottom: theme.space.smaller
        }
    }
})
export default StyledCaptionedIcon
