import styled, { StyledComponent } from 'styled-components'
import theme from '../../theming'

type ContentAlignment = 'left' | 'center' | 'right'

export const Container = styled.div`
    position: relative;
    display: flex;
`

export const DropdownBtn = styled.div<{ withButtonBorder?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    text-transform: capitalize;
    ${({ withButtonBorder }) => {
        if (withButtonBorder) {
            return `
            border: 1px solid ${theme.color.grayLighter}
            border-radius: ${theme.size.smallest}
            padding: ${theme.space.smallest} ${theme.space.small}
            min-width: ${theme.size.largest}
            `
        }
        return ``
    }};
`
export const DropdownContent = styled.div<{
    alignment?: ContentAlignment
    isActive?: boolean
}>`
    display: flex;
    align-items: center;
    cursor: pointer;
    visibility: hidden;
    z-index: -1;
    top: ${theme.size.largest};
    padding: ${theme.space.small};
    background: ${theme.color.white}
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.08);
    border-radius: ${theme.size.smaller};
    position: absolute;
    ${({ alignment, isActive }) => {
        if (alignment === 'right') {
            return `
            right: 0`
        } else if (alignment === 'left') {
            return `
            left: 0`
        }
        return `
        left: 50%;
        transform: translateX(-50%)`
    }};
    ${({ isActive }) => {
        if (isActive) {
            return `
            z-index: ${theme.layer.overlay}
            visibility: visible`
        }
        return ``
    }};
`