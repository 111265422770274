import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import theme from '../../theming'

export interface Props {
    className?: string
    image: string
    fallbackImage?: string
    height?: string
    width?: string
    center?: boolean
    cover?: boolean
    fullwidth?: boolean
    round?: boolean
    onClick?: () => any
}

const BackgroundImage: React.FC<Props> = (props) => {
    return <div className={props.className} onClick={props.onClick} />
}

const StyledBackgroundImage = styled(BackgroundImage)((props) => {
    const fallback = props.fallbackImage
        ? `, url('${props.fallbackImage}')`
        : ''
    return {
        backgroundImage: `url('${props.image}')${fallback}`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: props.center ? 'center' : 'top',
        backgroundSize: props.cover ? 'cover' : 'contain',
        width: `${props.width || '100%'}`,
        height: `${props.height || '100%'}`,
        minWidth: props.fullwidth ? '100vw' : theme.size.small,
        minHeight: theme.size.small,
        borderRadius: props.round ? '50%' : '0',
        transition: 'height 0.2s, width 0.2s',
    }
})
export default StyledBackgroundImage
