import React from 'react'
import TextLine from '../TextLine'

const getLimit = (limitFirstVal: number, limitSecondVal: number) => {
    let limit: number[] = []
    return limit = [limitFirstVal, limitSecondVal]
}

const getNewCropTop = (newCropTopVal: number): number => {
    let newCropTop: number
    return newCropTop = newCropTopVal
}

const getNewCropHeight = (newCropHeightVal: number): number => {
    let newCropHeight: number
    return newCropHeight = newCropHeightVal
}

const getNewCropWidth = (newCropWidthVal: number): number => {
    let newCropWidth: number
    return newCropWidth = newCropWidthVal
}

const getNewCropLeft = (newCropLeftVal: number): number => {
    let newCropLeft: number
    return newCropLeft = newCropLeftVal
}

const setTop = (
    limitFirstVal: number, limitSecondVal: number,
    clientY: number, newCropHeightVal: number
): [number, number] => {
    const limit = getLimit(limitFirstVal, limitSecondVal)
    const newCropTopVal = clientY < limit[0] ? limit[0] : Math.min(clientY, limit[1])
    const newCropTop = getNewCropTop(newCropTopVal)
    const newCropHeight = getNewCropHeight(newCropHeightVal - newCropTop)
    return [newCropTop, newCropHeight]
}

const setRight = (
    limitFirstVal: number, limitSecondVal: number,
    clientX: number, crop: Dimensions
): number => {
    const limit = getLimit(limitFirstVal, limitSecondVal)
    const newCropWidthVal = 
        (clientX < limit[0] 
            ? limit[0] 
            : Math.min(limit[1], clientX)) - crop.left
    return getNewCropWidth(newCropWidthVal)
}

const setBottom = (
    limitFirstVal: number, limitSecondVal: number,
    clientY: number, crop: Dimensions
): number => {
    const limit = getLimit(limitFirstVal, limitSecondVal)
    const newCropHeightVal =
    (clientY < limit[0]
        ? limit[0]
        : Math.min(clientY, limit[1])) - crop.top
    return getNewCropHeight(newCropHeightVal)
}

const setLeft = (
    limitFirstVal: number, limitSecondVal: number,
    clientX: number, crop: Dimensions
): [number, number] => {
    const limit = getLimit(limitFirstVal, limitSecondVal)
    const newCropLeftVal =
        clientX < limit[0] 
            ? limit[0] 
            : Math.min(clientX, limit[1])
    const newCropLeft = getNewCropLeft(newCropLeftVal)
    const newCropWidth = crop.width + crop.left - newCropLeft
    return [newCropLeft, newCropWidth]
}

const setCropByCase = (
    activeCropEdge: string, imageDetails: Dimensions,
    halfHandle: number, crop: Dimensions,
    tripleHandle: number,
    clientX: number, clientY: number,
    setCrop: React.Dispatch<React.SetStateAction<Dimensions>>
): void => {
    switch (activeCropEdge) {
        case 'top':
            const [newCropTop, newCropHeightTop] = setTop(
                (imageDetails.top + halfHandle),
                (crop.top + crop.height - tripleHandle),
                clientY, (crop.height + crop.top)
            )
            setCrop((oldCrop) => ({
                ...oldCrop,
                top: newCropTop,
                height: newCropHeightTop
            }))
            break
        case 'right':
            const newCropWidthRight = setRight(
                (crop.left + tripleHandle), (imageDetails.left + imageDetails.width - halfHandle),
                clientX, crop
            )
            setCrop((oldCrop) => ({
                ...oldCrop,
                width: newCropWidthRight
            }))
            break
        case 'bottom':
            const newCropHeightBottom = setBottom(
                (crop.top + tripleHandle), (imageDetails.top + imageDetails.height - halfHandle),
                clientY, crop
            )
            setCrop((oldCrop) => ({
                ...oldCrop,
                height: newCropHeightBottom
            }))
            break
        case 'left':
            const [newCropLeft, newCropWidth] = setLeft(
                (imageDetails.left + halfHandle), (crop.left + crop.width - tripleHandle),
                clientX, crop
            )
            setCrop((oldCrop) => ({
                ...oldCrop,
                left: newCropLeft,
                width: newCropWidth
            }))
            break
        default:
            break
    }
}

const evtAction = (evt: React.MouseEvent | React.TouchEvent): [number, number] => {
    let clientX = 0
    let clientY = 0
    if ((evt as React.MouseEvent).clientX) {
        const mouseEvt = evt as React.MouseEvent
        clientX = mouseEvt.clientX
        clientY = mouseEvt.clientY
    }
    if ((evt as React.TouchEvent).touches) {
        const touchEvt = evt as React.TouchEvent
        clientX = touchEvt.touches[0].clientX
        clientY = touchEvt.touches[0].clientY
    }
    return [clientX, clientY]
}

export const handleDrag = (
    evt: React.MouseEvent | React.TouchEvent,
    cropperDimensions: Dimensions | null,
    activeCropEdge: string, imageDetails: Dimensions,
    halfHandle: number, crop: Dimensions,
    tripleHandle: number,
    setCrop: React.Dispatch<React.SetStateAction<Dimensions>>
): void => {
    if (!cropperDimensions) {
        return
    }
    // What needs to be done is to listen for mouse move event on the document
    // if the mouse moves outside the bounds, then just update the cropper edge to
    // be at the boundary
    evt.preventDefault()
    evt.stopPropagation()
    if (!activeCropEdge) {
        return
    }
    let [clientX, clientY] = evtAction(evt)
    clientX -= cropperDimensions.left
    clientY -= cropperDimensions.top
    setCropByCase(
        activeCropEdge, imageDetails, halfHandle, crop,
        tripleHandle, clientX, clientY, setCrop
    )
}

const setBoundingBox = (
    image: string, imageData: CropperOutput = { imageUrl: image },
    crop: Dimensions, imageDetails: Dimensions
): void => {
    if (crop.left || crop.top || crop.width || crop.height) {
        imageData.boundingBox = {
            top: (crop.top - imageDetails.top) / imageDetails.height,
            left: (crop.left - imageDetails.left) / imageDetails.width,
            right:
                (crop.left - imageDetails.left) / imageDetails.width +
                crop.width / imageDetails.width,
            bottom:
                (crop.top - imageDetails.top) / imageDetails.height +
                crop.height / imageDetails.height
        }
    }
}

export const finishCropping = (
    image: string, crop: Dimensions,
    imageDetails: Dimensions, onCropComplete: (cropped: CropperOutput) => any
): void => {
    const imageData: CropperOutput = { imageUrl: image }
    setBoundingBox(image, imageData, crop, imageDetails)
    onCropComplete(imageData)
}

export const cropStartedFrom = (
    edge: string, setActiveCropEdge: React.Dispatch<React.SetStateAction<string>>
): (evt: any) => void => {
    return (evt: any) => {
        evt.preventDefault()
        evt.stopPropagation()
        setActiveCropEdge(edge)
    }
}

export const handles = (
    edges: string[], cropStartedFrom: (edge: string) => (evt: any) => void, 
    handleDrop: () => void, handleDrag: (evt: React.MouseEvent | React.TouchEvent) => void
): JSX.Element[] => {
    return edges.map((edge) => (
        <div
            key={edge}
            className={`cropHandleContainer cropHandleContainer-${edge}`}
            onTouchEnd={handleDrop}
            onTouchStart={cropStartedFrom(edge)}
            onTouchMove={handleDrag}
            onMouseMove={handleDrag}
            onMouseDown={cropStartedFrom(edge)}
            onMouseUp={handleDrop}
        >
            <div className={`cropHandle cropHandle-${edge}`} />
        </div>
    ))
}

export const cropText = (
    translate: (word: string) => string, wordTranslate: string
) => (
    <div>
        <TextLine
            text={translate(wordTranslate)}
            center={true}
            color="primaryLight"
            type="small"
        />
        <br />
    </div>
)
