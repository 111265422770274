import React, { useContext } from 'react'
import styled from 'styled-components'
import Button from '../../components/Button'
import TextLine from '../../components/TextLine'
import theme from '../../theming'
import { NavLink } from 'react-router-dom'
import ReactGA from 'react-ga'
import AppContext from '../../helpers/AppContext'

export interface Props {
    className?: string
}

const TrendingSearches: React.FC<Props> = (props) => {
    const { className } = props
    const { isDesktop } = useContext(AppContext)
    // todo: use i18n instead
    const heading = 'Trending Searches'
    const searchQueries: string[] = [
        'Shoulder pad',
        'Cut out top',
        'Three piece swimsuit',
        'Knit sets',
        'Crop top',
        'Sequin dress',
        'Charm bracelet',
        'Floral print dress',
        'Cut out dress',
        'Crochet knit',
        'Wide leg pants',
        'Sequin skirt',
        'Wrap around top',
        'Sequin top',
        'Tie dye print',
        'Sheer top',
        'Puff sleeves',
        'Bubblegum pink',
        'Hoop earrings'
    ]
    const trackClick = (query: string) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked on trending searches'
        })
        ReactGA.event({
            category: 'User',
            action: `Clicked on trending searches: ${query}`
        })
    }
    const getQueryLinks = () => {
        const getLinkAddress = (query: string, i: number) => {
            return `/text-search/?query=${encodeURIComponent(
                query
            )}&source=trending--${encodeURIComponent(query)}`
        }

        return searchQueries.map((query, i) => (
            <NavLink to={getLinkAddress(query, i)} key={`${i}--${query}`}>
                <Button
                    textType={isDesktop ? 'buttontext' : 'buttontextsmall'}
                    key={i}
                    inline={true}
                    text={query}
                    padding={isDesktop ? theme.space.large : theme.space.small}
                    background="white"
                    color="black"
                    className="text-search-link"
                    onClick={() => trackClick(query)}
                    borderColor="grayLighter"
                />
            </NavLink>
        ))
    }
    return (
        <Wrapper className={className}>
            <div className="container">
                <TextLine type="heading4" bold={true} text={heading} />
            </div>
            <div className="container">{getQueryLinks()}</div>
        </Wrapper>
    )
}

const StyledTrendingSearches = styled(TrendingSearches)((props) => {
    return {
        margin: `-${theme.space.medium} 0 ${theme.space.smaller}`,
        ['.container']: {
            marginTop: theme.space.medium
        },
        ['.text-search-link']: {
            marginRight: theme.space.smaller,
            marginBottom: theme.space.smaller
        }
    }
})

const Wrapper = styled.div`
    margin-top: 20px;
    ${theme.media.from.md`
        margin-top: ${theme.space.medium};
    `}
`
export default StyledTrendingSearches
