import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Textline from '../TextLine'
import theme from '../../theming'

export interface Props {
    className?: string
    heading: string
    height?: string
    items?: JSX.Element[]
}

const HorizontalScroll: React.FC<Props> = (props) => {
    const items = props.items || []
    return (
        <div className={props.className}>
            <Textline type="body" text={props.heading} />
            <div className="items">{...items}</div>
        </div>
    )
}

const StyledHorizontalScroll = styled(HorizontalScroll)((props) => {
    return {
        ['.items']: {
            overflowX: 'auto',
            overflowY: 'hidden',
            height: props.height || 'auto',
            whiteSpace: 'nowrap',
            display: 'grid',
            gridTemplateColumns: `1fr 1fr 1fr`,
            gridGap: '12px',
            marginTop: '5px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                overflowX: 'clip',
                overflowY: 'scroll',
                gridGap: '0 6px',
                maxHeight: '150px'
            },
        },
    }
})

export default StyledHorizontalScroll
