import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from '../../theming'

export interface Props {
    className?: string
    main: React.ReactElement
    aside: React.ReactElement
}

const ProductListWrapper: React.FC<Props> = (props) => {
    const { main, aside } = props
    return (
        <div className={props.className}>
            <div className="side-bar">{aside}</div>
            <div className="main-content">{main}</div>
        </div>
    )
}

const StyledProductListWrapper = styled(ProductListWrapper)((props) => {
    return {
        [`${theme.media.largeScreenBreakpoint}`]: {
            display: 'grid',
            gridTemplateColumns: '350px 5fr'
        },
        gridGap: theme.space.larger,
        ['.side-bar']: {
            display: 'none',
            marginTop: `-${theme.space.medium}`,
            paddingTop: theme.space.medium,
            [`${theme.media.largeScreenBreakpoint}`]: {
                display: 'flex',
                width: '100%',
                boxShadow: '1px 0px 0px rgba(8, 8, 8, 0.12)'
            }
        },
        ['.main-content']: {
            maxWidth: '100%'
        }
    }
})
export default StyledProductListWrapper
