import React from 'react'
import TextLine from '../TextLine'
import theme, { ColorType } from '../../theming'
import Icon from '../Icon'
import { FEEDBACK_LINK } from '../../constants'
import styled from 'styled-components'
import { useTranslate } from '../../helpers/useTranslate'

const textline = (text: React.ReactNode, color?: ColorType): JSX.Element => (
    <TextLine inline={true} text={text} color={color} type="body" />
)

const mapFeedbackBody = (text: React.ReactNode, index: number): JSX.Element => {
    if (index % 2 === 1) {
        return (
            <span key={`inline-text--${index}`}>
                &nbsp;{textline(text, 'accent')}&nbsp;
            </span>
        )
    }
    return <span key={`inline-text--${index}`}>{textline(text)}</span>
}

const bodyText = (homepageFeedbackBody: string) => {
    return homepageFeedbackBody.split('__').map(mapFeedbackBody)
}

export const getSectionBody = (): JSX.Element => {
    const { translate } = useTranslate()
    const sectionBdy = (
        <div className="sectionBody">
            <Icon name="MessageSquare" />
            <a target="_blank" href={FEEDBACK_LINK}>
                {bodyText(translate('HOMEPAGE_FEEDBACK_BODY'))}
            </a>
        </div>
    )
    return sectionBdy
}

export const Wrapper = styled.div`
    margin-top: 20px;
    ${theme.media.from.md`
        margin-top: 50px;
    `}
`