import { useTranslation } from 'react-i18next'

type TranslateHook = (namespace?: string[]) => {
    translate: (key: string, options?: any) => string
}

export const useTranslate: TranslateHook = (namespace?) => {
    const { t } = useTranslation(namespace)

    return {
        translate: (key, options?) => t(key, options)
    }
}