import React from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import Button from '../Button'
import { animateScroll as scroll } from 'react-scroll'
import { getToolElements } from './helper'

export interface Props {
    className?: string
    tools?: Array<{ icon: string; onClick: () => any }>
}

const FloatingToolbar: React.FC<Props> = (props) => {
    const { className, tools = [] } = props
    return (
        <div className={className}>
            <Button
                icon="ChevronUp"
                color="primary"
                transparent={true}
                noBorder={true}
                iconOnly={true}
                inline={true}
                padding={theme.space.small}
                onClick={() => scroll.scrollToTop()}
            />
            {tools.length ? <div className="divider" /> : null}
            {getToolElements(tools)}
        </div>
    )
}

const StyledFloatingToolbar = styled(FloatingToolbar)((props) => {
    return {
        position: 'fixed',
        right: 0,
        bottom: 100,
        padding: theme.space.smaller,
        borderRadius: `${theme.space.small} 0 0 ${theme.space.small}`,
        cursor: 'pointer',
        boxShadow: `rgba(0, 0, 0, 0.07) 0px 2px 8px 0px`,
        display: 'flex',
        flexDirection: 'column',
        background: theme.color.white,
        ['.divider']: {
            border: `1px solid ${theme.color.grayLighter}`
        }
    }
})
export default StyledFloatingToolbar
