import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Button from '../../components/Button'
import TextLine from '../../components/TextLine'
import theme from '../../theming'
import { Wavy } from './Sections'
import ReactGA from 'react-ga'

const Wrapper = styled.div`
    background: #080808;
    padding: 1.4rem;
    margin-left: -12px;
    margin-right: -12px;
    ${theme.media.from.md`
        padding: 8rem;
    `}

    ${theme.media.from.lg`
        padding: 13rem;
    `}

    h3, h4 {
        margin: 3rem 0;
    }
`

const Form = styled.form`
    margin: 2rem 0;
`

const Flex = styled.div<any>`
    input,
    textarea {
        width: 100%;
        margin-bottom: ${theme.space.largest};
        padding: 14px 20px 14px;
        border: 1px solid #080808;
        box-sizing: border-box;
        border-radius: 8px;
        ${theme.font.body};
        outline: none;
        background: ${theme.color.grayDark};
        resize: none;
        color: ${theme.color.white};
    }

    input {
        margin-bottom: ${theme.space.medium};
    }

    button {
        background-color: white;
    }

    ${theme.media.above.md`
        display: flex;
        justify-content: space-between;
        input {
            flex: 0 49%;
        }
    `}
`

interface FormValueType {
    personName: string
    company: string
    email: string
    phone: string
    text: string
}

const initialValue = {
    personName: '',
    company: '',
    email: '',
    phone: '',
    text: ''
}

const ContactUs = ({ isDesktop  }: {isDesktop?: boolean }) => {
    const [formValue, setFormValue] = useState<FormValueType>(initialValue)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccessful, setIsSuccessful] = useState(false)

    const onChange = (field: string) => (
        e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const target = e.currentTarget
        setFormValue((prev) => ({
            ...prev,
            [field]: target.value
        }))
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        setIsLoading(true)
        ReactGA.event({
            category: 'User',
            action: 'Attempted to submit business contact form'
        })
        axios
            .post('/contact-submit', formValue)
            .then(() => {
                setIsSuccessful(true)
                setIsLoading(false)
                setFormValue(initialValue)
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
            })
    }

    return (
        <Wrapper id="contact">
            <TextLine
                color="white"
                type={isDesktop ? 'subtitle' : 'small'}
                text={<Wavy color="#6FCF97">Contact Us</Wavy>}
            />
            <TextLine
                color="white"
                type={isDesktop ? 'heading3' : 'heading4'}
                text="Ready to transform your fashion ecommerce business? Let us know by filling the form below."
            />
            {isSuccessful ? (
                <TextLine
                    color="#6FCF97"
                    text="Thank you for contacting us. We will reach out to you soon."
                />
            ) : (
                <Form onSubmit={onSubmit}>
                    <Flex>
                        <input
                            name="personName"
                            value={formValue!.personName}
                            onChange={onChange('personName')}
                            type="text"
                            placeholder="Full name"
                            required={true}
                        />
                        <input
                            name="company"
                            value={formValue!.company}
                            onChange={onChange('company')}
                            type="text"
                            placeholder="Company name"
                            required={true}
                        />
                    </Flex>
                    <Flex>
                        <input
                            name="email"
                            value={formValue!.email}
                            onChange={onChange('email')}
                            type="email"
                            placeholder="Email address"
                            required={true}
                        />
                        <input
                            name="phone"
                            value={formValue!.phone}
                            onChange={onChange('phone')}
                            type="tel"
                            placeholder="Phone number (optional)"
                        />
                    </Flex>
                    <Flex>
                        <textarea
                            name="text"
                            value={formValue!.text}
                            onChange={onChange('text')}
                            placeholder="Message"
                            rows={6}
                            required={true}
                        />
                    </Flex>
                    <Flex>
                        <Button
                            buttonType="submit"
                            text={isLoading ? 'Sending...' : 'Send'}
                            transparent={true}
                            color="black"
                            disabled={isLoading}
                        />
                    </Flex>
                </Form>
            )}
        </Wrapper>
    )
}

export default ContactUs
