import TextLine from '../TextLine'
import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import theme from '../../theming'

interface Props {
    outOfStockText: string
    CTA: string
    onClick: (e: SyntheticEvent) => void
}

export const OutOfStockLabel = styled.p`
    margin: 0;
    text-transform: uppercase;
    font-size: xx-small;
    font-family: Inter, sans-serif;
    word-spacing: 2px;
    letter-spacing: 1px;
    color: red;
`

export const OutOfStockButton = styled.button`
    border: none;
    cursor: pointer;
    .cta {
        text-decoration: underline;
    }
    ${theme.media.largeScreenBreakpoint} {
        .cta {
            text-decoration: none;
        }
        &:hover .cta {
            text-decoration: underline;
        }
    }
`
export function OutOfStockBtn({ CTA, outOfStockText, onClick }: Props) {
    return (
        <OutOfStockButton className="outOfStockBadge badge" onClick={onClick}>
            <OutOfStockLabel>{outOfStockText}</OutOfStockLabel>
            <TextLine
                type="small"
                text={CTA}
                inline={true}
                color="green"
                className={'cta'}
            />
        </OutOfStockButton>
    )
}

export default OutOfStockBtn
