import React from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import TextLine from '../TextLine'
import Image, { Props as ImageProps } from '../Image/Image'
import { B2B_ASSET_PATH } from '../../constants'

interface TeamStar {
    name: string
    role: string
    photo: string
    photoConfig: ImageProps
}

const DIR: string = 'our-team'

const buildConfig = ({
    photo,
    name = '',
    role
}: {
    photo: string
    name: string
    role: string
}): TeamStar => ({
    name,
    role,
    photo,
    photoConfig: {
        srcSet: `/${DIR}/${photo}@1x.jpg 183w, /${DIR}/${photo}@2x.jpg 366w`,
        src: `${photo}@1x.jpg`,
        alt: '',
        sizes: '(max-width: 480px) 50vw, (max-width: 800px) 25vw, 300px'
    }
})
interface Props extends ImageProps {
    lazyload: boolean
}
const TeamStar = styled.img<Props>`
    max-width: 160px;
    border-radius: 8px;
    ${theme.media.largeScreenBreakpoint} {
        max-width: 304px;
    }
`

const ourTeam = [
    // {
    //     name: 'Solomon Omojola',
    //     role: 'Co-Founder & CEO',
    //     photo: 'solomon'
    // },
    {
        name: 'Ifedapo Olarewaju',
        role: 'Co-Founder & CTO',
        photo: 'ifedapo'
    },
    // {
    //     name: 'Esther Omojola',
    //     role: 'Operations Officer',
    //     photo: 'esthr'
    // },
    {
        name: 'Adeyemi Atoyegbe',
        role: 'Software Engineer',
        photo: 'adeyemi'
    },
    {
        name: 'Rabiat Mustapha',
        role: 'Designer',
        photo: 'rabiat'
    },
    {
        name: 'Olayinka Ogunwemimo',
        role: 'Front-End Engineer',
        photo: 'olayinka'
    },
    {
        name: 'Usman Abass',
        role: 'Back-End Engineer',
        photo: 'usman'
    }
    // {
    //     name: 'Olumide Dickson',
    //     role: 'Back-End Engineer',
    //     photo: 'olumide'
    // }
]
const teamStarsList: TeamStar[] = ourTeam.map((teamstar) =>
    buildConfig(teamstar)
)

const TeamDefault = () => (
    <TeamStar
        alt={'Lykdat TeamStar'}
        src={`/${DIR}/grey.jpg`}
        srcSet={`/${DIR}/grey.jpg 183w, /${DIR}/grey@2x.jpg 366w`}
        sizes={'(max-width: 480px) 50vw, (max-width: 800px) 25vw, 300px'}
        lazyload={true}
    />
)

const TeamWrapper = styled.div`
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-evenly;
    align-items: center;

    .teamstar--name {
        font-size: 12px;
        ${theme.media.largeScreenBreakpoint} {
            font-size: 16px;
        }
    }
`

const OurTeam = () => {
    return (
        <TeamWrapper>
            {teamStarsList.map(
                (
                    {
                        photoConfig: { src, srcSet, alt, sizes, className },
                        ...teamstar
                    },
                    i
                ) => (
                    <div className="card" key={`${teamstar.photo}--${i + 1}`}>
                        <TeamStar
                            src={`/${DIR}/${src}`}
                            className={className || teamstar.photo}
                            title={teamstar.name}
                            alt={alt || teamstar.name}
                            srcSet={srcSet}
                            sizes={sizes}
                            lazyload={true}
                        />
                        <TextLine
                            text={teamstar.name}
                            color={'#080808'}
                            center={true}
                            className={'teamstar--name'}
                        />
                        <TextLine
                            text={teamstar.role}
                            type="caption"
                            center={true}
                        />
                    </div>
                )
            )}
        </TeamWrapper>
    )
}

export default OurTeam
