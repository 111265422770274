import React, {
    useEffect,
    useRef,
    useState,
    ReactElement,
    useContext
} from 'react'
import styled from 'styled-components'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useElementDimensions } from '../../helpers/useElementDimensions'
import { getScaledDimensions } from '../../helpers/utils'
import theme from '../../theming'
import AppContext from '../../helpers/AppContext'
import { useTranslate } from '../../helpers/useTranslate'
import * as cropperElements from './helper'

const DEFAULT_HANDLE_SIZE = 20
export interface Props {
    className?: string
    image: string
    minHeight?: number
    handleSize?: number
    initialBorderSize?: number
    onCropComplete: (cropped: CropperOutput) => any
    finisher: ReactElement
}

const dummyDimension: Dimensions = {
    top: 0,
    left: 0,
    height: 0,
    width: 0
}

const Cropper: React.FC<Props> = (props) => {
    const {
        className,
        image,
        initialBorderSize = 0,
        handleSize = DEFAULT_HANDLE_SIZE,
        finisher,
        onCropComplete
    } = props
    const ctx = useContext(AppContext)
    const { translate } = useTranslate()

    const halfHandle = handleSize / 2
    const tripleHandle = handleSize * 3
    const adjustedInitialBorderSize = Math.max(initialBorderSize, halfHandle)
    const cropperRef = useRef<HTMLDivElement | null>(null)
    const imageRef = useRef<HTMLImageElement | null>(null)
    const [activeCropEdge, setActiveCropEdge] = useState('')
    const [imageLoaded, setImageLoaded] = useState(false)
    const cropperDimensions = useElementDimensions(cropperRef)
    const [crop, setCrop] = useState(dummyDimension)
    const [imageDetails, setImageDetails] = useState(dummyDimension)

    useEffect(() => {
        if (!cropperDimensions) {
            return
        }
        if (!imageRef.current) {
            return
        }
        const target = imageRef.current
        if (!target) {
            return
        }
        if (!cropperRef || !cropperRef.current) {
            return
        }
        const d = getScaledDimensions(
            target.naturalHeight,
            target.naturalWidth,
            cropperDimensions.height,
            cropperDimensions.width
        )
        const ht = d.height
        const wt = d.width
        const imd = { ...imageDetails, height: ht, width: wt }
        if (d.isFullHeight) {
            imd.top = 0
            imd.left = (cropperDimensions.width - wt) / 2
        } else if (d.isFullWidth) {
            imd.top = (cropperDimensions.height - ht) / 2
            imd.left = 0
        } else {
            imd.top = (cropperDimensions.height - ht) / 2
            imd.left = (cropperDimensions.width - wt) / 2
        }
        setImageDetails(imd)
        setCrop({
            top: imd.top + adjustedInitialBorderSize,
            left: imd.left + adjustedInitialBorderSize,
            height: imd.height - adjustedInitialBorderSize * 2,
            width: imd.width - adjustedInitialBorderSize * 2
        })

        // disable scrolls from weird iOS scroll behaviour
        disableBodyScroll(imageRef.current)
        // re-enable scroll after unmount
        return () => clearAllBodyScrollLocks()
    }, [imageLoaded, imageRef])

    const handleDrop = () => {
        setActiveCropEdge('')
    }

    const handleDrag = (evt: React.MouseEvent | React.TouchEvent) => {
        cropperElements.handleDrag(
            evt, cropperDimensions, 
            activeCropEdge, imageDetails,
            halfHandle, crop,
            tripleHandle, setCrop, 
        )
    }

    const cropStartedFrom = (edge: string) => {
        return cropperElements.cropStartedFrom(edge, setActiveCropEdge)
    }

    const finishCropping  = () => {
        cropperElements.finishCropping(image, crop, imageDetails, onCropComplete)
    }

    // seems not to be triggered only with document.onmouseup
    document.onmouseup = handleDrop

    const handles = cropperElements.handles(
        ['top', 'right', 'left', 'bottom'],
        cropStartedFrom, handleDrop, handleDrag
    )

    return (
        <>
            {cropperElements.cropText(translate, 'CROP_HINT')}
            <div className={className} ref={cropperRef}>
                <img
                    className="image"
                    src={image}
                    ref={imageRef}
                    onLoad={() => setImageLoaded(true)}
                    alt="Image to search with"
                />
                <div className="cropPolygon" style={crop}>
                    {handles}
                </div>
            </div>
            <div className="finisher" onClick={finishCropping}>
                {finisher}
            </div>
        </>
    )
}

const StyledCropper = styled(Cropper)((props) => {
    const { handleSize = DEFAULT_HANDLE_SIZE } = props
    const halfHandle = handleSize / 2

    return {
        height: '50vh',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        touchAction: 'none',
        marginBottom: theme.space.medium,
        ['img.image']: {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            objectPosition: 'center'
        },
        ['.cropHandleContainer']: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            ['&-top']: {
                top: `-${parseInt(theme.size.large) / 2}px`,
                left: 0,
                right: 0,
                height: theme.size.large
            },
            ['&-left']: {
                left: `-${parseInt(theme.size.large) / 2}px`,
                top: 0,
                bottom: 0,
                width: theme.size.large
            },
            ['&-bottom']: {
                bottom: `-${parseInt(theme.size.large) / 2}px`,
                left: 0,
                right: 0,
                height: theme.size.large
            },
            ['&-right']: {
                bottom: 0,
                top: 0,
                left: `calc(100% - ${parseInt(theme.size.large) / 2}px)`,
                width: theme.size.large
            }
        },
        ['.cropPolygon']: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            background: 'transparent',
            height: '80px',
            width: '90px',
            boxShadow: `10px 10px 200vw 200vh rgba(255,255,255,0.6)`
        },
        ['.cropHandle']: {
            touchAction: 'none',
            height: handleSize - 4,
            width: handleSize - 4,
            background: theme.color.white,
            border: `3px solid ${theme.color.grayDark}`,
            borderRadius: '50%',
            cursor: 'grab',
            ['&-top']: {
                top: `-${halfHandle}px`,
                left: `calc(50% - ${halfHandle}px)`
            },
            ['&-left']: {
                left: `-${halfHandle}px`,
                top: `calc(50% - ${halfHandle}px)`
            },
            ['&-bottom']: {
                bottom: `-${halfHandle}px`,
                left: `calc(50% - ${halfHandle}px)`
            },
            ['&-right']: {
                top: `calc(50% - ${halfHandle}px)`,
                left: `calc(100% - ${halfHandle}px)`
            }
        }
    }
})
export default StyledCropper
