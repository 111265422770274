import React, { useContext } from 'react'
import styled from 'styled-components'
import ContactForm from './ContactForm'
import theme from '../../theming'
import AppContext from '../../helpers/AppContext'

const Section = styled.div`
    ${theme.media.from.md`
        margin-bottom: 5em;
    `}
`

const Infographic = () => {
    const ctx = useContext(AppContext)
    const { isDesktop } = ctx

    return (
        <Section>
            <ContactForm isDesktop={isDesktop} />
        </Section>
    )
}

export default Infographic
