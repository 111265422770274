import Image, { Props as ImageProps } from '../Image/Image'
import styled from 'styled-components'
import theme from '../../theming'
import { BRANDS, BRANDS_DIR } from '../../constants'
import React from 'react'

interface Partner {
    name: string
    website?: string
    imageConfig: ImageProps
}

const buildConfig = (filename: string, name = ''): Partner => ({
    name,
    imageConfig: {
        srcSet: `/${BRANDS_DIR}/${filename}.png 1x, /${BRANDS_DIR}/${filename}@2x.png 2x`,
        src: `${filename}.png`,
        alt: '',
        sizes: ''
    }
})

const supportedBrandsList: Partner[] = BRANDS.map((brand) => buildConfig(brand))

interface Props extends ImageProps {
    lazyload: boolean
}

const BrandLogo = styled.img<Props>`
    max-width: 100px;
    &.ptl {
        max-width: 147px;
        ${theme.media.from.md`
            max-width: auto;
        `}
    }

    ${theme.media.from.md`
        flex-basis: 20vw;
        padding: 30px;
   `}
`

const BrandPartners: React.FC<{}> = () => {
    const ClientsWrapper = styled.div`
        margin: 10px auto;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-evenly;
        align-items: center;
    `
    return (
        <ClientsWrapper>
            {supportedBrandsList.map(
                (
                    {
                        imageConfig: { src, srcSet, alt, sizes, className },
                        ...partner
                    },
                    i
                ) => (
                    <BrandLogo
                        src={`/${BRANDS_DIR}/${src}`}
                        className={className || partner.name}
                        title={partner.name}
                        alt={alt || partner.name}
                        srcSet={srcSet}
                        sizes={sizes}
                        key={`${partner.name}--${i + 1}`}
                        lazyload={true}
                    />
                )
            )}
        </ClientsWrapper>
    )
}

const Wrapper = styled.div`
    padding: 30px 0;
    margin: 0 auto;
    ${theme.media.only.md`
        padding: 60px 0;
    `}

    ${theme.media.from.lg`
        margin: ${theme.size.larger} auto;
    `}
`

export default BrandPartners
