import React from 'react'
import Button, { Props as ButtonProps } from './Button'

type Props = Pick<ButtonProps, 'text' | 'icon' | 'onClick'> &
    Partial<ButtonProps>

const PrimaryCta: React.FC<Props> = (props) => {
    return <Button {...props} color={props.color || 'primary'} />
}

export default PrimaryCta
