import { css, BaseThemedCssFunction } from 'styled-components'
import { Breakpoint, breakpoints, sizes } from './breakpoints'

// Use px in breakpoints to work properly cross-browser and support users
const minCss = (px: number, ...args: [any, ...any[]]) =>
  /* prettier-ignore */
  px === 0
    ? css(...args)
    : css<any>`
      @media (min-width: ${px}px) {
        ${css(...args)}
      }
    `

const maxCss = (px: number, ...args: [any, ...any[]]) =>
  /* prettier-ignore */
  px === Number.MAX_SAFE_INTEGER
    ? css(...args)
    : css<any>`
      @media (max-width: ${px}px) {
        ${css(...args)}
      }
    `

const betweenCss = (minPx: number, maxPx: number, ...args: [any, ...any[]]) =>
  /* prettier-ignore */
  minPx === 0 && maxPx === Number.MAX_SAFE_INTEGER
    ? css(...args)
    : minPx === 0
      ? maxCss(maxPx, ...args)
      : maxPx === Number.MAX_SAFE_INTEGER
        ? minCss(minPx, ...args)
        : css<any>`
          @media (min-width: ${minPx}px) and (max-width: ${maxPx}px) {
            ${css(...args)}
          }
        `

type MediaCss = BaseThemedCssFunction<any>
type MediaBreakpointCss = { [breakpoint in Breakpoint]: MediaCss }
type MediaUnionCss = ((px: number) => MediaCss) & MediaBreakpointCss

type MediaFromCss = ((px: number) => MediaCss & { to: MediaUnionCss }) &
  { [breakpoint in Breakpoint]: MediaCss & { to: MediaUnionCss } }

type MediaToCss = ((px: number) => MediaCss & { from: MediaUnionCss }) &
  { [breakpoint in Breakpoint]: MediaCss & { from: MediaUnionCss } }

const from = (fromPx: number) => {
  const fromFn = (...args: [any, ...any[]]) => minCss(fromPx, ...args)
  fromFn.to = (toPx: number) => (...css: [any, ...any[]]) => betweenCss(fromPx, toPx, ...css)
  return fromFn
}
const to = (toPx: number) => {
  const toFn = (...args: [any, ...any[]]) => maxCss(toPx, ...args)
  toFn.from = (fromPx: number) => (...css: [any, ...any[]]) => betweenCss(fromPx, toPx, ...css)
  return toFn
}

sizes.forEach(size => {
  const { min, max } = breakpoints[size]

  const fromFn = (...args: [any, ...any[]]) => minCss(min, ...args)
  const toFn = (...args: [any, ...any[]]) => maxCss(max, ...args)

  fromFn.to = {}
  toFn.from = {}

  sizes.forEach(size => {
    fromFn.to[size] = (...css: [any, ...any[]]) => betweenCss(min, breakpoints[size].max, ...css)
    toFn.from[size] = (...css: [any, ...any[]]) => betweenCss(breakpoints[size].min, max, ...css)
  })

  from[size] = fromFn
  to[size] = toFn
})

export interface Media {
  largeScreenBreakpoint: string
  smallScreenBreakpoint: string
  largeScreenWidthQuery: string
  from: MediaFromCss
  to: MediaToCss
  above: MediaBreakpointCss
  below: MediaBreakpointCss
  only: MediaBreakpointCss
}

// iterate through the sizes and create a media template
const media: Media = {
  // @ts-ignore
  from,
  // @ts-ignore
  to,
  ...['above', 'below', 'only'].reduce((media, key) => {
    media[key] = sizes.reduce((acc, size) => {
      const { min, max } = breakpoints[size]
      switch (key) {
        case 'above':
          if (size === 'xl') {
            return acc
          }
          acc[size] = (...args: [any, ...any[]]) => minCss(max + 1, ...args)
          return acc

        case 'only':
          acc[size] = (...args: [any, ...any[]]) => betweenCss(min, max, ...args)
          return acc

        case 'below':
          if (size === 'xs') {
            return acc
          }
          acc[size] = (...args: [any, ...any[]]) => maxCss(min - 1, ...args)
          return acc

        default:
          return acc
      }
    }, {})
    return media
  }, {}),
}

export { media }
export default media