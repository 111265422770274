import { useEffect, useRef , EffectCallback, DependencyList } from 'react'

/**
 *
 * Basically behaves the same way as useEffect except it won't
 * run on the first render of the component where it's being used
 *
 */
const useEffectPostRender = (effect: EffectCallback, deps?: DependencyList): void => {
    const firstRender = useRef(true)
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            // we don't want to run anything else on first render
            return
        }

        return effect()
      }, deps)
}

export default useEffectPostRender