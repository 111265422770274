import React, { createRef, useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import TextLine from '../../components/TextLine'
import TryItOut from './TryItOut'
import ContactUs from './ContactUs'
import AppContext from '../../helpers/AppContext'
import { scrollTo } from '../../helpers/utils'
import ReactGA from 'react-ga'
import { Hero, ImageSearch, Recommendations, ShopTheLook } from './Sections'
import Pricing from './Pricing'
import Partners from '../../components/Partners/Partners'
import theme from '../../theming'

const Section = styled.div`
    ${theme.media.from.md`
        margin-bottom: 5em;
    `}

    .ImageSearch {
        ${theme.media.from.lg`
            width: 50%;
      `}
    }
`

const Wrapper = styled.div`
    padding: 30px 0;
    max-width: 1100px;
    /**
    * They are all contained already.
    * @TODO We could consider making the ScreenContainer component 100vw.
    * This would allow for flexible layout choices across pages.
    */
    margin: 0 auto;
    ${theme.media.only.md`
        padding: 60px 0;
    `}

    ${theme.media.from.lg`
        margin: ${theme.size.larger} auto;
    `}
`

const sectionMap = {
    ['hero']: 'hero',
    ['recommendation']: 'recommendation',
    ['try-it-out']: 'try-it-out',
    ['shop-the-look']: 'shop-the-look',
    ['contact']: 'contact',
    ['policy']: 'policy'
}

const Business = () => {
    const ctx = useContext(AppContext)
    const { isDesktop } = ctx

    const sectionIdsRef = Object.keys(sectionMap).reduce((acc, cur) => {
        acc[cur] = createRef()
        return acc
    }, {})

    const [selectedSection, setSelectedSection] = useState(sectionMap.hero)
    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const source = params.get('source') || ''
        ReactGA.event({
            category: 'User',
            action: `Landed on Business page with source: '${source}'`
        })
    }, [])
    useEffect(() => {
        if (selectedSection) {
            scrollTo(
                sectionIdsRef[selectedSection].current,
                90,
                () => {},
                () => setSelectedSection('')
            )
        }
    }, [selectedSection])

    return (
        <>
            <Helmet>
                <script>
                    {`(function(ss,ex){
                        window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));};
                    (function(d,s){ fs=d.getElementsByTagName(s)[0];
                    function ce(src){ var cs=d.createElement(s);
                    cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); };
                    ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js');
                    })(document,'script'); })('3P1w24dop1l7mY5n'); `}
                </script>
            </Helmet>
                <Section ref={sectionIdsRef[sectionMap.hero]}>
                    <Wrapper>
                        <Hero
                            onGetStarted={() => {
                                ReactGA.event({
                                    category: 'User',
                                    action: 'Clicked on contact us from b2b hero'
                                })
                                setSelectedSection(sectionMap['contact'])
                            }}
                            onTryItOut={() => {
                                ReactGA.event({
                                    category: 'User',
                                    action: 'Clicked on try it out from b2b hero'
                                })
                                setSelectedSection(sectionMap['try-it-out'])
                            }}
                            isDesktop={isDesktop}
                        />
                    </Wrapper>
                </Section>
                <Partners title={'We are trusted by'} />
                <Section>
                    <Wrapper>
                        <TextLine
                            className="ImageSearch"
                            text="How can Lykdat help drive your business forward?"
                            type={isDesktop ? 'heading2' : 'heading3'}
                        />
                        <ImageSearch
                            onGetStarted={() => {
                                ReactGA.event({
                                    category: 'User',
                                    action:
                                        'Clicked on contact us from image search section'
                                })
                                setSelectedSection(sectionMap['contact'])
                            }}
                            isDesktop={isDesktop}
                        />
                    </Wrapper>
                </Section>
                <Section ref={sectionIdsRef[sectionMap['try-it-out']]}>
                    <Wrapper><TryItOut/></Wrapper>
                </Section>
                <Section ref={sectionIdsRef[sectionMap.recommendation]}>
                    <Wrapper>
                        <Recommendations
                            onGetStarted={() => {
                                ReactGA.event({
                                    category: 'User',
                                    action:
                                        'Clicked on contact us from recommendations section'
                                })
                                setSelectedSection(sectionMap['contact'])
                            }}
                            isDesktop={isDesktop}
                        />
                    </Wrapper>
                </Section>
                <Section ref={sectionIdsRef[sectionMap['shop-the-look']]}>
                    <Wrapper>
                        <ShopTheLook
                            onGetStarted={() => {
                                ReactGA.event({
                                    category: 'User',
                                    action:
                                        'Clicked on contact us from shop the look section'
                                })
                                setSelectedSection(sectionMap['contact'])
                            }}
                            isDesktop={isDesktop}
                        />
                    </Wrapper>
                </Section>
                <Section ref={sectionIdsRef[sectionMap['pricing']]}>
                    <Wrapper><Pricing/></Wrapper>
                </Section>
            <Section ref={sectionIdsRef[sectionMap.contact]}>
                <ContactUs isDesktop={isDesktop} />
            </Section>
        </>
    )
}

export default Business
