import ReactGA from 'react-ga'
import * as utils from '../../helpers/utils'

export const onClick = (fileField: HTMLInputElement | null) => {
    if (!fileField) {
        return
    }
    // reset value, so 'onChange' always works
    fileField.value = ''
    fileField.click()
}

export const onFileAdded = (files: FileList | null, ctx: AppContextInterface, onImageAdded: (src: string) => void) => {
    if (!files) {
        return
    }

    const file = files[0]
    if (!utils.validateFileSize(file, ctx)) {
        return
    }

    if (file.lastModified) {
        const d = new Date()
        if (d.getTime() - file.lastModified <= 20000) {
            ReactGA.event({
                category: 'User',
                action: 'Uploaded picture from camera'
            })
        } else {
            ReactGA.event({
                category: 'User',
                action: 'Uploaded picture from gallery'
            })
        }
    }

    utils.getOrientation(file, (orientation: number) => {
        utils.getUprightImageUrl(file, orientation, (url: string) => {
            onImageAdded(url)
        })
    })
}
