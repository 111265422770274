import React from 'react'
import Icon from '../Icon'
import TextLine from '../TextLine'
import { useTranslate } from '../../helpers/useTranslate'
import styled from 'styled-components'

const getItemMarkup = (
    item: { icon: string; body: string; link: React.ReactNode },
    indx: number
) => (
    <div
        key={`${item.icon}--${indx}`}
        className="wtsSectionBodyItem"
        id={`${encodeURIComponent(item.icon)}`}
    >
        <Icon name={item.icon} className="socialIcon" />
        <div className="textWrapper">
            <TextLine text={item.body} type="body" />
            {item.link}
        </div>
    </div>
)

const getContentData = () => {
    const { translate } = useTranslate()
    const CONTENT_DATA = [
        {
            icon: 'Twitter',
            body: translate('TWITTER_SEARCH'),
            link: (
                <a
                    target="_blank"
                    href="https://twitter.com/lykdatApp"
                    className="socialLink"
                >
                    <TextLine
                        text={translate('TWITTER_FOLLOW')}
                        color="accent"
                    />
                </a>
            )
        }
    ]
    return CONTENT_DATA
}

export const sectionBody = (): JSX.Element => {
    const contentData = getContentData()
    return (
        <div className="wtsSectionBody">{contentData.map(getItemMarkup)}</div>
    )
}

export const Wrapper = styled.div `
    margin-top: 50px;
`
