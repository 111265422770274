import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Button from '../../components/Button'
import TextLine from '../../components/TextLine'
import theme from '../../theming'
import ReactGA from 'react-ga'

const Wrapper = styled.div`
    background: #080808;
    padding: 1.4rem;
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    ${theme.media.from.xs`
        padding-bottom: 300px;
    `}

    ${theme.media.from.md`
        padding: 8rem;
    `}

    ${theme.media.from.lg`
        padding: 13rem;
        padding-top: 20px;
    `}

    h3, h4 {
        margin: 3rem 0;
    }
`

const Form = styled.form`
    margin: 2rem 0;
`

const Flex = styled.div<any>`
    input {
        width: 100%;
        margin-bottom: ${theme.space.largest};
        padding: 14px 20px 14px;
        border: 1px solid #080808;
        box-sizing: border-box;
        border-radius: 8px;
        ${theme.font.body};
        outline: none;
        background: ${theme.color.grayDark};
        resize: none;
        color: ${theme.color.white};
    }

    input {
        margin-bottom: ${theme.space.medium};
    }

    button {
        background-color: white;
    }
`

const Wavy = styled.div<{ color?: string }>`
    display: inline;
    color: ${({ color }) => color && `${color}`};
`

interface FormValueType {
    personName: string
    company: string
    email: string
    role: string
}

const initialValue = {
    personName: '',
    company: '',
    email: '',
    role: '',
}

const ContactForm = ({ isDesktop  }: {isDesktop?: boolean }) => {
    const [formValue, setFormValue] = useState<FormValueType>(initialValue)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccessful, setIsSuccessful] = useState(false)

    const onChange = (field: string) => (
        e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const target = e.currentTarget
        setFormValue((prev) => ({
            ...prev,
            [field]: target.value
        }))
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        setIsLoading(true)
        ReactGA.event({
            category: 'User',
            action: 'Attempted to submit infographic details form'
        })
        axios
            .post('/infographic-submit', formValue)
            .then(() => {
                setIsSuccessful(true)
                setIsLoading(false)
                setFormValue(initialValue)
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
            })
    }

    return (
        <Wrapper>
            <TextLine
                color="white"
                type={isDesktop ? 'heading3' : 'heading4'}
                text="Fashion websites must-haves for increasing conversion rate"
            />
            <TextLine
                color="white"
                type={isDesktop ? 'subtitle' : 'small'}
                text={<Wavy color="#6FCF97">We will send you an email with a link to download the document</Wavy>}
            />
            {isSuccessful ? (
                <TextLine
                    color="#6FCF97"
                    text="Thank you for interest. You will receive a download link shortly."
                />
            ) : (
                <Form onSubmit={onSubmit}>
                    <Flex>
                        <input
                            name="personName"
                            value={formValue!.personName}
                            onChange={onChange('personName')}
                            type="text"
                            placeholder="Full name"
                            required={true}
                        />
                    </Flex>
                    <Flex>
                        <input
                            name="email"
                            value={formValue!.email}
                            onChange={onChange('email')}
                            type="email"
                            placeholder="Email address"
                            required={true}
                        />
                    </Flex>
                    <Flex>
                        <input
                            name="company"
                            value={formValue!.company}
                            onChange={onChange('company')}
                            type="text"
                            placeholder="Company name"
                            required={true}
                        />
                    </Flex>
                    <Flex>
                        <input
                            name="role"
                            value={formValue!.role}
                            onChange={onChange('role')}
                            type="text"
                            placeholder="Company role"
                        />
                    </Flex>
                    <Flex>
                        <Button
                            buttonType="submit"
                            text={isLoading ? 'Submitting...' : 'Submit'}
                            transparent={true}
                            color="black"
                            disabled={isLoading}
                        />
                    </Flex>
                </Form>
            )}
        </Wrapper>
    )
}

export default ContactForm
