import React, { useState, Dispatch } from 'react'

function useSortAndFilterState(presetSort?: sortType, presetFilter?: MembersOf<Filter>): [
    Sorter,
    Dispatch<React.SetStateAction<Sorter>>,
    Filter,
    Dispatch<React.SetStateAction<Filter>>
] {
    const initFilter: Filter = {
        ...{
            vendor: new Set(),
            gender: new Set(),
            country: new Set(),
            color: new Set(),
            brandName: new Set()
        },
        // initialise filter state with some preset filter options.
        // this is used for a default filter behaviour on initialise
        ...presetFilter
    }
    const [sortedBy, setSortedBy] = useState<sortType | null>(presetSort || null)
    const [filteredBy, setFilteredBy] = useState<Filter>(initFilter)
    return [sortedBy, setSortedBy, filteredBy, setFilteredBy]
}

export default useSortAndFilterState
