import React from 'react'
import styled from 'styled-components'
import { useTranslate } from '../../helpers/useTranslate'
import theme from '../../theming'
import * as AboutPage from '../About'
import ImageSearcher from '../ImageSearcher'

export interface Props {
    className?: string
    primary?: boolean
}

const HomeTopSection: React.FC<Props> = (props) => {
    const { className } = props
    const { translate: t } = useTranslate()

    return (
        <div className={className}>
            <div className="container">
                <div className="display">
                    <h1 className={'hero-text'}>
                        <AboutPage.MarkedText>
                            <span>{t('HOMEPAGE_HERO_TEXT.0')}</span>
                        </AboutPage.MarkedText>
                        <span>{t('HOMEPAGE_HERO_TEXT.1')} </span>
                        {t('HOMEPAGE_HERO_TEXT.2')}
                        <AboutPage.MarkedText>
                            <span>{t('HOMEPAGE_HERO_TEXT.3')}</span>
                        </AboutPage.MarkedText>
                    </h1>
                </div>
                <ImageSearcher
                    text={t('HOMEPAGE_HERO_CTA')}
                    noBorder={true}
                    className="SearchImageStyle w-auto"
                />
            </div>
        </div>
    )
}

const StyledHomeTopSection = styled(HomeTopSection)((props) => {
    return {
        // backgroundColor: 'white',
        marginTop: -16, // reset global padding-top
        ['.container']: {
            margin: 'auto',
            padding: `${theme.space.large}`,
            maxWidth: 700,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            zIndex: 0,
            [`${theme.media.largeScreenBreakpoint}`]: {
                padding: `${theme.space.largest} 0`
            },
            ['.display']: { marginBottom: theme.space.larger },
            ['.hero-text']: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 30,
                fontWeight: 700,
                lineHeight: '29px',
                [`${theme.media.largeScreenBreakpoint}`]: {
                    lineHeight: '58px',
                    fontSize: 48
                }
            }
        }
    }
})
export default StyledHomeTopSection
