import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import logo from '../../../public/logo.svg'
import { NavLink, useLocation } from 'react-router-dom'
import theme, { Layer } from '../../theming'
import Nav from '../Nav'
import Button from '../Button'
import UserAccountTrigger from '../UserAccountTrigger'
import AppContext from '../../helpers/AppContext'
import ReactGA from 'react-ga'
import TopSearchBox from './TopSearchBox'
import Dropdown from '../Dropdown/Dropdown'
import CurrencySelector from '../../components/CurrencySelector'
import MobileSearchBar from '../../components/MobileSearchBar'

export interface Props {
    layer?: Layer
    className?: string
}

const TopBar: React.FC<Props> = (props) => {
    const { className } = props
    const appRoute = useLocation()
    const ctx = useContext(AppContext)
    const isBusinessPage = appRoute.pathname === '/business'
    const withouTopSearchBox = [
        '/business',
        '/business/business-policy',
        '/infographic'
    ]
    const trackSignUpClick = () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked sign up CTA'
        })
    }

    const topSearchBox = withouTopSearchBox.includes(
        appRoute.pathname
    ) ? null : (
        <TopSearchBox />
    )

    const getMobileSearchBar = () => {
        const pagesWithoutMobileSearch = [
            '/business',
            '/business/',
            '/policies',
            '/infographic'
        ]
        return pagesWithoutMobileSearch.includes(appRoute.pathname) ? null : (
            <MobileSearchBar />
        )
    }
    const signUpButton =
        !isBusinessPage ? (
            ctx.currentUser || ctx.currentUser === undefined ? null : (
                <div className="signUp-btn">
                    <UserAccountTrigger source="bottom nav">
                        <Button
                            text="Join"
                            onClick={trackSignUpClick}
                            color={'grayDark'}
                            padding={theme.space.small}
                            full={true}
                            textType="buttontextsmall"
                        />
                    </UserAccountTrigger>
                </div>
            )
        ) : (
            <div className="signUp-btn">
                <a
                    href="https://console.lykdat.com/auth/signup"
                    target="_blank"
                >
                    <Button
                        text="Get Started"
                        color={'grayDark'}
                        padding={theme.space.small}
                        full={true}
                        textType="buttontextsmall"
                    />
                </a>
            </div>
        )

    const getNavItems = () => {
        if (isBusinessPage) {
            return [
                {
                    key: 'aboutUs',
                    icon: '',
                    routes: ['/about'],
                    copy: 'About us'
                },
                {
                    key: 'tryItOut',
                    icon: '',
                    routes: ['/business#try-it-out'],
                    copy: 'Try it out'
                },
                {
                    key: 'lykdatApp',
                    icon: '',
                    routes: ['/business#pricing'],
                    copy: 'Pricing'
                }
            ]
        } else {
            return [
                {
                    key: 'explore',
                    icon: 'Loader',
                    routes: ['/explore'],
                    copy: 'EXPLORE_NAV'
                },
                {
                    key: 'user',
                    icon: 'User',
                    routes: ['/user/'],
                    copy: 'USER_NAV'
                }
            ]
        }
    }
    const topCurrency = <CurrencySelector />
    return (
        <div className={className}>
            <div className="inner-wrapper">
                <NavLink className="navLogo" to={'/'}>
                    <div className="branding">
                        <img
                            className="logoImage"
                            src={logo}
                            alt="LykDat logo"
                        />
                    </div>
                </NavLink>
                <div className="topWrapper">{topSearchBox}</div>
                <div className="navWrapper">
                    <Dropdown displayText={ctx.userCurrency}>
                        {topCurrency}
                    </Dropdown>
                    <div className="nav">
                        <Nav
                            withoutIcon={true}
                            items={getNavItems()}
                            allActive={
                                !!isBusinessPage ||
                                ctx.isDesktop
                            }
                        />
                    </div>
                    {signUpButton}
                </div>
            </div>
            {/* <div className="infoBanner">
                <span>
                    Get 10% off until the 29th of October, when you{' '}
                    <a href="https://lykdat.com/shop/farfetch?vendor=farfetch">
                        shop FarFetch
                    </a>
                    . Use Discount code LYKDATFF10
                </span>
            </div> */}
            {getMobileSearchBar()}
        </div>
    )
}

const StyledTopBar = styled(TopBar)((props) => {
    return {
        flexDirection: 'column',
        boxSizing: 'border-box',
        background: theme.color.white,
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'sticky',
        width: '100vw',
        top: 0,
        zIndex: props.layer ? theme.layer[props.layer] : 'inherit',
        borderBottom: `1px solid ${theme.color.grayLighter}`,
        ['& .branding']: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            height: '100%'
        },
        ['& .inner-wrapper']: {
            display: 'flex',
            alignItem: 'stretch',
            justifyContent: 'space-between',
            margin: 0,
            padding: `0 ${theme.space.small}`,
            width: '100%',
            height: '100%',
            boxSizing: 'border-box'
        },
        ['& .logoImage']: {
            height: theme.size.largest
            // margin: `0 ${theme.space.small}`
        },
        ['& .tagline']: {
            marginTop: `-${theme.space.medium}`,
            marginLeft: theme.space.smaller
        },
        ['.navLogo']: {
            flex: 1,
            minWidth: 'min-content'
        },
        ['.navWrapper']: {
            flex: 1,
            display: 'flex',
            minWidth: 'min-content',
            justifyContent: 'flex-end'
        },
        ['& .nav']: {
            display: 'none'
        },
        [`${theme.media.largeScreenBreakpoint}`]: {
            ['.inner-wrapper']: {
                flex: 1,
                maxWidth: '1440px',
                margin: '0 auto'
            },
            justifyContent: 'space-between',
            ['& .branding']: {
                justifyContent: 'flex-start'
            },
            ['& .nav']: {
                display: 'flex'
            }
        },
        ['.signUp-btn']: {
            margin: `auto 0`,
            marginLeft: theme.space.large,
            display: 'flex'
        },
        ['& .topWrapper']: {
            [`${theme.media.smallScreenBreakpoint}`]: {
                display: 'none'
            }
        },
        ['.infoBanner']: {
            backgroundColor: 'black',
            display: 'flex',
            justifyContent: 'center',
            color: 'white',
            padding: '16px',
            fontSize: '14px',
            fontFamily: 'inter',
            [`${theme.media.smallScreenBreakpoint}`]: {
                fontSize: '12px'
            },
            ['& a']: {
                color: 'white',
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        }
    }
})
export default StyledTopBar
