import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import TextLine from '../TextLine'
import AppContext from '../../helpers/AppContext'

export interface Props {
    className?: string
    items: Array<{
        text: string
    }>
    onSwitch: (selectedTab: number) => void
    tabsTextColor?: string
}

const Tabs: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const [currentTab, setCurrentTab] = useState(0)
    useEffect(() => {
        switchTo(currentTab)        
    }, [])

    const switchTo = (idx: number) => {
        setCurrentTab(idx)
        props.onSwitch(idx)
    }

    const content = props.items.map((item, index) => (
        <div
            className={`tabItem${index === currentTab ? ' active' : ''}`}
            key={index}
            onClick={() => {switchTo(index)}}
        >
            <TextLine
                className="tabCaption"
                text={item.text}
                type={ctx.isDesktop ? 'small' : 'caption'}
                color={props.tabsTextColor || 'accent'}
            />
        </div>
    ))
    return (
        <div className={className}>
            {content}
        </div>
    )
}

const StyledTabs = styled(Tabs)((props) => {
    return {
        'width': 'auto',
        'textAlign': 'center',
        'marginTop': theme.space.medium,
        ['.active']: {
            borderBottom: `3px solid ${theme.color.primary}`
        },
        ['.tabItem']: {
            padding: `${theme.space.medium} ${theme.space.medium} ${theme.space.small}`,
            display: 'inline-block',
            cursor: 'pointer'
        },
        '.tabCaption': {
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            margin: '0 auto'
        }
    }
})
export default StyledTabs
