import React, { useState, useContext } from 'react'
import theme from '../../theming'
import Tabs from '../../components/Tabs'
import styled from 'styled-components'
import headerBg from '../../../public/policypagesheader.svg'
import AppContext from '../../helpers/AppContext'
import BusinessEntryPoint from '../../components/BusinessEntryPoint'
import TextLine from '../../components/TextLine'
import Privacy from './Privacy'
import SLA from './SLA'
import Terms from './Terms'

export interface Props {
    className?: string
}

const BusinessPolicy: React.FC<Props> = (props) => {
    const tabs = [
        { text: 'Service Level Agreement', content: <SLA /> },
        { text: 'Terms of Service', content: <Terms /> },
        { text: 'Privacy Policy', content: <Privacy /> }
    ]

    const ctx = useContext(AppContext)
    const [selectedTab, setSelectedTab] = useState(0)
    const [content, setTextContent] = useState<JSX.Element>()

    const onTabSwitch = (tabId: number) => {
        setSelectedTab(tabId)
        setTextContent(tabs[tabId].content)
    }
    const tab = (
        <Tabs items={tabs} onSwitch={onTabSwitch} className="tab" tabsTextColor="#00000" /> 
    )

    const headerText: string = tabs[selectedTab].text

    return (
        <div className={props.className}>
            <div className="top-wrap">
                <div className="header-wrap flex">
                    <header>
                        <TextLine text={headerText} type={ctx.isDesktop ? 'heading1' : 'heading4'} />
                    </header>
                </div>
                <div className="tab-wrap flex">
                    {tab}
                </div>
            </div>
            <div className="content">
                {content}
            </div>
            <BusinessEntryPoint />
        </div>
    )
}

const StyledBusinessPolicy = styled(BusinessPolicy)((props) => {
    return {
        ['.top-wrap']: {
            margin: '-16px -16px 0',
            borderBottom: '1px solid black',
            backgroundImage: `url('${headerBg}')`,
        },
        ['.flex']: {
            height: '123px',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            width: '100%'
        },
        ['.content']: {
            display: 'flex',
            paddingTop: theme.space.large,
            justifyContent: 'center'
        },
        ['.content > div']: {
            width: '80%',
            maxWidth: '850px',
            fontSize: '16px',
            opacity: 0.7,
            lineHeight: '35px'
        },
        [`${theme.media.below.lg}`]: {
            ['.tab > .tabItem']: {
                padding: `${theme.space.medium} 13px ${theme.space.small}`
            }
        }
    }
})

export default StyledBusinessPolicy
