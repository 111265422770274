import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Frame from '../../components/Frame'
import ProductListWithControls from '../../components/ProductListWithControls'
import TextLine from '../../components/TextLine'
import EmptyListMessage from '../../components/EmptyListMessage'
import UserAccountTrigger from '../../components/UserAccountTrigger'
import YouMayAlsoLike from '../../components/YouMayAlsoLike'
import Feedback from '../../components/Feedback'
import AppContext from '../../helpers/AppContext'
import { useTranslate } from '../../helpers/useTranslate'

export interface Props {
    className?: string
}

/**
 * Filters the product list to return only the products whose IDs were specified in the URL
 * query param "product_ids". If the query param is not specified the original list is retutrned.
 * These URLs are for example used to send alert emails to users about some of their saved products
 * whose prices have dropped. In the email a URL to their saved products is linked, specifying only
 * the subset whose prices have dropped.
 * @param products the list of products that will run through the filter
 */
function filterByUrlIDs(products: Product[]): Product[] {
    const params = new URLSearchParams(location.search)
    const rawProductIDs = params.get('product_ids')
    if (!rawProductIDs) {
        return products
    }

    const productIDs = new Set(rawProductIDs.split(','))
    return products.filter((prod) => productIDs.has(prod.id))
}

const Saved: React.FC<Props> = (props) => {
    const { className } = props
    const [products, setProducts] = useState<Product[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const ctx = useContext(AppContext)
    const { currentUser, storage } = ctx
    const { translate } = useTranslate()
    useEffect(() => {
        if (!currentUser) {
            return
        }
        setIsLoading(true)
        storage
            .getLikedProducts(ctx)
            .then((prods) => {
                setProducts(prods.slice().reverse())
                setIsLoading(false)
            })
            .catch((e) => {
                // @todo: tell the user something and give them an opportunity to try again
                console.log(e)
            })
    }, [currentUser, storage])

    const getContent = () => {
        if (isLoading) {
            return (
                <Frame>
                    <TextLine type="body" text="loading..." />
                </Frame>
            )
        }
        if (!currentUser) {
            const message = (
                <span>
                    <TextLine text="Please&nbsp;" type="body" inline={true} />
                    <UserAccountTrigger
                        className="loginLink"
                        source="saved screen"
                    >
                        <TextLine
                            text="sign in or sign up &nbsp;"
                            type="body"
                            inline={true}
                            color="accent"
                        />
                    </UserAccountTrigger>
                    <TextLine
                        text="to view your saved items."
                        type="body"
                        inline={true}
                    />
                </span>
            )
            return <EmptyListMessage message={message} />
        }

        return (
            <ProductListWithControls
                emptyListMessage="You have no saved items"
                products={filterByUrlIDs(products)}
                title={translate('SAVED_SCREEN_HEADER')}
            />
        )
    }

    return (
        <>
            <Helmet>
                <title>Saved &bull; LykDat &bull; Fashion Image Search</title>
            </Helmet>
            <div className={className}>{getContent()}</div>
            <YouMayAlsoLike />
            <Feedback />
        </>
    )
}

const StyledSaved = styled(Saved)((props) => {
    return { '.loginLink': { display: 'inline' } }
})
export default StyledSaved
