import styled from 'styled-components'
import theme from '../../theming'

export const Wrapper = styled.div`
    background: ${theme.color.white};
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    ${theme.media.above.md`padding: 75px;`}
    margin-bottom: 40px;
`

export const InnerWrapper = styled.div`
    max-width: 600px;
    margin: auto;
    text-align: center;
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${theme.space.larger};
`

export const BodyWrapper = styled.div`
    margin-top: ${theme.space.large};
`

export const bodyCopy =
    'Exclusive Coupons & Discounts. Personalised Style Recommendations based on your Favorite Influencers'
