import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import ProductListWithControls from '../../components/ProductListWithControls'
import TextLine from '../../components/TextLine'
import YouMayAlsoLike from '../../components/YouMayAlsoLike'
import Feedback from '../../components/Feedback'
import AppContext from '../../helpers/AppContext'
import ReactGA from 'react-ga'
import { useTranslate } from '../../helpers/useTranslate'
import { getShopProducts, textSearch } from '../../helpers/apiClient'

export interface Props {
    className?: string
}

const buildFilterQuery = (query: string, { skip }: { skip: string[] }) => {
    const searchParams: URLSearchParams = new URLSearchParams(query)
    const obj = {}
    const skipSet = new Set(skip)

    // @ts-ignore
    for (const [key, value] of searchParams) {
        if (!skipSet.has(key)) {
            if (obj[key]) {
                obj[key] = obj[key].add(value)
            }
            obj[key] = new Set([value])
        }
    }
    return obj
}

const MerchMaker: React.FC<Props> = (props) => {
    const { className } = props
    const [products, setProducts] = useState<Product[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const ctx = useContext(AppContext)
    const { title } = useParams<any>()
    const { search } = useLocation()
    const options = { skip: ['sub_category'] }

    const filter = buildFilterQuery(search, options)

    useEffect(() => {
        const query = location.search.split('?')[1] || ''
        setIsLoading(true)
        if (location.pathname === '/shop/vintage') {
            const textquery = query.split('=')[1] || ''
            textSearch(textquery)
                .then((res) => {
                    setProducts(res.result)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            getShopProducts(query)
                .then((prods) => {
                    setProducts(prods)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }

        ReactGA.event({
            category: 'User',
            action: `Viewed ${title} shop screen`
        })
    }, [])

    const getContent = () => (
        <iframe
            src="https://ai-merchmaker.vercel.app/design"
            height="900px"
            width="100%"
            style={{ border: 'none' }}
        />
    )
    return (
        <>
            <Helmet>
                <title>AI Merch Maker &bull; LykDat &bull;</title>
            </Helmet>
            <div
                style={{
                    padding: '8px',
                    backgroundColor: '#fff'
                }}
            >
                {getContent()}
            </div>
            <YouMayAlsoLike />
            <Feedback />
        </>
    )
}

const StyledMerchMaker = styled(MerchMaker)((props) => {
    return {
        '.loginLink': { display: 'inline' }
    }
})
export default StyledMerchMaker
