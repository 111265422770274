import React from 'react'
import styled from 'styled-components'
import TextLine from '../TextLine'
import Button from '../Button'
import Explore from '../Explore'
import { NavLink } from 'react-router-dom'
import theme from '../../theming'

export interface Props {
    className?: string
    message?: string | React.ReactElement
}

const EmptyListMessage: React.FC<Props> = (props) => {
    const { className, message = 'Nothing to see here...' } = props
    const messageEl =
        typeof message === 'string' ? (
            <TextLine type="subtitle" text={message} />
        ) : (
            message
        )
    return (
        <>
            <div className={className}>
                {messageEl}
                <div className="optionsContainer">
                    <div className="option">
                        <NavLink to="/">
                            <Button
                                text="Click here to return home"
                                icon="Home"
                                color="primary"
                                transparent={true}
                                noBorder={true}
                                inline={true}
                                sizeByScale="small"
                                padding="0"
                            />
                        </NavLink>
                    </div>
                </div>
            </div>

            <Explore />
        </>
    )
}

const StyledEmptyListMessage = styled(EmptyListMessage)((props) => {
    return {
        margin: `${theme.space.larger} ${theme.space.medium}`,
        paddingTop: theme.space.small,
        ['.optionsContainer']: {
            paddingTop: theme.space.smaller
        },
        ['.option']: {
            margin: `${theme.space.small} 0`
        }
    }
})
export default StyledEmptyListMessage
