import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useElementDimensions } from '../../helpers/useElementDimensions'

const InnerWrapper = styled.div<{ left?: number }>`
    margin-left: ${({ left }) => `-${left}px`};
    margin-right: ${({ left }) => `-${left}px`};
    flex: 1;
`

const VWBlock: React.FC = (props) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const containerDimensions = useElementDimensions(containerRef)
    const [leftOffset, setLeftOffset] = useState(0)

    useEffect(() => {
        if (!containerDimensions) {
            return
        }
        setLeftOffset(containerDimensions.left)
    }, [containerDimensions])

    return (
        <div ref={containerRef}>
            <InnerWrapper left={leftOffset}>{props.children}</InnerWrapper>
        </div>
    )
}

export default VWBlock
