import React from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import * as buttonElements from './helpers'

export type Props = buttonElements.Props

const Button: React.FC<Props> = (props) => {
    const content = (
        <>
            {buttonElements.getIconContent(props)} {buttonElements.getTextContent(props)}
        </>
    )
    return buttonElements.getButtonOrLink(props, content)
}

const StyledButton = styled(Button)((props) => {
    return {
        backgroundColor: buttonElements.getBgColor(props.transparent, props.background, 
            props.color, props.bgOpacity),
        border: buttonElements.getButtonBorder(props.borderColor, 
            props.noBorder, props.transparent, props.color),
        borderRadius: buttonElements.getBorderRadius(props.round, 
            props.iconOnly, props.textType, props.padding),
        color:
            theme.color[
                buttonElements.getButtonThemeColor(props.transparent, props.color)
            ],
        cursor: buttonElements.getCursor(props.disabled),
        display: buttonElements.getDisplay(props.inline),
        justifyContent: 'center',
        alignItems: 'center',
        // @todo: get rid of this mess
        padding: buttonElements.getButtonPadding(props.padding, props.iconOnly),
        width: buttonElements.getButtonWidth(props.full),
        ['.icon']: {
            marginRight: buttonElements.getIconClassMarginRight(props.iconOnly, 
                props.noBorder, props.sizeByScale)
        },
        [':focus']: {
            outline: 'none'
        },
        [':hover']: {
            backgroundColor: buttonElements.getHoverResult(props.cancelHover, props.transparent, 
                props.background, props.color, props.bgOpacity)
        },
        textDecoration: 'none'
    }
})
export default StyledButton
