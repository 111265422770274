import React from 'react'
import theme, { ColorType } from '../../theming'

export interface Props {
    color?: ColorType
}

const Divider: React.FC<Props> = (props) => {
    const { color = 'grayLighter'} = props 
    const style = {
        borderTop: `1px solid  ${theme.color[color]}`,
        borderBottom: 'none'
    }
    return <hr style={style}/>
}

export default Divider
