import React from 'react'
import styled from 'styled-components'
import TextSearchInput from '../TextSearchInput'
import theme from '../../theming'

export interface Props {
    className?: string
}

const MobileSearchBar: React.FC<Props> = (props) => {
    const { className } = props

    return (
        <div className={className}>
            <div className="search-bar">
                <TextSearchInput
                    className="text-input"
                    placeholderText="Search products, brands and categories"
                />
            </div>
        </div>
    )
}

const StyledMobileSearchBar = styled(MobileSearchBar)((props) => {
    return {
        [`${theme.media.largeScreenBreakpoint}`]: {
            display: 'none'
        },
        ['.search-bar']: {
            width: '100%',
            zIndex: 1,
            background: 'white',
            padding: `${theme.space.small} 0`
        },
        ['.text-input form']: {
            display: 'flex',
            border: `1px solid ${theme.color.grayLight}`,
            borderRadius: '8px'
        },
        ['.text-input form > button']: {
            width: '5px',
            marginRight: '7px',
            marginLeft: '15px'
        },
        ['.text-input form > button > svg']: {
            stroke: 'black'
        },
        ['.text-input input::placeholder']: {
            fontSize: '14px',
            opacity: 0.6,
            fontFamily: `'Inter', sans-serif`
        },
        ['.text-input .search-input']: {
            width: '350px',
            margin: '0 auto'
        }
    }
})
export default StyledMobileSearchBar
