import React, { useContext, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Thumbnail from '../Thumbnail'
import { NavLink } from 'react-router-dom'
import TextLine from '../TextLine'
import { ago } from '../../helpers/utils'
import { useElementDimensions } from '../../helpers/useElementDimensions'
import theme from '../../theming'
import AppContext from '../../helpers/AppContext'
import Button from '../Button'
import { useTranslate } from '../../helpers/useTranslate'

export interface Props {
    className?: string
    searches: RecentSearch[]
    withBottomToggle?: boolean
}

const SearchList: React.FC<Props> = (props) => {
    const { className, searches, withBottomToggle = false } = props
    const ctx = useContext(AppContext)
    const { translate } = useTranslate()
    const [expanded, setExpanded] = useState(false)
    const listRef = useRef<HTMLDivElement>(null)
    const containerDimensions = useElementDimensions(listRef, [
        expanded,
        searches.length
    ])
    const [height, setHeight] = useState('0')
    const hasToggle = searches.length > 3
    const bottomClasses = withBottomToggle ? '--bottomToggle' : ''
    const toggleButtonClasses = `toggleButton ${bottomClasses}`
    const Title = `${translate('RECENT_SEARCHES_TITLE')}  (${
        searches.length
    } items)`
    useEffect(() => {
        
        if (!containerDimensions) {
            return
        }
        let toggleHeight = 0
        if (hasToggle && withBottomToggle) {
            toggleHeight = parseInt(theme.size.large)
        }
        setHeight(`${containerDimensions.height + toggleHeight}px`)
    }, [containerDimensions, setHeight, ctx])
    const resultUrl = (imageURL: string) =>
        `/search-result/?image_url=${encodeURIComponent(imageURL)}`
    const searchItem = (item: RecentSearch) => (
        <li key={`srch--${item.timestamp}`} className="searchItem">
            {/* @todo: crate 'Screen' enum for instead of literals */}
            <NavLink className={className} to={resultUrl(item.query_image_url)}>
                <div className="searchItemInner">
                    <Thumbnail
                        className="thumbImg"
                        src={item.query_image_url}
                        round={false}
                        size="larger"
                    />
                    <div className="searchItemCaption">
                        <TextLine
                            text={ago(item.timestamp, translate)}
                            color="gray"
                            type={ctx.isDesktop ? 'small' : 'caption'}
                        />
                    </div>
                </div>
            </NavLink>
        </li>
    )

    const onToggleClick = () =>
        setExpanded((prevExpandedState) => !prevExpandedState)

    const toggle = hasToggle ? (
        <div className={toggleButtonClasses} onClick={onToggleClick}>
            <TextLine
                text={expanded ? 'See less' : 'See more'}
                inline={true}
                type={withBottomToggle ? 'buttontextsmall' : 'small'}
            />
            <Button
                transparent={true}
                color="primary"
                round={true}
                icon={expanded ? 'ChevronUp' : 'ChevronDown'}
                noBorder={true}
                padding="0"
                iconOnly={true}
            />
        </div>
    ) : null
    const listContent = searches
        .slice(0, expanded ? undefined : 4)
        .map((srch) => searchItem(srch))
    const list = (
        <div ref={listRef} className="list-container">
            <ul>{listContent}</ul>
            {withBottomToggle ? toggle : null}
        </div>
    )
    const heading = (
        <div className="heading-container">
            <TextLine text={Title} type="heading6" bold={true} />
            {withBottomToggle ? null : toggle}
        </div>
    )
    return (
        <div className={className}>
            {heading}
            <div style={{ height }}>{list}</div>
        </div>
    )
}

const StyledSearchList = styled(SearchList)((props) => {
    return {
        overflow: 'hidden',
        position: 'relative',
        transition: 'height 0.2s, width 0.2s',
        ['ul']: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
            [`${theme.media.smallScreenBreakpoint}`]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4,1fr)',
                gap: theme.space.small
            }
        },
        ['.searchItem']: {
            borderTop: `1px solid ${theme.color.grayDarker}`,
            paddingTop: theme.space.medium,
            paddingBottom: theme.space.medium,
            [`${theme.media.smallScreenBreakpoint}`]: {
                padding: 0,
                borderTop: '0',
                
            },
            [':hover, :focus']: {
                backgroundColor: theme.color.accentLightest
            }
        },
        ['.searchItemInner']: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        ['.thumbImg']: {
            height: '68px',
            width: '68px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                height: '88px',
                width: '100%'
            }
        },
        ['.searchItemCaption']: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.space.large,
            [`${theme.media.smallScreenBreakpoint}`]: {
                margin: `${theme.space.smallest} auto`
            }
        },
        ['.toggleButton']: {
            cursor: 'pointer',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            columnGap: theme.space.small,
            marginBottom: '20px'
        },
        ['.--bottomToggle']: {
            width: 'fit-content',
            marginLeft: '100px'
        },

        ['.heading-container']: {
            display: 'grid',
            gridTemplateColumns: '1fr max-content',
            columnGap: theme.space.medium,
            alignItems: 'baseline'
        },
        ['.list-container']: {
            borderBottom: `1px solid ${theme.color.grayDarker}`,
            [`${theme.media.smallScreenBreakpoint}`]: {
                borderBottom: '0'
            }
        }
    }
})
export default StyledSearchList
