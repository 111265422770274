import { removeProductFromList } from './utils'

export class CachedStorage implements AppStorage {
    private storage: AppStorage
    private likedProducts: Product[] = []
    private recentProducts: Product[] = []
    private recentSearches: RecentSearch[] = []
    private likedProductsCacheTime: number = 0
    private recentProductsCacheTime: number = 0
    private recentSearchCacheTime: number = 0
    // in minutes
    private timeout = 15

    constructor(storage: AppStorage, timeout?: number) {
        this.storage = storage
        if (timeout) {
            this.timeout = timeout
        }
    }

    private useCache(cachedTime: number) {
        const diffInMins = (Date.now() - cachedTime) / (60 * 1000)
        return diffInMins < this.timeout
    }

    public saveRecentSearch(s: SearchResultData, ctx: AppContextInterface): Promise<void> {
        return this.storage.saveRecentSearch(s, ctx).then(() => {
            // invalidate cache so it refetches recent searches next time
            this.recentSearchCacheTime = 0
        })
    }

    public getRecentSearches(ctx: AppContextInterface): Promise<RecentSearch[]> {
        if (this.useCache(this.recentSearchCacheTime)) {
            console.log('using cache for recentSearch ...')
            return Promise.resolve(this.recentSearches)
        }

        return this.storage.getRecentSearches(ctx).then((data) => {
            this.recentSearches = data
            this.recentSearchCacheTime = Date.now()
            return data
        })
    }

    public saveLikedProduct(p: Product, ctx: AppContextInterface): Promise<void> {
        return this.storage.saveLikedProduct(p, ctx).then(() => {
            if (this.useCache(this.likedProductsCacheTime)) {
                console.log('updating cache for likedProducts ...')
                this.likedProducts.push(p)                
            }
        })
    }

    public unsaveLikedProduct(p: Product, ctx: AppContextInterface): Promise<void> {
        return this.storage.unsaveLikedProduct(p, ctx).then(() => {
            if (this.useCache(this.likedProductsCacheTime)) {
                console.log('updating cache for likedProducts ...')
                removeProductFromList(p, this.likedProducts)               
            }
        })
    }

    public getLikedProducts(ctx: AppContextInterface): Promise<Product[]> {
        if (this.useCache(this.likedProductsCacheTime)) {
            console.log('using cache for likedProducts ...')
            return Promise.resolve(this.likedProducts)
        }

        return this.storage.getLikedProducts(ctx).then((data) => {
            this.likedProducts = data
            this.likedProductsCacheTime = Date.now()
            return data
        })
    }

    public saveRecentProduct(p: Product, ctx: AppContextInterface): Promise<void> {
        return this.storage.saveRecentProduct(p, ctx).then(() => {
            if (this.useCache(this.recentProductsCacheTime)) {
                console.log('updating cache for recentProducts ...')
                this.recentProducts.push(p)                
            }
        })
    }

    public getRecentProducts(ctx: AppContextInterface): Promise<Product[]> {
        if (this.useCache(this.recentProductsCacheTime)) {
            console.log('using cache for recentProducts ...')
            return Promise.resolve(this.recentProducts)
        }

        return this.storage.getRecentProducts(ctx).then((data) => {
            this.recentProducts = data
            this.recentProductsCacheTime = Date.now()
            return data
        })
    }
}