import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useState,
    useEffect
} from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import { Link } from 'react-router-dom'

interface Tab {
    id: string
    title: string
    data?: unknown
}

const HorizontalScroll = styled.div<any>`
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    height: auto;
    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
        transition: 1s all;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #00000000;
        border-radius: 10px;
        transition: 1s all;
    }
    &::-webkit-scrollbar-track {
        background: #00000000;
        border-radius: 10px;
        transition: 1s all;
    }
    ${theme.media.from.lg`
        overflow-x: hidden;
    `}
`

const TabsContext = React.createContext<
    | {
          isActive: string
          setActive: Dispatch<SetStateAction<string>>
          addTab: (arg: Tab) => void
          removeTab: (idx: string) => void
          tabs: Set<Tab>
      }
    | undefined
>(undefined)

export function useTabGroupContext() {
    const ctx = useContext(TabsContext)
    if (!ctx) {
        throw new Error('Put Tabs in TabsContext')
    }
    return ctx
}

const TabGroup = ({
    children,
    button: Btn,
    defaultActiveTab
}: PropsWithChildren<{ button: any; defaultActiveTab?: string }>) => {
    const [isActive, setActive] = useState<string>('')
    const [tabs, setTab] = useState<Set<Tab>>(new Set([]))

    useEffect(() => {
        if (defaultActiveTab) {
            for (const tab of tabs) {
                if (tab.data === defaultActiveTab) {
                    setActive(tab.id)
                    break
                }
            }
        }
        return () => {}
    }, [defaultActiveTab, tabs])

    const addTab = (tab: Tab) => {
        setTab(tabs.add(tab))
    }
    const removeTab = (idx: string) => {
        setTab(() => {
            tabs.forEach((tab) => {
                if (tab.id === idx) {
                    tabs.delete(tab)
                }
            })
            return tabs
        })
    }

    const ULWrapper = styled.ul`
        padding: 0;
        margin: 0;
        display: flex;
        font-family: 'Inter', sans-serif;
    `
    const StyledLink = styled(Link)`
        color: rgba(154, 154, 154, 1);
        padding: 8px 0;
    `
    const tabsHeaderMarkup = (
        <ULWrapper>
            {Array.from(tabs).map((tab: Tab) => (
                <Btn
                    key={tab.id}
                    className={isActive === tab.id ? 'active' : ''}
                    onClick={() => {
                        setActive(tab.id)
                    }}
                >
                    <StyledLink to={tab.data ? `?shop_by=${tab.data}` : ''}>
                        {tab.title}
                    </StyledLink>
                </Btn>
            ))}
        </ULWrapper>
    )

    return (
        <TabsContext.Provider
            value={{ removeTab, addTab, setActive, isActive, tabs }}
        >
            <HorizontalScroll>{tabsHeaderMarkup}</HorizontalScroll>
            {children}
        </TabsContext.Provider>
    )
}

export default TabGroup
