import React, { useContext } from 'react'
import styled from 'styled-components'
import ExploreItem from '../../components/ExploreItem'
import Section from '../../components/Section'
import { getRandomSample } from '../../helpers/utils'
import AppContext from '../../helpers/AppContext'

export interface Props {
    className?: string
}

const YouMayAlsoLike: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    if (!ctx.exploreItems.length) {
        return null
    }

    // todo: use i18n instead
    const headingText = 'You May Also Like'
    const exploreItems = getRandomSample(ctx.exploreItems, 3)
    const sectionBody = (
        <>
            {exploreItems.map((item, idx) => (
                <ExploreItem {...item} key={`${item.media_url}-${idx}`} />
            ))}
        </>
    )

    return (
        <div className={className}>
            <Section title={headingText} body={sectionBody} />
        </div>
    )
}

const StyledYouMayAlsoLike = styled(YouMayAlsoLike)((props) => {
    return {}
})
export default StyledYouMayAlsoLike
