import React, { useRef, useState, useEffect, useContext } from 'react'
import { useElementDimensions } from '../../helpers/useElementDimensions'
import AppContext from '../../helpers/AppContext'
import Icon from '../../components/Icon'
import TextLine from '../TextLine'
import * as dropdownElements from './helper'

type ContentAlignment = 'left' | 'center' | 'right'

export interface Props {
    className?: string
    children?: React.ReactElement
    displayText?: string
    withButtonBorder?: boolean
}

const Dropdown: React.FC<Props> = (props) => {
    const [isActive, setIsActive] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const [alignment, setAlignment] = useState<ContentAlignment>('center')
    const { withButtonBorder, children, displayText } = props
    const containerDimensions = useElementDimensions(containerRef, [children])
    const contentDimensions = useElementDimensions(contentRef, [children])
    useEffect(() => {
        setIsActive(false)
    }, [children])

    useEffect(() => {
        if (!containerDimensions) {
            return
        }
        if (!contentDimensions) {
            return
        }
        if (!window) {
            return
        }

        const windowWidth = window.innerWidth
        const contentWidth = contentDimensions.width
        const containerWidth = containerDimensions.width

        if (!containerWidth) {
            return
        }
        if (!contentWidth) {
            return
        }

        const containerStartingPoint = containerDimensions.left
        const enoughSpace = contentWidth / 1.5
        const midpoint = containerStartingPoint + containerWidth / 2

        const shouldAlignLeft = midpoint < enoughSpace
        const shouldAlignRight = windowWidth - midpoint < enoughSpace

        if (shouldAlignLeft) {
            setAlignment('left')
        } else if (shouldAlignRight) {
            setAlignment('right')
        }
    }, [containerDimensions, contentDimensions])

    return (
        <dropdownElements.Container ref={containerRef}>
            <dropdownElements.DropdownBtn
                onClick={(e) => setIsActive(!isActive)}
                {...{ withButtonBorder }}
            >
                <TextLine text={displayText} />
                <Icon name={isActive ? 'FiChevronUp' : 'FiChevronDown'} />
            </dropdownElements.DropdownBtn>
            <dropdownElements.DropdownContent
                className="content"
                {...{ alignment, isActive }}
                ref={contentRef}
            >
                {children}
            </dropdownElements.DropdownContent>
        </dropdownElements.Container>
    )
}

export default Dropdown
