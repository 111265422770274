import React, { useContext, useState, ReactElement } from 'react'
import AppContext from './AppContext'
import Button from '../components/Button'

// timeout value needs to be higher than the
//  css transition value to
//  avoid UI glitches
export const transitionValue = 0.3

export const doneButton = (btnTxt: string, close: () => void) => {
    if (btnTxt) {
        return (
            <div className="doneButtonContainer">
                <Button text={btnTxt} full={true} onClick={close} />
            </div>
        )
    }
    return null
}

export const useDrawerCloser = (): [boolean, 
    boolean, ReactElement<any, string | React.JSXElementConstructor<any>> | null, 
    string, (callBack: () => void) => void, 
    (callBack: () => void) => void] => {
    const [closing, setClosing] = useState(false)
    const [closed, setClosed] = useState(true)
    const { drawer, openDrawer, drawerCta, setDrawerCta } = useContext(
        AppContext
    )

    const timeoutAdditionalPeriod = 20
    const timeoutVal = (transitionValue * 1000) + timeoutAdditionalPeriod

    const close = (closeCallback: () => void) => {
        return new Promise<void>((resolve, _reject) => {
            setClosing(true)
            closeCallback() 
            setTimeout(() => {
                setClosing(false)
                openDrawer(null)
                setDrawerCta('')
                setClosed(true)
                resolve()
            }, timeoutVal)
        })
    }

    const open = (closeCallback: () => void) => {
        closeCallback()
        setClosed(false)
    }

    return [closed, closing, drawer, drawerCta, close, open]
}
