import React, { useContext, useState } from 'react'
import AppContext from '../../helpers/AppContext'
import UserProfile from '../UserProfile'
import ReactGA from 'react-ga'

export interface Props {
    className?: string
    children: React.ReactElement
    source?: string
}

const UserAccuountTrigger: React.FC<Props> = (props) => {
    // @todo: consider exttracting this to a custom hook like useDrawer
    const { openDrawer } = useContext(AppContext)
    const handleClick = () => {
        ReactGA.event({
            category: 'User',
            action: `Tried to login ${
                props.source ? `from ${props.source}` : ''
            }`
        })
        openDrawer(<UserProfile />)
    }
    return (
        <div
            onClick={handleClick}
            className={props.className}
            style={{ cursor: 'pointer' }}
        >
            {props.children}
        </div>
    )
}

export default UserAccuountTrigger
