import React, {
    SyntheticEvent,
    useEffect,
    useRef,
    useState
} from 'react'
import { useElementDimensions } from '../../helpers/useElementDimensions'
import styled from 'styled-components'
import theme from '../../theming'
import Swipable from '../Swipable'
import { useDrawerCloser, transitionValue, doneButton } from '../../helpers/drawerControl'

export interface Props {
    className?: string
}

const BottomDrawer: React.FC<Props> = (props) => {
    const { className } = props
    const drawerRef = useRef<HTMLDivElement>(null)
    // using percentage to avoid this issue
    // https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
    const [top, setTop] = useState('100%')
    const [closed, closing, drawer, drawerCta, close, open ] = useDrawerCloser()

    const containerDimensions = useElementDimensions(drawerRef, [drawer])

    const closeDrawer = () => close(() => setTop('100%'))
    
    useEffect(() => {
        if (closing) {
            return
        }
        if (!containerDimensions) {
            return
        }
        if (drawer) {
            open(() => setTop(`calc(100% - ${containerDimensions.height}px)`))
        } else if (!closed) {
            closeDrawer()
        }
    }, [closing, containerDimensions])

    const onOverlayClick = (e: SyntheticEvent) => {
        closeDrawer()
        e.stopPropagation()
    }

    return (
        <div style={{ top: closed ? '100%' : 0 }} className={className}>
            {drawer && <div className="overlay" onClick={onOverlayClick} />}
            <div className="drawer" style={{ top }} ref={drawerRef}>
            <div className="inner">
                    <Swipable onSwipeDown={closeDrawer}>
                        <div className="handleContainer">
                            <div className="handle" />
                        </div>
                    </Swipable>
                    <div className="body">{drawer}</div>
                    {doneButton(drawerCta, closeDrawer)}
                </div>
            </div>
        </div>
    )
}

const StyledBottomDrawer = styled(BottomDrawer)((props) => {
    return {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        bottom: theme.size.larger,
        width: '100vw',
        zIndex: theme.layer.appShell + 1,
        ['.overlay']: {
            position: 'absolute',
            height: '100%',
            width: '100vw',
            backgroundColor: theme.color.black,
            opacity: 0.3
        },
        ['.drawer']: {
            position: 'absolute',
            width: '100vw',
            borderRadius: `${theme.size.smaller} ${theme.size.smaller} 0 0`,
            backgroundColor: theme.color.white,
            transition: `top ${transitionValue}s`
        },
        ['.handle']: {
            margin: `${theme.space.medium} auto`,
            height: theme.space.smaller,
            width: theme.size.larger,
            backgroundColor: theme.color.grayLighter,
            borderRadius: `${parseInt(theme.size.smaller) / 2}px`,
            ['&:hover']: {
                backgroundColor: theme.color.grayDark
            }
        },
        ['.doneButtonContainer']: { padding: theme.space.large },
        ['.handleContainer']: {
            padding: theme.space.medium
        },
        ['.inner > .body']: {
            padding: theme.space.large,
            paddingBottom: theme.space.large
        }
    }
})
export default StyledBottomDrawer
