import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useTranslate } from '../../helpers/useTranslate'
import ProductList from '../ProductList'
import TextLine from '../TextLine'
import Section, { SectionControl } from '../Section/Section'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import AppContext from '../../helpers/AppContext'

export interface Props extends RouteComponentProps {
    className?: string
}
const seeAll = (props: Props) => {
    ReactGA.event({
        category: 'User',
        action: `Clicked 'see all recent'`
    })

    props.history.push('/recent')
}
const RecentProductsPreview: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const [products, setProducts] = useState<Product[]>([])
    const { translate } = useTranslate()

    useEffect(() => {
        ctx.storage
            .getRecentProducts(ctx)
            .then((products) => {
                // reverse so most recent product appears first
                const recentProds = products
                    .slice()
                    .reverse()
                    .slice(0, 4)
                setProducts(recentProds)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [setProducts, ctx.storage])

    if (!ctx.currentUser || !products.length) {
        return null
    }

    const trackRecentProductPreviewClick = () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked recent product preview'
        })
    }

    const productList = (
        <ProductList
            products={products}
            maxLength={8}
            onProductClick={trackRecentProductPreviewClick}
        />
    )
    const sectionControls: SectionControl[] = []

    const control = {
        content: (
            <TextLine
                className="subtitle"
                text={translate('SEE_ALL')}
                type="small"
                underline={true}
                inline={true}
            />
        ),
        onClick: () => {
            seeAll(props)
        }
    }

    if (ctx.isDesktop) {
        sectionControls.push(control)
    }

    const section = products.length ? (
        <Section
            title={translate('RECENT_PRODUCTS')}
            body={productList}
            controls={sectionControls}
            bottomCTA={ctx.isDesktop ? undefined : control}
        />
    ) : null
    return <div className={className}>{section}</div>
}

const StyledRecentProducts = styled(RecentProductsPreview)((props) => {
    return {
        minHeight: '350px'
    }
})

export default withRouter(StyledRecentProducts)
