import React from 'react'
import styled from 'styled-components'
import theme from '../../theming'

interface Props {
    left?: React.ReactNode
    right?: React.ReactNode
    className?: string
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    ${theme.media.to.md`
        justify-content: center;
    `}
`

export const ContentWrapper = styled.div`
    margin-bottom: 3rem;

    ${theme.media.from.lg`
        flex: 0 45%;
    `}
`

const RichSection = ({ left, right, className }: Props) => (
    <Wrapper className={className}>
        <ContentWrapper>{left}</ContentWrapper>
        <ContentWrapper>{right}</ContentWrapper>
    </Wrapper>
)

export default RichSection