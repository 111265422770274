import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import TextLine from '../../components/TextLine'
import AppContext from '../../helpers/AppContext'
import { useTranslate } from '../../helpers/useTranslate'
import BrandPartners from '../../components/Partners/BrandPartners'
import * as AboutPage from '../../components/About'
import OurTeam from '../../components/OurTeam/OurTeam'
import DoMore from '../../components/DoMore/DoMore'
import ImageSearcher from '../../components/ImageSearcher'
import theme from '../../theming'
import 'video-react/dist/video-react.css'
import { Player } from 'video-react'
import Button from '../../components/Button'
import { FEEDBACK_LINK, HERO_DIR, OUR_VIDEO } from '../../constants'

export interface Props {
    className?: string
    primary?: boolean
}

const TextLimiter = styled.div<{ lim?: string }>`
    max-width: ${(props) => props.lim || '386px'};
    margin: ${theme.size.larger} auto;
`
const About: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const { translate: t } = useTranslate()

    return (
        <>
            <Helmet>
                <title>About LykDat &bull; Fashion Image Search | LykDat</title>
            </Helmet>
            <AboutPage.MainWarpper id="about-top">
                <div className="flex wrap h-center">
                    <div className="flex flex-1 v-center">
                        <TextLimiter lim="600px">
                            <AboutPage.HeroText>
                                {t('ABOUT_HERO_TEXT.0')}
                                <AboutPage.MarkedText>
                                    <span>{t('ABOUT_HERO_TEXT.1')}</span>
                                </AboutPage.MarkedText>
                                <AboutPage.MarkedText>
                                    <span>{t('ABOUT_HERO_TEXT.2')}</span>
                                </AboutPage.MarkedText>
                                {t('ABOUT_HERO_TEXT.3')}
                            </AboutPage.HeroText>

                            <AboutPage.Text text={t('ABOUT_PRODUCT_BODY')} />
                            <div className="flex py-2">
                                <ImageSearcher
                                    text="Image Search"
                                    full={true}
                                    noBorder={true}
                                    className="SearchImageStyle w-auto"
                                />
                                <Button
                                    className=""
                                    newTab={true}
                                    asLinkTo="/business?source=about#hero"
                                    text="Lykdat For Business"
                                    background="white"
                                    color="black"
                                    borderColor="grayLighter"
                                />
                            </div>
                        </TextLimiter>
                    </div>
                    <AboutPage.HeroWrapper className="preview">
                        <AboutPage.HeroImage
                            alt={'Lykdat Fashion Search'}
                            src={`/${HERO_DIR}/hero-small.png`}
                            srcSet={`/${HERO_DIR}/hero-small.png 381w, /${HERO_DIR}/hero-medium.png 762w`}
                            sizes={
                                '(max-width: 480px) 100vw, (max-width: 1180px) 40vw, 665px'
                            }
                        />
                    </AboutPage.HeroWrapper>
                </div>
                <AboutPage.Section className="text-left">
                    <AboutPage.SectionHeading
                        title={t('ABOUT_OUR_STORY_TITLE')}
                    />

                    <div className="flex wrap h-center">
                        <div className="flex-1 v-center flex p-2">
                            <AboutPage.Text
                                text={t('ABOUT_OUR_STORY_BODY.0')}
                            />
                            <AboutPage.Text
                                text={t('ABOUT_OUR_STORY_BODY.1')}
                            />
                            <AboutPage.Text
                                text={t('ABOUT_OUR_STORY_BODY.2')}
                            />
                        </div>
                        <div className="flex-1">
                            <Player
                                playsInline={true}
                                src={OUR_VIDEO}
                                aspectRatio={'16:9'}
                            />
                        </div>
                    </div>
                </AboutPage.Section>
                <AboutPage.Section>
                    <AboutPage.SectionHeading
                        title={t('ABOUT_OUR_MISSION_TITLE')}
                    />
                    <TextLimiter lim={'767px'}>
                        <AboutPage.Text text={t('ABOUT_OUR_MISSION_BODY')} />
                    </TextLimiter>
                </AboutPage.Section>

                <AboutPage.CalloutSection className="do-more flex">
                    <DoMore />
                </AboutPage.CalloutSection>

                <AboutPage.Section className="our-people">
                    <AboutPage.SectionHeading title={t('ABOUT_TEAM_TITLE')} />
                    <OurTeam />
                </AboutPage.Section>

                <AboutPage.Section className="supported-brands">
                    <AboutPage.SectionHeading
                        title={t('ABOUT_OUR_BRAND_PARTNERS_TITLE')}
                    />
                    <BrandPartners />
                </AboutPage.Section>

                <AboutPage.CalloutSection className="business flex v-center">
                    <TextLine
                        type="heading5"
                        text={t('LYKDAT_FOR_BUSINESSES_TITLE')}
                        color={'white'}
                        center={true}
                    />
                    <TextLimiter>
                        <TextLine
                            type="heading4"
                            className={'my-0'}
                            text={t('LYKDAT_FOR_BUSINESSES_BODY')}
                            color={'white'}
                            center={true}
                        />
                    </TextLimiter>
                    <Button
                        className="w-auto"
                        newTab={true}
                        asLinkTo="/business?source=about#hero"
                        text="Learn More"
                        background="white"
                        color="black"
                        borderColor="grayLighter"
                    />
                </AboutPage.CalloutSection>
                <AboutPage.Section>
                    <AboutPage.FeedbackHeading>
                        {t('HOMEPAGE_FEEDBACK_TITLE')}
                    </AboutPage.FeedbackHeading>
                    <a target={'_blank'} href={FEEDBACK_LINK}>
                        <p className={'center'}>{t('ABOUT_FEEDBACK')}</p>
                    </a>
                </AboutPage.Section>
            </AboutPage.MainWarpper>
        </>
    )
}

export default About
