export function isEmpty(resp: TextSearchResponse): boolean {
  if (resp.result.length) {
      return false
  }

  const grouped = resp.grouped_results
  if (grouped) {
      return (grouped.male.length + grouped.female.length + grouped.unisex.length) === 0
  }

  return true
}

export function hasGroupedResult(resp?: TextSearchResponse): boolean {
  if (!resp) {
    return false
  }

  const grouped = resp.grouped_results
  if (grouped) {
      return (grouped.male.length + grouped.female.length + grouped.unisex.length) > 0
  }

  return false
}
