import React from 'react'
import theme from '../../theming'

const SLA: React.FC = () => {
    return (
        <div style={{...theme.font.body, lineHeight: '35px'}}>
            <p>
            <span
                >If Lykdat does not meet this Service Level Agreement (&quot;SLA&quot;)
                for its hosted search services under a Plan, and if Subscriber meets its
                obligations under this SLA, Subscriber will be eligible to receive a
                Service Credit as described below. This SLA states Subscriber&#39;s sole
                and exclusive remedy for any failure by Lykdat to meet the SLA.</span
            >
            </p>
            <p><h3>Definitions</h3></p>
            <ul>
                <li>
                    <span
                    >&quot;Service Year&quot; is the preceding 365 days from the date of
                    an SLA claim.</span
                    >
                </li>
            </ul>
            <ul>
                <li>
                    <span
                    >&quot;Annual Uptime Percentage&quot; is calculated by subtracting
                    from 100% the percentage of 5 minute periods during the Service Year
                    in which Lykdat was in a state where no search requests could be
                    received. If Subscriber has been using Lykdat for less than 365 days,
                    Subscriber&rsquo;s Service Year is still the preceding 365 days but
                    any days prior to Subscriber&rsquo;s use of the service will be deemed
                    to have had 100% Availability. Any downtime occurring prior to a
                    successful Service Credit claim cannot be used for future claims.
                    Annual Uptime Percentage measurements exclude downtime resulting
                    directly or indirectly from any Lykdat SLA Exclusion (defined
                    above).</span
                    >
                </li>
            </ul>
            <ul>
            <li>
                <span
                >The &quot;Eligible Credit Period&quot; is a single month, and refers
                to the monthly billing cycle in which the most recent unavailability
                event included in the SLA claim occurred.</span
                >
            </li>
            </ul>
            <ul>
            <li>
                <span
                >A &quot;Service Credit&quot; is a dollar credit, calculated as set
                forth below, that we may credit back to an eligible Lykdat
                account.</span
                >
            </li>
            </ul>
            <p>
            <h3>Service Commitments and Service Credits</h3>
            </p>
            <p>
            <span
                >The subscriptions covered by this SLA, and the Annual Guaranteed Uptime
                available for each covered Lykdat subscription are as follows:</span
            >
            </p>
            <p>
            <span>Standard Plan (available for subscription):</span>
            </p>
            <ul>
            <li>
                <span>Annual Guaranteed Uptime = 99.99%</span>
            </li>
            </ul>
            <p>
            <span
                >If the Annual Uptime Percentage for Subscriber drops below 99.9% for
                the Service Year, Subscriber is eligible to receive a Service Credit
                equal to 10% of their bill for the Eligible Credit Period. A Service
                Credit will be applicable and issued only if the credit amount for the
                applicable monthly billing cycle is greater than one dollar ($1 USD).
                Service Credits may not be transferred or applied to any other
                account.</span
            >
            </p>
            <p>
            <h3>Subscriber must request financial credit</h3>
            </p>
            <p>
            <span>To receive a Service Credit, Subscriber must submit a request by
                sending an e-mail message to </span>
                <span><a className="c8" href="mailto:sla-issue@lykdat.com">sla-issue@lykdat.com</a></span>
                <span>. To be eligible, the credit request must (i) include
                Subscriber&rsquo;s account email in the subject of the e-mail message
                (ii) include, in the body of the e-mail, the dates and times of each
                incident the Subscriber claims to have experienced; (iii) the Subscriber
                must include the server request logs that document the errors and
                corroborate the claimed outage (any confidential or sensitive
                information in these logs should be removed or replaced with asterisks);
                and (iv) be received by Lykdat within thirty (30) business days of the
                last reported incident in the SLA claim.</span>
            </p>
            <p><h3>SLA exclusions</h3></p>
            <p>
            <span
                >The Service Commitment does not apply to any unavailability, suspension
                or termination of Lykdat, or any other Lykdat performance issues: (i)
                caused by factors outside of Lykdat&rsquo;s reasonable control,
                including any force majeure event or Internet access or related problems
                beyond the demarcation point of Lykdat; (ii) that result from any
                actions or inactions of Subscriber or any third party; (iii) that result
                from Subscriber&rsquo;s equipment, software or other technology and/or
                third party equipment, software or other technology (other than third
                party equipment within our direct control); (v) arising from our
                suspension and termination of Subscriber&rsquo;s right to use Lykdat in
                accordance with the Lykdat Terms of Service (collectively, the
                &quot;Lykdat SLA Exclusions&quot;). If availability is impacted by
                factors other than those explicitly listed in this agreement, Lykdat may
                issue a Service Credit considering such factors in Lykdat&rsquo;s sole
                discretion.</span>
            </p>
        </div>
    )
}

export default SLA
