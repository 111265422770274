export interface BreakpointRange {
    min: number
    max: number
}
  
export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const XL: BreakpointRange = { min: 1441, max: Number.MAX_SAFE_INTEGER }
export const LG: BreakpointRange = { min: 992, max: 1440 }
export const MD: BreakpointRange = { min: 768, max: 991 }
export const SM: BreakpointRange = { min: 576, max: 767 }
export const XS: BreakpointRange = { min: 0, max: 575 }

export type BreakpointsType = Record<Breakpoint, BreakpointRange>

export const breakpoints: BreakpointsType = {
    xl: XL,
    lg: LG,
    md: MD,
    sm: SM,
    xs: XS,
}

export const sizes: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl']

export default breakpoints