import React from 'react'
import { Tab, TabGroup } from '../Tabs'
import CategoryBanner from '../CategoryBanner/CategoryBanner'
import styled from 'styled-components'
import theme from '../../theming'
import { useTranslate } from '../../helpers/useTranslate'

const TabButton = styled.li`
    list-style: none;
    scroll-snap-align: center;
    color: rgba(154, 154, 154, 1);
    margin: 0 20px;
    padding-bottom: 8px;
    text-wrap: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    ${theme.media.largeScreenBreakpoint} {
        font-size: 16px;
    }

    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
    &.active {
        color: rgba(8, 8, 8, 1);
        border-bottom: 2px solid rgba(8, 8, 8, 1);
        a {
            color: rgba(8, 8, 8, 1);
        }
    }
`
const H4 = styled.h4`
    font-family: 'Inter', sans-serif;
    margin: 24px 0;
    font-weight: 700;
    ${theme.media.largeScreenBreakpoint} {
        font-size: 24px;
    }
`
interface Props {
    shopByGender?: string
}
const ShopByGender: React.FC<Props> = (props) => {
    const { translate: t } = useTranslate()

    return (
        <>
            <H4>{t('SHOP_FOR_ITEMS_YOU_LIKE')}</H4>
            <TabGroup button={TabButton} defaultActiveTab={props.shopByGender}>
                <Tab
                    render={() => <CategoryBanner gender={'unisex'} />}
                    title={t('SHOP_BY_GENDER.0')}
                />
                <Tab
                    render={() => <CategoryBanner gender={'female'} />}
                    title={t('SHOP_BY_GENDER.1')}
                    data={'female'}
                />
                <Tab
                    render={() => <CategoryBanner gender={'male'} />}
                    title={t('SHOP_BY_GENDER.2')}
                    data={'male'}
                />
            </TabGroup>
        </>
    )
}

export default ShopByGender
