import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
    i18n.use(Backend)
        .use(initReactI18next)
        .use(LanguageDetector)
}

// initialize if not already initialized
if (!i18n.isInitialized) {
    i18n.init({
        detection: {
            lookupQuerystring: 'lang',
            order: ['querystring', 'navigator']
        },
        fallbackLng: 'en',
        load: 'languageOnly',
        saveMissing: true,
        debug: process.env.NODE_ENV !== 'production',
        interpolation: {
            escapeValue: false // not needed for react!!
        }
    })
}

export default i18n
