import React from 'react'
import styled from 'styled-components'
import theme, { Layer } from '../../theming'
import Nav from '../Nav'

export interface Props {
    className?: string
    layer?: Layer
}

const BottomBar: React.FC<Props> = (props) => {
    const { className } = props

    return (
        <div className={className}>
            <Nav />
        </div>
    )
}

const StyledBottomBar = styled(BottomBar)((props) => {
    return {
        background: theme.color.white,
        padding: `0 ${theme.size.larger} ${theme.size.smallest}`,
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        zIndex: props.layer ? theme.layer[props.layer] : 'inherit',
        [`${theme.media.largeScreenBreakpoint}`]: {
            display: 'none'
        }
    }
})
export default StyledBottomBar
