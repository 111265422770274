import React, { useEffect, useState, useContext } from 'react'
import styled, { CSSObject } from 'styled-components'
import theme from '../../theming'
import Button from '../Button'
import TextLine from '../TextLine'

interface Props {
    className?: string
    genderFilters: JSX.Element[]
    countryFilters: JSX.Element[]
    colorFilters: JSX.Element[]
    vendorFilters: JSX.Element[]
    brandNameFilters: JSX.Element[]
}

function toggleViewMore(
    filterType: FilterType,
    viewMores: Set<FilterType>,
    setExpandedCount: (v: number) => void
): void {
    if (viewMores.has(filterType)) {
        viewMores.delete(filterType)
    } else {
        viewMores.add(filterType)
    }

    setExpandedCount(viewMores.size)
}

const CheckboxedSortAndFilter: React.FC<Props> = (props) => {
    const [viewMores, ] = useState(new Set<FilterType>())
    const [expandedCount, setExpandedCount] = useState(0)

    return (
        <div className={props.className}>
            <TextLine text={'Filters'} className="filter-heading" />
            <div>
                <TextLine text={'Gender'} className="filter-subheading"/>
                <div className="sorts-filters" >
                    {props.genderFilters}
                </div>
            </div>
            <div>
                <TextLine text={'Location'} className="filter-subheading"/>
                <div className="sorts-filters">
                    {viewMores.has('country') ? props.countryFilters : props.countryFilters.slice(0, 3)} 
                    <Button
                        textType="small"
                        inline={true}
                        text={viewMores.has('country') ? '- View less' : '+ View more'}
                        padding={theme.space.smaller}
                        transparent={true}
                        color="gray"
                        onClick={() => toggleViewMore('country', viewMores, setExpandedCount)}
                        className="viewMorebtn"
                    />
                </div>
            </div>
            <div>
                <TextLine text={'Color'} className="filter-subheading" />
                <div className="sorts-filters">
                    {viewMores.has('color') ? props.colorFilters : props.colorFilters.slice(0, 3)} 
                    <Button
                        textType="small"
                        inline={true}
                        text={viewMores.has('color') ? '- View less' : '+ View more'}
                        padding={theme.space.smaller}
                        transparent={true}
                        color="gray"
                        onClick={() => toggleViewMore('color', viewMores, setExpandedCount)}
                        className="viewMorebtn"
                    />
                </div>
            </div>
            <div>
                <TextLine text={'Seller'}  className="filter-subheading"/>
                <div className="sorts-filters">
                    {viewMores.has('vendor') ? props.vendorFilters : props.vendorFilters.slice(0, 3)} 
                    <Button
                        textType="small"
                        inline={true}
                        text={viewMores.has('vendor') ? '- View less' : '+ View more'}
                        padding={theme.space.smaller}
                        transparent={true}
                        color="gray"
                        onClick={() => toggleViewMore('vendor', viewMores, setExpandedCount)}
                        className="viewMorebtn"
                    />
                </div>
            </div>
            <div>
                <TextLine text={'Brand name'} className="filter-subheading"/>
                <div className="sorts-filters">
                    {viewMores.has('brandName') ? props.brandNameFilters : props.brandNameFilters.slice(0, 3)} 
                    <Button
                        textType="small"
                        inline={true}
                        text={viewMores.has('brandName') ? '- View less' : '+ View more'}
                        padding={theme.space.smaller}
                        transparent={true}
                        color="gray"
                        onClick={() => toggleViewMore('brandName', viewMores, setExpandedCount)}
                        className="viewMorebtn"
                    />
                </div>
            </div>
        </div>
    )
}

const StyledCheckboxedSortAndFilter = styled(CheckboxedSortAndFilter)((props) => {
    return {
        '.filter-heading': {
            fontSize: '18px',
            marginBottom: theme.space.larger,
        },
        '.filter-heading, .filter-subheading': {
            marginTop: theme.space.medium,
        },
        '.filterButtons': {
            [`${theme.media.largeScreenBreakpoint}`]: {
                padding: `4px 170px`,
                display: 'flex !important',
                textTransform: 'capitalize',
                textAlign: 'left',
                ['.icon']: {
                    marginRight: '-242px',
                    marginLeft: '226px',
                    backgroundColor: 'black',
                    stroke: 'white'
                }
            },
        },
        '.viewMorebtn': {
            marginLeft: '0px !important'
        }
    }
})

export default StyledCheckboxedSortAndFilter
