import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import TextLine from '../../components/TextLine'
import Image from '../../components/Image'
import Icon from '../../components/Icon'
import ImageSearcher from '../../components/ImageSearcher'
import theme from '../../theming'
import { CATEGORY_COVERS } from '../../constants'
import { getTopSubCategories } from '../../helpers/apiClient'
import { Link } from 'react-router-dom'
import AppContext from '../../helpers/AppContext'
import ReactGA from 'react-ga'
import axios from 'axios'
import { useTranslate } from '../../helpers/useTranslate'

interface Props {
    className?: string
    gender: string
}

const SearchWithImage = styled.button<{ cardHeight: string }>`
    border: 2px ${theme.color.black} solid;
    margin-bottom: ${theme.space.small};
    border-radius: 6px;
    ${theme.media.from.md`
    border-radius: 8px;`}
    box-sizing: border-box;
    ${({ cardHeight }) => `height: ${cardHeight}px`}
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: ${theme.color.grayLighter};
    cursor: pointer;
    width: 100%;
`

const HorizontalScroll = styled.div<any>`
    overflow-x: auto;
    scroll-snap-type: x proximity;
    height: auto;
    padding-bottom: 16px;
    display: flex;
    gap: 20px;
    &:hover {
        ::-webkit-scrollbar-thumb {
            background-color: #000000;
        }
        ::-webkit-scrollbar-track {
            background: #00000010;
            border-radius: 10px;
        }
    }
    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
        transition: 1s all;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #000000;
        border-radius: 10px;
        transition: 1s all;
    }
    &::-webkit-scrollbar-track {
        background: #00000010;
        border-radius: 10px;
        transition: 1s all;
    }
`

const CoverImageWrapper = styled.div`
    box-sizing: border-box;
    height: auto;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
`

const CatCard = styled.div<{ cardHeight: string }>`
    flex-basis: 80px;
    flex-grow: 0;
    flex-shrink: 0;
    scroll-snap-align: start;
    ${theme.media.from.md`
            flex-basis: 120px;
        `}
`

const RoundedImage = styled(Image)`
    margin-bottom: ${theme.space.small};
    border-radius: 6px;
    ${theme.media.from.md`
    border-radius: 8px;`}
`
const Wrapper = styled.div`
    margin: ${theme.space.larger} 0;
    ${theme.media.from.md`
        margin-top: 64px;
    `}
`

const unisex = [
    'tops',
    'trousers',
    'outerwear',
    'suits',
    'footwear',
    'swimwear',
    'shoes',
    'bags',
    'socks',
    'shorts',
    'sportswear',
    'nightwear'
]

const TryItOut: React.FC<Props> = ({ gender = 'unisex' }) => {
    const ctx = useContext(AppContext)
    const { translate: t } = useTranslate()
    const categoryGroup = {
        male: new Set([
            ...unisex,
            'suspenders_&_garters',
            'boys_clothes',
            'socks'
        ]),
        female: new Set([
            ...unisex,
            'bras',
            'women_underwear',
            'skirt',
            'maternity',
            'girls_clothes',
            'dress'
        ]),
        unisex: new Set(unisex)
    }
    const [subCatList, setSubCatList] = useState<string[]>([])
    const CARD_HEIGHT = ctx.isDesktop ? '150' : '90'
    useEffect(() => {
        getTopSubCategories()
            .then((data) => {
                setSubCatList(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const onCategoryClick = (subCat: string) => {
        ReactGA.event({
            category: 'User',
            action: 'clicked homepage category'
        })
        ReactGA.event({
            category: 'User',
            action: `clicked homepage ${subCat} category`
        })
    }

    // Include articles that belong to this category
    // The subcategory list is ordered from the server.
    const orderedList = subCatList.filter((cat) =>
        categoryGroup[gender].has(cat)
    )

    const cards = orderedList.map((subCategory) => {
        if (!CATEGORY_COVERS()[subCategory]) {
            return null
        }
        return (
            <CatCard key={subCategory} cardHeight={CARD_HEIGHT}>
                <Link
                    onClick={() => onCategoryClick(subCategory)}
                    to={`/shop/${
                        CATEGORY_COVERS(gender)[subCategory].copy
                    }?sub_category=${subCategory}${
                        gender && gender !== 'unisex' ? `&gender=${gender}` : ''
                    }`}
                >
                    <CoverImageWrapper>
                        <RoundedImage
                            src={CATEGORY_COVERS(gender)[subCategory].image}
                            fit="cover"
                            height={CARD_HEIGHT}
                            width="100%"
                        />
                        <TextLine
                            text={t(CATEGORY_COVERS()[subCategory].copy)}
                            type="small"
                            color="black"
                        />
                    </CoverImageWrapper>
                </Link>
            </CatCard>
        )
    })

    return (
        <Wrapper className={'has-overflow'}>
            <HorizontalScroll className={'overflow-scroll'}>
                <CatCard cardHeight={CARD_HEIGHT}>
                    <ImageSearcher>
                        <SearchWithImage cardHeight={CARD_HEIGHT}>
                            <Icon name="Camera" size="20" />
                            {/* <TextLine text="Search with Image" type="small" /> */}
                        </SearchWithImage>
                    </ImageSearcher>
                </CatCard>
                {cards}
            </HorizontalScroll>
        </Wrapper>
    )
}

export default TryItOut
