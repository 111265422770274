import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import theme from '../../theming'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import AppContext from '../../helpers/AppContext'
import TextLine from '../TextLine'
import Button from '../Button'

export interface Props {
    className?: string
    onDone?: () => void
}

const STORAGE_KEY = 'paymentInfoBegin'
const COMPLETED_KEY = 'paymentInfoCompleted'

export function needsPaymentInfo(): boolean {
    return !!localStorage.getItem(STORAGE_KEY) && !localStorage.getItem(COMPLETED_KEY)
}

const PaymentInfoForm: React.FC<Props> = (props) => {
    const { className } = props
    const { currentUser } = useContext(AppContext)
    const payment = usePaymentInputs()
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (payment.meta.isTouched && !payment.meta.error) {
            console.log('payment.meta', payment.meta)
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [payment.meta])

    useEffect(() => {
        if (!currentUser) {
            return
        }

        const began = localStorage.getItem(STORAGE_KEY)
        if (began !== currentUser.uid) {
            ReactGA.event({
                category: 'User',
                action: 'Started Payment Info'
            })
        }

        localStorage.setItem(STORAGE_KEY, currentUser.uid)
    }, [currentUser])

    const paymentUI = (
        <PaymentInputsWrapper {...payment.wrapperProps} className='payment-parent'>
            <svg {...payment.getCardImageProps({ images })} />
            <input {...payment.getCardNumberProps()} />
            <input {...payment.getExpiryDateProps()} placeholder='MM / YY' />
            <input {...payment.getCVCProps()} />
        </PaymentInputsWrapper>
    )

    const handleClick = () => {
        if (disabled || !currentUser) {
            return
        }

        localStorage.setItem(COMPLETED_KEY, currentUser.uid)
        ReactGA.event({
            category: 'User',
            action: 'Completed Payment Info'
        })

        if (props.onDone) {
            props.onDone()
        }
    }

    return (
        <div className={className}>
            <div className="payment-wrapper">
                <div className="heading">
                    <TextLine
                        className="headerStyle"
                        text={'Payment Information'}
                        type="heading5"
                    />
                    <TextLine
                        text={'You won\'t be charged until after 30 days. You can always cancel anytime.'}
                        type="small"
                        color="#666666"
                    />
                </div>
                <div className="wtsSectionBody">
                    {paymentUI}
                </div>
                <div style={{marginTop: '20px'}}>
                    <Button
                        text={'Continue'}
                        onClick={handleClick}
                        full={true}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    )
}

const StyledPaymentInfoForm = styled(PaymentInfoForm)((props) => {
    return {
        display: 'flex',
        maxWidth: 375,
        height: 'fit-content',
        ['.payment-parent > div:nth-child(1)']: {
            border: '1px solid #ddd',
            borderRadius: '5px',
            boxShadow: 'none'
        },
        ['.payment-parent > div:nth-child(2)']: {
            fontFamily: theme.font.caption.fontFamily,
        },
        ['.payment-parent input#expiryDate[name=expiryDate]']: {
            paddingLeft: '5px',
            paddingRight: '5px',
        },
        ['.wtsSectionBody']: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        ['.heading']: {
            marginBottom: '25px'
        },
        ['.textWrapper']: {
            width: '80%',
            marginLeft: '20px'
        },
        ['.payment-wrapper']: {
            borderBottom: `1px solid ${theme.color.grayLighter}`,
            paddingBottom: '10px'
        },
        ['.headerStyle']: {
            margin: '2px 0'
        },
    }
})

export default StyledPaymentInfoForm
