import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { SortAndFilterButton } from '../../components/Button'
import SortAndFilter from '../../components/SortAndFilter'
import ProductList from '../../components/ProductList'
import ProductListWrapper from '../../components/ProductListWrapper'
import Section, { SectionControl } from '../../components/Section/Section'
import TextLine from '../../components/TextLine'
import EmptyListMessage from '../../components/EmptyListMessage'
import FloatingToolbar from '../../components/FloatingToolbar'
import AppContext from '../../helpers/AppContext'
import useSortAndFilterState from '../../helpers/SortAndFilterState'
import useEffectPostRender from '../../helpers/useEffectPostRender'

export interface Props {
    className?: string
    products: Product[]
    emptyListMessage: string
    title: string
    onProductClick?: () => void
    disableTracking?: boolean
    presetFilter?: MembersOf<Filter>
}

const ProductListWithControls: React.FC<Props> = (props) => {
    const { className, products, disableTracking } = props
    const [sort, setSort, filter, setFilter] = useSortAndFilterState(undefined, props.presetFilter)
    const ctx = useContext(AppContext)
    const { openDrawer, setDrawerCta, isDesktop } = ctx
    const [sortedProducts, setSortedProducts] = useState<Product[]>(products)

    useEffectPostRender(() => {
        setSortedProducts(products)
    }, [products])

    const handleSortAndFilterOutput = (
        newList: Product[],
        output: SortAndFilterStateData
    ) => {
        setSortedProducts(newList)
        setSort(output.sortedBy)
        setFilter(output.allFilteredBy)
    }

    const sectionBody = (
        <ProductList
            products={sortedProducts}
            disableTracking={disableTracking}
            noSimilarityBadge={true}
        />
    )
    const drawerContent = (
        <SortAndFilter
            products={products}
            sortedBy={sort}
            filteredBy={filter}
            onUpdate={handleSortAndFilterOutput}
        />
    )

    // @todo: consider using this for Recent screen also. Unify the approach for adding controls
    const sectionControls: SectionControl[] = []
    if (!isDesktop) {
        sectionControls.push({
            content: <SortAndFilterButton />,
            onClick: () => {
                setDrawerCta('Done')
                openDrawer(drawerContent)
            }
        })
    } else {
        sectionControls.push({
            content: (<SortAndFilter
                products={products}
                sortedBy={sort}
                filteredBy={filter}
                renderSortOnly={true}
                onUpdate={handleSortAndFilterOutput}
            />),
            onClick: () => {}
        })
    }
    const toolbarItems = [
        {
            icon: 'Filter',
            onClick: () => {
                setDrawerCta('Done')
                openDrawer(drawerContent)
            }
        }
    ]

    const toolbar = (
        <FloatingToolbar tools={isDesktop ? undefined : toolbarItems} />
    )
    const getContent = () => {
        if (!products.length) {
            return <EmptyListMessage message={props.emptyListMessage} />
        }
        const header = !sortedProducts.length ? null : (
            <div>
                <TextLine
                    type="subtitle"
                    bold={true}
                    text={props.title}
                    className="listTitle"
                />
            </div>
        )
        const main = (
            <div>
                {header}
                <Section body={sectionBody} controls={sectionControls} />
            </div>
        )

        const aside = <div className="sortFilterDisplay">{drawerContent}</div>
        return (
            <>
                <ProductListWrapper aside={aside} main={main} />
                {toolbar}
            </>
        )
    }

    return <div className={className}>{getContent()}</div>
}

const StyledProductListWithControls = styled(ProductListWithControls)(
    (props) => {
        return {
            ['.sortFilterDisplay']: { width: '100%' },
            ['.listTitle']: {
                textTransform: 'capitalize'
            }
        }
    }
)
export default StyledProductListWithControls
