import React from 'react'
import { DEFAULT_CURRENCY } from './currency'
import * as defaultStorage from './storage'

const defaultAppContext: AppContextInterface = {
    drawer: null,
    openDrawer: () => null,
    toastMessage: '',
    setToastMessage: () => null,
    loader: { show: false, text: '' },
    setLoader: () => null,
    currencyRates: null,
    userCurrency: DEFAULT_CURRENCY,
    setUserCurrency: () => null,
    isDesktop: false,
    drawerCta: '',
    setDrawerCta: () => null,
    promos: null,
    userCountry: null,
    currentUser: null,
    setCurrentUser: () => null,
    storage: defaultStorage,
    setLastSearchResult: () => null,
    lastSearchResult: null,
    setExploreItems: () => null,
    exploreItems: [],
    setTrendingProducts: () => null,
    trendingProducts: [],
    lang: 'en',
    textSearchResult: null,
    imageSearchResult: null
}

const AppContext = React.createContext(defaultAppContext)

export default AppContext
