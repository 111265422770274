import React, {
    MutableRefObject,
    PropsWithChildren,
    useEffect,
    useRef,
    useState
} from 'react'
import { PLACEHOLDER_IMAGE } from '../../constants'
import styled from 'styled-components'

export interface Props {
    className?: string
    altSrc?: string
    src: string
    title?: string
    alt?: string
    height?: string
    width?: string
    setCors?: boolean
    fit?: 'cover' | 'contain'
    failureCallback?: (err?: any) => void
    onLoad?: () => void
    srcSet?: string
    sizes?: string
    lazy?: boolean
    defaultComponent?: (props: Props) => React.ReactElement
}

const Image: React.FC<Props> = (props) => {
    const {
        className,
        alt,
        height,
        width,
        altSrc,
        setCors = true,
        failureCallback = () => {
            console.log('')
            return null
        },
        fit,
        srcSet,
        sizes,
        title = '',
        lazy = true,
        defaultComponent
    } = props
    const imageRef: MutableRefObject<HTMLImageElement | null> = useRef(null)
    const [src, setSrc] = useState(PLACEHOLDER_IMAGE)
    const [loaded, setLoaded] = useState(false)
    const [failed, setFailed] = useState(false)
    const attemptRecovery = (err: any) => {
        if (!altSrc || failed) {
            setSrc(PLACEHOLDER_IMAGE)
            setFailed(true)
            failureCallback(err)
            return
        }

        setFailed(true)
        setSrc(altSrc)
        return
    }

    useEffect(() => {
        if (!imageRef.current) {
            return
        }

        if (!('IntersectionObserver' in window)) {
            // @todo IntersectionObserver polyfill
            setSrc(props.src)
            return
        }
        const imgElement = imageRef.current
        if (setCors) {
            imgElement.setAttribute('crossOrigin', 'annonymous')
        }
        const onImgLoad = () => {
            if (props.onLoad) {
                props.onLoad()
            }
            setLoaded(true)
        }

        if (!lazy) {
            setSrc(props.src)
            setLoaded(true)
            return
        }

        const lazyImageObserver = new IntersectionObserver(
            (entries, _observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !failed && !loaded) {
                        const lazyImage = entry.target as HTMLImageElement
                        setSrc(props.src)
                        imgElement.onload = onImgLoad
                        lazyImageObserver.unobserve(lazyImage)
                    }
                })
            }
        )

        lazyImageObserver.observe(imgElement)
        return () => {
            imgElement.removeEventListener('load', onImgLoad)
        }
    }, [imageRef, props.src, setCors, setLoaded, failureCallback, setSrc])
    const cssClasses = `${className} ${loaded ? 'loadedImage' : 'loadingImage'}`
    const fitStyle: React.CSSProperties = {
        objectFit: fit
    }

    if (failed && defaultComponent) {
        return defaultComponent(props)
    }

    return (
        <img
            ref={imageRef}
            src={src}
            title={title}
            className={cssClasses}
            height={height || 'auto'}
            width={width || 'auto'}
            alt={alt || ''}
            style={fitStyle}
            onAbort={attemptRecovery}
            onError={attemptRecovery}
            srcSet={srcSet}
            sizes={sizes}
        />
    )
}

const StyledImage = styled(Image)`
    opacity: 0.2;
    max-width: 100%;
    @keyframes emptyPulser {
        0% {
            opacity: 0.1;
        }
        50% {
            opacity: 0.3;
        }
        100% {
            opacity: 0.1;
        }
    }
    &.loadingImage {
        animation-name: emptyPulser;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 2s;
    }
    @keyframes fadeInImg {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
    &.loadedImage {
        animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
        animation-fill-mode: forwards;
        animation-duration: 2s;
        animation-delay: 0.1s;
    }
`
export default StyledImage
