import React, { useState } from 'react'
import ReactGA from 'react-ga'
import firebase from 'firebase/app'
import * as apiClient from '../../helpers/apiClient'
import theme from '../../theming'
import { CachedStorage } from '../../helpers/CachedStorage'
import * as httpStorage from '../../helpers/httpStorage'
import * as defaultStorage from '../../helpers/storage'
import {
    countryToCurrency,
    getUserCountry,
    DEFAULT_CURRENCY
} from '../../helpers/currency'
import getExploreItems from '../../helpers/exploreItems'
import TiktokPixel from 'tiktok-pixel'

export interface Props extends ServerData {
    className?: string
    children: React.ReactElement
}

// Configure Firebase.
const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyDjLLYftfLYNdTTpfFR-8LX-vi5wnlxObk',
    authDomain: 'auth.lykdat.com'
}

export const initializeFirebase = () => firebase.initializeApp(FIREBASE_CONFIG)

const sendUserLoginEvent = (user: firebase.User | null) => {
    if (user && user.metadata.creationTime) {
        const signUpDate = new Date(user.metadata.creationTime)
        const thirtyMinutesAgo = Date.now() - 1000 * 60 * 30
        const lessThanThirtyMinutesAgo = signUpDate.getTime() > thirtyMinutesAgo
        ReactGA.event({
            category: 'User',
            action: lessThanThirtyMinutesAgo ? `Sign Up` : 'Sign in'
        })
        TiktokPixel.track('CompleteRegistration', {
            description: lessThanThirtyMinutesAgo ? `Sign Up` : 'Sign in'
        })
    }
}

const onInitialize = (
    setIsDesktop: React.Dispatch<React.SetStateAction<boolean>>,
    setUserCurrency: (value: React.SetStateAction<keyof Currencies>) => void,
    setUserCountry: (value: React.SetStateAction<string | null>) => void,
    setPromos: (value: React.SetStateAction<PromoData | null>) => void,
    setTrendingProducts: (value: React.SetStateAction<Product[]>) => void,
    setCurrencyRates: (value: React.SetStateAction<CurrencyRates | null>) => void
) => {
    setIsDesktop(
        window.matchMedia(theme.media.largeScreenWidthQuery).matches
    )

    window.onresize = () => {
        setIsDesktop(
            window.matchMedia(theme.media.largeScreenWidthQuery).matches
        )
    }

    apiClient.getCurrencyRates().then((rates) => setCurrencyRates(rates))

    getUserCountry()
        .then((countryCode) => {
            if (countryCode) {
                setUserCurrency(countryToCurrency(countryCode))
                setUserCountry(countryCode)
            }
        })
        .catch((error) => {
            console.error(error)
        })

    apiClient.getPromos().then((promoData) => {
        setPromos(promoData)
    })

    apiClient.getTrendingProducts().then((items) => {
        setTrendingProducts(items)
    })
}

const onAuthorizationState = (
    setStorage: (value: React.SetStateAction<AppStorage>) => void,
    setCurrentUser: (value: React.SetStateAction<firebase.User | null | undefined>) => void,
    setExploreItems: React.Dispatch<React.SetStateAction<ExploreItemData[]>>,
    ctx: AppContextInterface
): firebase.Unsubscribe => {
    const unregisterAuthObserver = firebase.auth()
    .onAuthStateChanged((user) => {
        setCurrentUser(user)
        setStorage(
            user ? new CachedStorage(httpStorage) : defaultStorage
        )
    
        getExploreItems(ctx, false, user).then((items) => {
            setExploreItems(items)
        })

        sendUserLoginEvent(user)
    })
    return unregisterAuthObserver
}

export const getLayoutElements = (props: Props): [
    () => void, () => firebase.Unsubscribe, 
    AppContextInterface, boolean, AppStorage
] => {
    const [isDesktop, setIsDesktop] = useState(false)
    const [userCountry, setUserCountry] = useState<string | null>(null)
    const [userCurrency, setUserCurrency] = useState<UserCurrency>(
        DEFAULT_CURRENCY
    )
    const [promos, setPromos] = useState<PromoData | null>(null)
    const [trendingProducts, setTrendingProducts] = useState<Product[]>([])
    const [currencyRates, setCurrencyRates] = useState<CurrencyRates | null>(
        null
    )
    const [currentUser, setCurrentUser] = useState<
        firebase.User | null | undefined
    >(undefined)
    const [storage, setStorage] = useState<AppStorage>(defaultStorage)
    const [exploreItems, setExploreItems] = useState<ExploreItemData[]>([])
    const [
        drawerContent,
        setDrawerContent
    ] = useState<React.ReactElement | null>(null)
    const [toastMessageContent, setToastMessageContent] = useState<
    string | toastOptions>('')
    const [loader, setLoader] = useState<LoaderProps>({
        show: false,
        text: ''
    })
    const [drawerCta, setDrawerCta] = useState('')
    const [
        lastSearchResult,
        setLastSearchResult
    ] = useState<SearchResultData | null>(null)
    const {
        lang,
        textSearchResult = null,
        imageSearchResult = null,
    } = props

    const onInit = () => {
        onInitialize(
            setIsDesktop, setUserCurrency, 
            setUserCountry, setPromos, 
            setTrendingProducts, setCurrencyRates
        )
    }

    const appContext: AppContextInterface = {
        drawer: drawerContent,
        openDrawer: setDrawerContent,
        toastMessage: toastMessageContent,
        setToastMessage: setToastMessageContent,
        loader,
        setLoader,
        currencyRates,
        userCurrency,
        setUserCurrency,
        isDesktop,
        drawerCta,
        setDrawerCta,
        promos,
        userCountry,
        currentUser,
        setCurrentUser,
        storage,
        lastSearchResult,
        setLastSearchResult,
        exploreItems,
        setExploreItems,
        trendingProducts,
        setTrendingProducts,
        lang,
        textSearchResult,
        imageSearchResult
    }

    const onAuthState = (): firebase.Unsubscribe => {
        return onAuthorizationState(setStorage, setCurrentUser, setExploreItems, appContext)
    }

    return [onInit, onAuthState, appContext, isDesktop, storage]
}