import { media, Media } from './media'
import { breakpoints, BreakpointsType } from './breakpoints'

type TextType =
    | 'subtitle'
    | 'body'
    | 'caption'
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'heading5'
    | 'heading6'
    | 'buttontext'
    | 'buttontextsmall'
    | 'small'
    | 'bodysmallbold'
    | 'bodysmallest'
type FontProperty =
    | 'fontFamily'
    | 'fontSize'
    | 'fontWeight'
    | 'lineHeight'
    | 'color'
type ColorType =
    | 'primary'
    | 'primaryLight'
    | 'primaryLighter'
    | 'primaryDark'
    | 'white'
    | 'black'
    | 'gray'
    | 'grayLight'
    | 'grayLighter'
    | 'grayLightest'
    | 'grayDark'
    | 'grayDarker'
    | 'accent'
    | 'accentDark'
    | 'accentLight'
    | 'accentLighter'
    | 'accentLightest'
    | 'constructive'
    | 'background'
type Scale =
    | 'smallest'
    | 'smaller'
    | 'small'
    | 'medium'
    | 'moderatelylarge'
    | 'large'
    | 'larger'
    | 'largest'
type Layer = 'body' | 'appShell' | 'overlay' | 'modal'

class ThemeBase {
    public font: { [key in TextType]: { [_key in FontProperty]: any } }
    public color: { [key in ColorType]: string }
    public space: { [key in Scale]: string }
    public size: { [key in Scale]: string }
    public media: Media
    public layer: { [key in Layer]: number }
    public maxBodyWidth: string
    public minBodyHeight: string
    public boxShadow: string
    public hoverBoxShadow: string
    public breakpoints: BreakpointsType
    constructor() {
        this.color = {
            accent: '#0A4DC8',
            accentDark: '#002E91',
            accentLight: '#407be6',
            accentLighter: '#94baff',
            accentLightest: '#e0ebff',
            black: '#000',
            constructive: '#1b5e20',
            gray: '#7f7f7f',
            grayDarker: '#CCCCCC',
            grayDark: '#222',
            grayLight: '#a1a1a1',
            grayLighter: '#f0f0f0',
            grayLightest: '#fbfbfb',
            primary: '#000',
            primaryDark: '#222',
            primaryLight: '#777',
            primaryLighter: '#fafafa',
            white: '#fff',
            background: '#fafafa'
        }
        this.font = {
            body: this.typography(`'Inter', sans-serif`, '16px', '400', '20px'),
            caption: this.typography(
                `'Inter', sans-serif`,
                '10px',
                '400',
                '16px'
            ),
            heading1: this.typography(
                `'Inter', sans-serif`,
                '48px',
                '700',
                '58px'
            ),
            heading2: this.typography(
                `'Inter', sans-serif`,
                '40px',
                '700',
                '48px'
            ),
            heading3: this.typography(
                `'Inter', sans-serif`,
                '32px',
                '700',
                '40px'
            ),
            heading4: this.typography(
                `'Inter', sans-serif`,
                '24px',
                '700',
                '30px'
            ),
            heading5: this.typography(
                `'Inter', sans-serif`,
                '18px',
                '700',
                '22px'
            ),
            heading6: this.typography(
                `'Inter', sans-serif`,
                '15px',
                '700',
                '18px'
            ),
            buttontext: this.typography(
                `'Inter', sans-serif`,
                '18px',
                '500',
                '22px'
            ),
            buttontextsmall: this.typography(
                `'Inter', sans-serif`,
                '15px',
                '600',
                '18px'
            ),
            small: this.typography(
                `'Inter', sans-serif`,
                '14px',
                '400',
                '20px'
            ),
            bodysmallbold: this.typography(
                `'Inter', sans-serif`,
                '14px',
                '600',
                '20px'
            ),
            bodysmallest: this.typography(
                `'Inter', sans-serif`,
                '12px',
                '400',
                '16px'
            ),
            subtitle: this.typography(
                `'Inter', sans-serif`,
                '18px',
                '600',
                '22px'
            )
        }
        this.space = {
            largest: '48px',
            larger: '32px',
            large: '24px',
            moderatelylarge: '20px',
            medium: '16px',
            small: '12px',
            smaller: '8px',
            smallest: '4px'
        }
        this.size = {
            largest: '48px',
            larger: '32px',
            large: '24px',
            moderatelylarge: '20px',
            medium: '16px',
            small: '12px',
            smaller: '8px',
            smallest: '4px'
        }
        this.media = {
            largeScreenBreakpoint: '@media only screen and (min-width: 769px)',
            smallScreenBreakpoint: '@media only screen and (max-width: 768px)',
            largeScreenWidthQuery: '(min-width: 769px)',
            ...media
        }
        this.maxBodyWidth = '1440px'
        this.minBodyHeight = '320px'
        this.boxShadow = 'rgba(0, 0, 0, 0.07) 0px 1px 4px 0px'
        this.hoverBoxShadow = 'rgba(0, 0, 0, 0.2) 0px 2px 8px 0px'
        this.layer = {
            appShell: 7,
            modal: 3,
            overlay: 5,
            body: 0
        }
        this.breakpoints = breakpoints
    }
    public typography(
        fontFamily: string,
        fontSize: string,
        fontWeight: string,
        lineHeight: string,
        color: string = this.color.black,
        letterSpacing: string = 'normal'
    ) {
        return {
            color,
            fontFamily,
            fontSize,
            fontWeight,
            lineHeight,
            letterSpacing
        }
    }
}

export { ThemeBase as default, TextType, ColorType, Scale, Layer }
