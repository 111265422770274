import React from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import { NavLink } from 'react-router-dom'
import Image from '../Image'
import TextLine from '../TextLine'
import { rgba } from 'polished'

export interface Props {
    className?: string
    onClick: () => any
    imageSrc: string
    alt?: string
    linkTo?: string
    caption?: string
}

const PictureCard: React.FC<Props> = (props) => {
    const { alt, caption, className, onClick, imageSrc, linkTo } = props
    const captionEl = caption ? (
        <TextLine
            text={caption}
            type="small"
            color="white"
            className="cardText"
        />
    ) : null
    const card = (
        <>
            <Image
                className="cardImage"
                height="260"
                width="100%"
                src={imageSrc}
                alt={alt}
                fit="cover"
                setCors={false}
            />
            {captionEl}
        </>
    )

    return (
        <div className={className}>
            <div className="card" onClick={onClick}>
                {linkTo ? <NavLink to={linkTo}>{card}</NavLink> : card}
            </div>
        </div>
    )
}

const StyledPictureCard = styled(PictureCard)((props) => {
    return {
        cursor: 'pointer',
        boxShadow: theme.boxShadow,
        ['&:hover']: {
            boxShadow: theme.hoverBoxShadow
        },
        borderRadius: theme.size.smaller,
        maxHeight: 260,
        width: `calc(50% - ${theme.space.medium})`,
        marginBottom: theme.space.largest,
        textAlign: 'center',
        [`${theme.media.largeScreenBreakpoint}`]: {
            width: `calc(33% - ${theme.space.largest})`
        },
        ['.card']: {
            position: 'relative',
            maxHeight: 'inherit'
        },
        ['.cardText']: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
            backgroundColor: rgba(0, 0, 0, 0.6),
            padding: theme.space.small,
            borderRadius: `0 0 ${theme.size.smaller}  ${theme.size.smaller}`
        },
        ['a']: { display: 'block' },
        ['.cardImage']: {
            borderRadius: theme.space.small
        },

        ['.caption']: {
            padding: theme.space.medium
        }
    }
})
export default StyledPictureCard
