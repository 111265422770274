import React from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import TextLine from '../TextLine'
import Image from '../Image'
import { SRControl } from '../SearchResultsDashboard/SearchResultsDashboard'
import ProductList from '../ProductList'
import Button from '../Button'
import axios from 'axios'
import { EXPLORER_API_URL } from '../../constants'

export interface Props extends ExploreItemData {
    className?: string
}

const ExploreItem: React.FC<Props> = (props) => {
    const {
        _id,
        className,
        media_creator,
        media_url,
        media_source,
        media_source_url,
        products
    } = props
    const control: SRControl = {
        text: `${media_creator}`
    }
    const control2: SRControl = {
        icon: 'Link',
        text: media_source,
        asLink: `${media_source_url}`
    }

    const mediaSourceAsLink = (
        <a href={media_source_url} style={{ display: 'flex' }}>
            <Button
                text={media_source}
                icon="Link"
                sizeByScale="smaller"
                textType="caption"
                padding="0"
                className="sourceLabel"
                color="gray"
                transparent={true}
                noBorder={true}
            />
        </a>
    )

    const mediaSourceAsText = (
        <div style={{ display: 'flex' }}>
            <TextLine text={media_source} type="caption" color="gray" />
        </div>
    )

    const credit = (
        <div className="creditBlock">
            <TextLine text={media_creator} type="small" className="creator" />
            {media_source_url ? mediaSourceAsLink : mediaSourceAsText}
        </div>
    )
    const trackImpression = () => {
        if (!_id) {
            return
        }

        axios
            .patch(EXPLORER_API_URL, {
                id: _id,
                op: 'update_impressions'
            })
            .then(() => {
                // console.log('')
            })
            .catch((e) => {
                console.log(e)
            })
    }
    const trackClicks = () => {
        if (!_id) {
            return
        }

        axios
            .patch(EXPLORER_API_URL, {
                id: _id,
                op: 'update_clicks'
            })
            .then(() => {
                console.log('')
            })
            .catch((e) => {
                console.log(e)
            })
    }
    const sourceBlock = (
        <div className="sourceBlock">
            <div className="sourceImage">
                <Image
                    className="sourceImage__img"
                    height="100%"
                    width="100%"
                    src={media_url}
                    alt="explore media"
                    fit="cover"
                    setCors={false}
                    onLoad={trackImpression}
                />
            </div>

            {credit}
        </div>
    )

    return (
        <div className={className}>
            {sourceBlock}
            <div className="productsBlock">
                <ProductList
                    products={products}
                    noSimilarityBadge={true}
                    exploreTracker={trackClicks}
                />
            </div>
        </div>
    )
}

const StyledExploreItem = styled(ExploreItem)((props) => {
    const imgMaxHeight = 200
    return {
        margin: `${theme.space.large} 0 ${theme.space.larger}`,
        display: 'flex',
        flexDirection: 'column',
        [`${theme.media.largeScreenBreakpoint}`]: {
            flexDirection: 'row',
            alignItems: 'flex-start'
        },
        ['.sourceBlock']: {
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: theme.space.large,
            maxHeight: imgMaxHeight,
            marginRight: theme.space.medium,
            [`${theme.media.largeScreenBreakpoint}`]: {
                flexDirection: 'column',
                marginRight: theme.space.largest,
                alignItems: 'flex-start'
            }
        },
        ['.sourceLabel']: {
            '> svg': {
                marginRight: theme.space.smaller
            }
        },
        ['.sourceImage']: {
            width: 130,
            [`${theme.media.largeScreenBreakpoint}`]: { width: 170 }
        },
        ['.creditBlock']: {
            margin: theme.space.medium,
            [`${theme.media.largeScreenBreakpoint}`]: {
                margin: 0,
                marginLeft: theme.space.smaller
            }
        },
        ['.sourceImage, .sourceImage__img']: {
            height: '100%',
            maxHeight: imgMaxHeight,
            borderRadius: theme.size.smaller
        },
        ['.productsBlock']: {
            width: '100%',
            marginBottom: theme.space.larger
        }
    }
})
export default StyledExploreItem
