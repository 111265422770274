import React, { useContext, useEffect, useRef } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../theming'
import Button from '../Button'
import AppContext from '../../helpers/AppContext'

interface Props extends RouteComponentProps {
    className?: string
    placeholderText?: string
    searchIcon?: boolean
}

const TextSearchInput: React.FC<Props> = (props) => {
    const ctx = useContext(AppContext)
    const inputElement = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus()
        }
    }, [])

    useEffect(() => {
        if (
            props.location.pathname !== '/text-search' &&
            inputElement.current &&
            inputElement.current.value
        ) {
            // set input to empty once user is no longer on text-search page.
            // the idea here is that the user is no longer using the text search functionality
            // once they leave the text-search page.
            inputElement.current.value = ''
        }
    }, [props.location.pathname])

    const updateUrl = () => {
        if (inputElement.current && inputElement.current.value) {
            // TODO: investigate query encoding in mozilla
            const queryVal = encodeURIComponent(inputElement.current.value)
            if (props.location.pathname === '/text-search') {
                props.history.push(`/empty`)
                props.history.replace(`/text-search?query=${queryVal}`)
            } else {
                props.history.push(`/text-search?query=${queryVal}`)
            }
        }
    }

    const searchTextInput = (e: React.FormEvent) => {
        e.preventDefault()
        if (!ctx.isDesktop && inputElement.current) {
            inputElement.current.blur()
        }
        if (inputElement.current && inputElement.current.value) {
            updateUrl()
        }
    }

    return (
        <div className={props.className}>
            <div className="search-input">
                <form onSubmit={searchTextInput}>
                    <Button
                        iconOnly={true}
                        icon="Search"
                        color="grayLight"
                        inline={true}
                        transparent={true}
                        padding={`${ctx.isDesktop ? '10px' : '5px'}`}
                        noBorder={true}
                        onClick={searchTextInput}
                    />
                    <input
                        ref={inputElement}
                        type="text"
                        placeholder={
                            props.placeholderText || 'What are you looking for?'
                        }
                    />
                </form>
            </div>
        </div>
    )
}

const StyledTextSearchInput = styled(TextSearchInput)((props) => {
    return {
        marginTop: `${theme.size.large}px 0`,
        width: '100%',
        ['.search-input']: {
            width: '562px',
            margin: `0 auto`,
            [theme.media.smallScreenBreakpoint]: {
                width: '360px',
                maxWidth: '98%',
                ['input[type=text]']: {
                    width: '330px',
                    maxWidth: '82%'
                }
            }
        },
        ['input[type=text]']: {
            verticalAlign: 'super',
            padding: theme.space.small,
            fontSize: theme.font.body.fontSize,
            width: '500px',
            border: 'none'
        },
        ['input[type=text]:focus']: {
            outline: 'none'
        }
    }
})

export default withRouter(StyledTextSearchInput)
