import React from 'react'
import theme from '../../theming'
import Button from './Button'

const SortAndFilterButton: React.FC = () => {
    return (
        <Button
            textType="buttontextsmall"
            inline={true}
            text="Filters"
            background="white"
            borderColor="grayLighter"
            color="black"
            icon="Filter"
            padding={theme.space.smaller}
        />
    )
}

export default SortAndFilterButton
