import React, { useRef, useEffect } from 'react'
import Tooltip from 'react-tooltip'
import styled from 'styled-components'
import theme from '../../theming'
import ReactGA from 'react-ga'
import { MdAutorenew } from 'react-icons/md'

export interface Props {
    className?: string
    condition?: () => boolean
    hideAfter?: number
    showAfter?: number
    top?: boolean
    html?: boolean
    id: string
    uuid: string
    content: string
    children: React.ReactElement
    position?: { top: number; left: number }
    background?: string
    border?: string
    textColor?: string
}

const WithAutoTooltip: React.FC<Props> = (props) => {
    const {
        children,
        content,
        uuid,
        id,
        top,
        condition,
        background,
        border,
        textColor,
        position: pos,
        html = false
    } = props
    const hideAfter = props.hideAfter || 8000
    const showAfter = props.showAfter || 5000
    const position = top ? 'top' : 'bottom'
    const triggerElementRef: React.MutableRefObject<HTMLInputElement | null> = useRef(
        null
    )

    useEffect(() => {
        setTimeout(() => {
            if (!triggerElementRef.current) {
                return
            }

            if (condition && !condition()) {
                return
            }

            Tooltip.show(triggerElementRef.current)
            setTimeout(
                () =>
                    triggerElementRef.current &&
                    Tooltip.hide(triggerElementRef.current),
                hideAfter
            )
        }, showAfter)
    }, [])

    const triggerEl = (
        <div
            ref={triggerElementRef}
            data-tip={content}
            data-event="click"
            data-effect="solid"
            data-for={id}
        />
    )
    const stopPropagation = (e: React.SyntheticEvent) => {
        e.stopPropagation()
    }
    return (
        <div className={props.className} onClick={stopPropagation}>
            <div>
                {top ? triggerEl : null}
                {children}
                {!top ? triggerEl : null}
            </div>

            <Tooltip
                id={id}
                uuid={uuid}
                place={position}
                effect="solid"
                html={html}
                scrollHide={false}
                event="click"
                className="auto-tooltip"
                multiline={true}
                backgroundColor={background}
                borderColor={border}
                textColor={textColor}
                overridePosition={pos ? () => pos : undefined}
            />
        </div>
    )
}

const StyledWithAutoTooltip = styled(WithAutoTooltip)((props) => {
    return {
        ['.auto-tooltip']: {
            fontFamily: theme.font.caption.fontFamily
        }
    }
})

export default StyledWithAutoTooltip
