import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BackgroundImage from '../BackgroundImage'

export interface ThumbnailButtonProps {
    className?: string
    image: string
    width?: string
}

const ThumbnailButton: React.FC<ThumbnailButtonProps> = (props) => {
    return (
        <button className={props.className}>
            <BackgroundImage
                image={props.image}
                width="100%"
                cover={true}
                round={true}
            />
        </button>
    )
}

const StyledThumbnailButton = styled(ThumbnailButton)((props) => {
    return {
        padding: '5px',
        width: props.width || '50px',
        height: props.width || '50px',
        borderRadius: '50%',
    }
})

export default StyledThumbnailButton
