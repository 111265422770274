import React, { useContext } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import Section from '../Section'
import TextLine from '../TextLine'
import Icon from '../Icon'
import AppContext from '../../helpers/AppContext'
import { useTranslate } from '../../helpers/useTranslate'
import * as indexSocialHelper from './helper'

export interface Props {
    className?: string
    primary?: boolean
}

const IndexSocial: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const { translate } = useTranslate()
    
    return (
        <indexSocialHelper.Wrapper
            className={className}
            id={`${encodeURIComponent(translate('WAYS_TO_SEARCH_TITLE'))}`}
        >
            <Section
                title={translate('HOME_SOCIAL_HEADER')}
                body={<div className="cardsContainer">{indexSocialHelper.sectionBody()}</div>}
            />
        </indexSocialHelper.Wrapper>
    )
}

const StyledIndexSocial = styled(IndexSocial)((props) => {
    return {
        '.wtsSectionBody': {
            display: 'flex',
            flexWrap: 'wrap'
        },
        '.wtsSectionBodyItem': {
            display: 'flex',
            maxWidth: '500px',
            ['&:first-of-type']: {
                marginBottom: theme.space.larger,
                marginRight: theme.space.large
            }
        },
        '.socialIcon': {
            marginRight: theme.space.large,
            marginTop: theme.space.smaller
        },
        '.socialLink': {
            display: 'block',
            marginTop: theme.space.small
        }
    }
})

export default StyledIndexSocial
