import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/app'
import 'firebase/auth'
import AppContext from '../../helpers/AppContext'
import TextLine from '../TextLine'
import Icon from '../Icon'
import Thumbnail from '../Thumbnail'
import Divider from '../Divider'
import Button from '../Button'
import { PLACEHOLDER_IMAGE, FEEDBACK_LINK } from '../../constants'
import { useTranslate } from '../../helpers/useTranslate'
import PaymentInfoForm from '../PaymentInfoForm'

export interface Props {
    className?: string
    primary?: boolean
    prompt?: string
}

const getItemMarkup = (item: {
    icon: string
    textTitle: string
    textBody: string
}) => (
    <div className="wtsSectionBodyItem">
        <Icon name={item.icon} className="icon" />
        <div className="textWrapper">
            <TextLine
                className="headerStyle"
                text={item.textTitle}
                type="heading6"
                bold={true}
            />
            <TextLine text={item.textBody} type="small" color="#666666" />
        </div>
    </div>
)

const UserProfile: React.FC<Props> = (props) => {
    const { className } = props
    const { currentUser, openDrawer } = useContext(AppContext)
    const [showPayment, setShowPayment] = useState(false)
    const { translate } = useTranslate()

    const profile = () => {
        if (!currentUser) {
            return null
        }

        const recentItems = (
            <div onClick={() => openDrawer(null)} className="full__width">
                <Button
                    text={translate('RECENT_SEARCHES_KEYWORD')}
                    textType="small"
                    icon="Clock"
                    color="primary"
                    transparent={true}
                    noBorder={true}
                    size="24px"
                    padding="0"
                    asLinkTo="/recent"
                    className="justify__right"
                    textClassName="button__text"
                />
            </div>
        )

        const savedItems = (
            <div onClick={() => openDrawer(null)} className="full__width">
                <Button
                    text={translate('SAVE_ITEMS_KEYWORD')}
                    textType="small"
                    icon="Heart"
                    color="primary"
                    transparent={true}
                    noBorder={true}
                    size="24px"
                    padding="0"
                    asLinkTo="/saved"
                    className="justify__right"
                    textClassName="button__text"
                />
            </div>
        )

        const sendFeedback = (
            <div onClick={() => openDrawer(null)} className="full__width">
                <a target="_blank" href={FEEDBACK_LINK}>
                    <Button
                        text={translate('SEND_FEEDBACK_KEYWORD')}
                        textType="small"
                        icon="MessageSquare"
                        color="primary"
                        transparent={true}
                        noBorder={true}
                        size="24px"
                        padding="0"
                        className="justify__right"
                        textClassName="button__text"
                    />
                </a>
            </div>
        )

        const signOut = (
            <div className="full__width">
                <Button
                    text={translate('SIGN_OUT_KEYWORD')}
                    textType="small"
                    icon="Logout"
                    color="primary"
                    transparent={true}
                    noBorder={true}
                    sizeByScale="medium"
                    padding="0"
                    onClick={() => firebase.auth().signOut()}
                    className="justify__right"
                    textClassName="button__text"
                />
            </div>
        )

        return (
            <div style={{ width: '100%', paddingLeft: '24px' }}>
                <div className="avatar">
                    <TextLine
                        type="heading4"
                        text="Profile"
                        className="avatar__header"
                    />
                    <div className="avatar__img">
                        <Thumbnail
                            src={currentUser.photoURL || PLACEHOLDER_IMAGE}
                            round={true}
                            size="larger"
                            className="image__thumbnail"
                        />
                    </div>
                    <TextLine
                        text={currentUser.displayName || 'User'}
                        className="avatar__displayname"
                    />
                </div>

                <Divider />

                <div className="menu">
                    {recentItems}
                    {savedItems}
                    {sendFeedback}
                    {signOut}
                </div>
            </div>
        )
    }

    const CONTENT_DATA = [
        {
            icon: 'FiTag',
            // textTitle: translate('DISCOUNT_ALERTS'),
            textTitle: 'Exclusive Coupons & Discount Alerts',
            // textBody: translate('DISCOUNT_BENEFITS_DESCRIPTION'),
            textBody: 'Get notified when your favorite items go on sale with exclusive coupons codes and discount'
        },
        {
            icon: 'User',
            // textTitle: translate('PERSONALIZED_SHOPPING_TITLE'),
            // textBody: translate('PERSONALIZED_SHOPPING_BENEFITS')
            textTitle: 'Personalised Recommendations',
            textBody: 'Get personalized recommendations curated based on your style and preferences'
        },
        {
            icon: 'ShoppingCart',
            // textTitle: translate('PERSONALIZED_SHOPPING_TITLE'),
            // textBody: translate('PERSONALIZED_SHOPPING_BENEFITS')
            textTitle: 'Styles From Your Favorite Influencers',
            textBody: 'Get weekly curated shopping carts in your inbox based on influencers you follow and love'
        }
    ]

    const signUpIntro = (
        <div className="signUpIntro">
            <div className="heading">
                <TextLine
                    className="headerStyle"
                    // text={translate('JOIN_LYKDAT')}
                    text={'Sign Up at Just US$3.99/mo'}
                    type="heading5"
                />
                <TextLine
                    text={translate('SIGNUP_BENEFITS')}
                    type="small"
                    color="#666666"
                />
            </div>
            <div className="wtsSectionBody">
                {CONTENT_DATA.map(getItemMarkup)}
            </div>
        </div>
    )

    const signInPrompt = props.prompt ? (
        <TextLine text={props.prompt} type="body" bold={true} />
    ) : null

    const breakWordAnd = translate('AND')
    const breakWordPrivacyAndPolicy = translate('PRIVACY_POLICY')
    const breakWordTnS = translate('TERMS_OF_SERVICE')
    const breakWordMoreDetails = translate('FOR_MORE_DETAILS')
    const signInHeader = translate('SIGNIN_OR_SIGNWITH')

    const sectionBody = (
        <div className="sectionBod">
            <TextLine
                text={signInHeader}
                type="caption"
                color="#666666"
                inline={true}
            />
            <a target="_blank" href="/terms#terms-top" className="hyperlink">
                {breakWordPrivacyAndPolicy}
            </a>
            <TextLine
                text={breakWordAnd}
                type="caption"
                color="#666666"
                inline={true}
            />
            <a
                target="_blank"
                href="/privacy#privacy-top"
                className="hyperlink"
            >
                {breakWordTnS}
            </a>
            <TextLine
                text={breakWordMoreDetails}
                type="caption"
                color="#666666"
                inline={true}
            />
        </div>
    )

    const firebaseUIConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                fullLabel: 'Google'
            }
        ],
        callbacks: {
            signInSuccessWithAuthResult: (authResult: firebase.auth.UserCredential, redirectUrl?: string) => {
                console.log('authResult--in', authResult.additionalUserInfo && authResult.additionalUserInfo.isNewUser)

                // Avoid redirects after sign-in.
                return false
            }
        }
    }

    const auth = (
        <div className="auth">
            <TextLine
                text={translate('SIGNIN_OR_SIGNWITH')}
                color="#666666"
                type="small"
            />
            <StyledFirebaseAuth
                uiConfig={firebaseUIConfig}
                firebaseAuth={firebase.auth()}
            />
            {sectionBody}
        </div>
    )

    const signIn = () => (
        <div>
            {signUpIntro}
            {signInPrompt}
            {auth}
        </div>
    )

    if (showPayment) {
        return (
            <div style={{ minHeight: 100 }}>
                <div className={className}>
                    <PaymentInfoForm onDone={() => setShowPayment(false)} />
                </div>
            </div>
        )
    }

    return (
        <div style={{ minHeight: 280 }}>
            <div className={className}>
                {currentUser ? profile() : signIn()}
            </div>
        </div>
    )
}

const StyledUserProfile = styled(UserProfile)((props) => {
    return {
        display: 'flex',
        maxWidth: 375,
        height: 'fit-content',
        minHeight: '70vh',
        ['.full__width']: {
            width: '100%'
        },
        ['.justify__right']: {
            justifyContent: 'start'
        },
        ['.avatar']: {
            marginBottom: theme.space.large
        },
        ['.avatar__header']: {
            margin: 'initial',
            marginBottom: theme.space.larger
        },
        ['.avatar__img']: {
            marginRight: theme.space.medium,
            marginBottom: theme.space.medium
        },
        ['.avatar__displayname']: {
            marginBottom: theme.space.medium
        },
        ['.image__thumbnail']: {
            border: '1px solid #999999',
            width: '72px',
            height: '72px'
        },
        ['.menu']: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            ['>*']: {
                marginBottom: theme.space.medium
            },
            marginTop: theme.space.medium
        },
        ['.button__text']: {
            marginLeft: '18px',
            fontSize: '16px',
            fontWeight: 400
        },
        ['#firebaseui_container']: {
            width: '100%'
        },
        ['.firebaseui-idp-icon-wrapper']: {
            display: 'inline-block'
        },
        ['.firebaseui-idp-text.firebaseui-idp-text-long']: {
            display: 'inline-block'
        },
        ['.firebaseui-card-content']: {
            padding: '0px'
        },
        ['.firebaseui-idp-button']: {
            boxShadow: theme.boxShadow,
            marginBottom: theme.space.small,
            width: '100%',
            maxWidth: '360px',
            textAlign: 'center'
        },
        ['.firebaseui-idp-google']: {
            border: `1px solid rgba(8, 8, 8, 0.2)`
        },
        ['.firebaseui-idp-facebook > .firebaseui-idp-icon-wrapper']: {
            paddingLeft: '11px'
        },
        ['.firebaseui-idp-list > .firebaseui-list-item']: {
            textAlign: 'left',
            marginBottom: '0px'
        },
        ['.wtsSectionBody']: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        ['.wtsSectionBodyItem']: {
            display: 'flex',
            maxWidth: '500px',
            ['&:first-of-type']: {
                marginRight: theme.space.small
            },
            marginBottom: '14px'
        },
        ['.heading']: {
            marginBottom: '25px'
        },
        ['.icon']: {
            margin: '3px 0px'
        },
        ['.textWrapper']: {
            width: '80%',
            marginLeft: '20px'
        },
        ['.signUpIntro']: {
            borderBottom: `1px solid ${theme.color.grayLighter}`,
            paddingBottom: '10px'
        },
        ['.headerStyle']: {
            margin: '2px 0'
        },
        ['.auth']: {
            marginTop: '10px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                paddingBottom: '100px'
            }
        },
        ['.hyperlink']: {
            margin: '0 3px',
            textDecoration: 'underline',
            fontSize: '12px',
            opacity: 0.5,
            color: 'inherit',
            lineHeight: '18px',
            fontFamily: 'Inter, sans-serif'
        }
    }
})

export default StyledUserProfile
