import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import { createImage } from '../../helpers/utils'
import Thumbnail from '../Thumbnail'

interface Props {
    className?: string
    item: LocalizedObject
    imageSrc: string
    highlight?: boolean
}
const CutOut: React.FC<Props> = (props) => {
    const [cutOutImage, setCutOutImage] = useState<string>('')
    const cutout = props.item
    createImage(props.imageSrc)
        .then((image) => {
            const canvas = document.createElement('canvas')
            const cropHeight =
                image.naturalHeight *
                Math.abs(cutout.boundingBox.top - cutout.boundingBox.bottom)
            const cropWidth =
                image.naturalWidth *
                Math.abs(cutout.boundingBox.left - cutout.boundingBox.right)
            const cropLeft = cutout.boundingBox.left * image.naturalWidth
            const cropTop = cutout.boundingBox.top * image.naturalHeight
            canvas.width = cropWidth
            canvas.height = cropHeight
            const ctx = canvas.getContext('2d')
            if (!ctx) {
                // @todo: handle properly. Should this happen?
                return
            }
            ctx.drawImage(
                image,
                cropLeft,
                cropTop,
                cropWidth,
                cropHeight,
                0,
                0,
                cropWidth,
                cropHeight
            )
            return new Promise((resolve, _reject) => {
                resolve(setCutOutImage(canvas.toDataURL()))
            })
        })
        .catch((err) => {
            // @todo: handle error
            // report
        })
    return cutOutImage ? (
        <div className={props.className}>
            <Thumbnail className="styleImg" src={cutOutImage} size="largest" />
        </div>
    ) : null
}

const StyledCutOut = styled(CutOut)((props) => {
    return {
        width: 'fit-content',
        border: `1px  ${
            props.highlight ? theme.color.primary : 'transparent'
        } solid`,
        padding: '2px',
        borderRadius: theme.size.small,
        margin: 0,
        [`${theme.media.smallScreenBreakpoint}`]: {
            padding: '1px',
            borderRadius: theme.size.smaller,
        },
        ['.styleImg']: {
            borderRadius: theme.size.smaller,
            width: '66px',
            height: '66px',
            padding: '1px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                width: '44px',
                height: '44px',
                padding: '2px'
            },
        }
    }
})

export default StyledCutOut
