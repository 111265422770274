import React from 'react'
import theme from '../../theming'
import Button from '../Button'

interface ToolProps {
    icon: string
    onClick: () => any
}

const mapTool = (toolItem: ToolProps, index: number, tools: ToolProps[]) => {
    return <div key={`${toolItem.icon}--${index}`}>
            <Button
                icon={toolItem.icon}
                color="primary"
                transparent={true}
                noBorder={true}
                iconOnly={true}
                inline={true}
                padding={theme.space.small}
                onClick={toolItem.onClick}
            />
            {index < tools.length - 1 ? <div className="divider" /> : null}
        </div>
}

export const getToolElements = (tools: ToolProps[]): JSX.Element[] => tools.map(mapTool)
