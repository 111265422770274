import React, { useEffect, useContext, useState } from 'react'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../theming'
import CaptionedIcon from '../CaptionedIcon'
import TextLine from '../TextLine'
import ImageSearcher from '../ImageSearcher'
import UserAccountTrigger from '../UserAccountTrigger'
import { navItems } from './data'
import ReactGA from 'react-ga'
import AppContext from '../../helpers/AppContext'
import { useTranslate } from '../../helpers/useTranslate'

export interface Props extends RouteComponentProps {
    className?: string
    items?: NavItem[]
    withoutIcon?: boolean
    allActive?: boolean
}

const Nav: React.FC<Props> = (props) => {
    const {
        className,
        location,
        withoutIcon,
        items = navItems,
        allActive = false
    } = props
    const ctx = useContext(AppContext)
    const { translate } = useTranslate()
    const [profileCaption, setProfileCaption] = useState('')

    const doGaTracking = (navItemName: string) => {
        return () => {
            ReactGA.event({
                category: 'User',
                action: `Clicked on nav item: ${navItemName}`
            })
        }
    }

    useEffect(() => {
        if (ctx.currentUser === undefined) {
            // user status hasn't been checked yet
            setProfileCaption('')
        } else if (ctx.currentUser === null) {
            // user status has been checked, but no logged in user
            // was found
            setProfileCaption(translate('USER_NAV_OUT'))
        } else {
            setProfileCaption(translate('USER_NAV_IN'))
        }

    }, [setProfileCaption, ctx.currentUser])

    const NavItems = items.map((item: NavItem, index: number) => {
        const isActive = item.routes.includes(location.pathname)
        const iconShade = isActive || allActive ? 'grayDark' : 'grayLight'
        const text = item.key === 'user' ? profileCaption : translate(item.copy)

        const captionIcon = withoutIcon ? (
            <div onClick={doGaTracking(item.key)}>
                <TextLine text={text} color={iconShade} type="body" />
            </div>
        ) : (
            <CaptionedIcon
                caption={text}
                size={item.size}
                icon={item.icon}
                color={iconShade}
                className={'nav-item'}
                onClick={doGaTracking(item.key)}
            />
        )

        let content

        switch (item.key) {
            case 'search':
                content = <ImageSearcher>{captionIcon}</ImageSearcher>
                break

            case 'user':
                content = <UserAccountTrigger source="bottom nav">{captionIcon}</UserAccountTrigger>
                break

            case 'explore':
                content = (
                    <div key={`CI-${index}`}>
                        <NavLink
                            to={item.routes[0]}
                            exact={item.routes[0] === '/'}
                        >
                            {captionIcon}
                        </NavLink>
                    </div>
                )
                break
            
            case 'index':
                content = (
                    <NavLink to={item.routes[0]} exact={item.routes[0] === '/'} >
                        {captionIcon}
                    </NavLink>
                )
                break

            default:
            content = <a href={item.routes[0]}>{captionIcon}</a>
            break
        }

        return (
            <div key={`CI-${index}`} className="navitem">
                {content}
            </div>
        )
    })
    return <div className={className}>{NavItems}</div>
}

const StyledNav = styled(Nav)((props) => {
    return {
        boxSizing: 'border-box',
        display: 'flex',
        height: '100%',
        margin: '0 auto',
        padding: `${theme.space.small} 0`,
        width: '100%',
        justifyContent: 'space-between',
        paddingBottom: theme.space.small,
        ['& a']: {
            textDecoration: 'none'
        },
        ['.navitem']: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            [`${theme.media.largeScreenBreakpoint}`]: {
                marginLeft: theme.space.large
            }
        }
    }
})
export default withRouter(StyledNav)
