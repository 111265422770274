import Image, { Props as ImageProps } from '../Image/Image'
import styled from 'styled-components'
import TextLine from '../TextLine'
import * as AboutPage from '../About'
import ImageSearcher from '../ImageSearcher'
import React from 'react'
import { useTranslate } from '../../helpers/useTranslate'
import { SEARCH_IMAGES } from '../../constants'

const {firstPhoto, secondPhoto, thirdPhoto} = SEARCH_IMAGES

const DoMorePhoto = styled(Image)`
  max-height: 100%;
  border-radius: 8px;
  &.left {
    width: 100%;
  }
`

const DoMore: React.FC<{}> = () => {
    const { translate: t } = useTranslate()

    return <>
        <div className="do-more-pictures">
            <div className="do-more-pictures-left-1">
                <DoMorePhoto
                    src={firstPhoto.src}
                    title={firstPhoto.src}
                    alt={firstPhoto.src}
                    srcSet={firstPhoto.srcSet}
                    sizes={firstPhoto.sizes}
                    lazy={false}
                    className={'fill'}
                />
            </div>
            <div className="do-more-pictures-left-2">
                <DoMorePhoto
                    src={secondPhoto.src}
                    title={secondPhoto.src}
                    alt={secondPhoto.src}
                    srcSet={secondPhoto.srcSet}
                    sizes={secondPhoto.sizes}
                    className={'fill'}
                    lazy={false}
                />
            </div>
            <div className="do-more-pictures-right">
                <DoMorePhoto
                    src={thirdPhoto.src}
                    title={thirdPhoto.src}
                    alt={thirdPhoto.src}
                    srcSet={thirdPhoto.srcSet}
                    sizes={thirdPhoto.sizes}
                    className={'fill'}
                    lazy={false}
                />
            </div>
        </div>
        <div className="do-more-details">
            <TextLine
                text={t('ABOUT_A_THOUSAND_TITLE')}
                type={'heading4'}
                className={'m-0'}
                color={'white'}
            />
            <AboutPage.Text
                text={t('ABOUT_A_THOUSAND_BODY')}
                color={'white'}
                className={'do-more-text'}
            />
            <div className="flex">
                <ImageSearcher
                    text="Image Search"
                    full={true}
                    noBorder={true}
                    color="accent"
                    className="SearchImageStyle w-auto"
                />
            </div>
        </div>
    </>
}

export default DoMore
